<template>
    <section>
        <v-card outlined>
            <v-card-title style="border-bottom: 1px solid #eeeeee">
                     <v-row no-gutters align="center" justify="space-between">
                        <v-col class="d-flex flex-wrap justify-space-between align-center justify-md-start">
                            <div class="d-flex">
                                <v-icon small class="nav-icon  mx-2">fa fa-file-edit</v-icon>
                                <h3 class="title">
                                    Логи
                                </h3>
                            </div>
                            <div>
                                <v-btn  small icon @click="Refresh()"><v-icon small style="cursor:pointer" title="Обновить"
                                        class="px-2">fa
                                        fa-refresh</v-icon></v-btn>
                                <v-btn small icon @click="ShowFilterModal()" class="d-md-none"><v-icon small style="cursor:pointer"
                                        title="Фильтры" class="px-1">
                                        mdi mdi-filter
                                    </v-icon></v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <v-text-field v-model="filter" append-icon="mdi-magnify" label="Поиск" single-line
                                hide-details class="ma-0 pa-0"></v-text-field>
                        </v-col>
                    </v-row>

             </v-card-title>
            <v-card-text class="pa-0">
                <v-row no-gutters class="logger-row">
                    <v-col cols="12" md="3" class="col px-3 d-none d-md-flex flex-column">
                        <div class="  d-flex justify-space-between ">
                            <div class="text-h6 font-weight-black">Фильтры</div>
                            <v-btn class="align-self-end text-decoration-underline" text x-small :disabled="loading"
                                @click="ClearFilterModel()">очистить</v-btn>
                        </div>
                        <div class="flex-grow-1">
                            <vs-date-picker mode="dateTime" update-on-input is-range v-model="date_range"
                                :max-date='new Date()' show-weeknumbers :is24hr="true" :columns="1" :popover="popover"
                                @input="ChangeDate">
                                <template v-slot="{ inputValue, inputEvents, isDragging }">
                                    <v-text-field :disabled="loading" label="Дата/время"
                                        append-icon="mdi-calendar-blank-multiple" persistent-hint
                                        hint="Если дата/время не выбраны, отображаются последние 1000 записей"
                                        :value="inputValue.start != null && inputValue.end != null ? inputValue.start + ' - ' + inputValue.end : ''"
                                        readonly v-on="inputEvents.start">
                                        <template v-slot:append-outer v-if="inputValue.start != null">
                                            <v-icon @click="ClearDate()">mdi-close-circle</v-icon>
                                        </template>
                                    </v-text-field>
                                </template>
                            </vs-date-picker>

                            <v-select :disabled="loading" label="Тип лога" no-data-text="Нет элементов" multiple
                                @change="ChangeLogs" class="mt-5" item-value="id" item-text="name"
                                :items="LoggerActionSelect" v-model="filterModel.logsAction">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 3" x-small>
                                        <span>{{ item.id }}</span>
                                    </v-chip>
                                    <span v-if="index === 3" class="grey--text text-caption">
                                        (+{{ filterModel.logsAction.length - 3 }} других)
                                    </span>
                                </template>
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @mousedown.prevent @click="ToggleLoggerAction">
                                        <v-list-item-action>
                                            <v-icon :color="filterModel.logsAction.length > 0 ? 'indigo darken-4' : ''">
                                                {{ iconLogerAction }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Выбрать всё
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>

                            <v-autocomplete :disabled="loading" label="Пользователь" no-data-text="Нет элементов"
                                multiple @change="ChangeUsers" item-value="id" item-text="name" :items="AllUsers"
                                v-model="filterModel.users">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index == 0" x-small>
                                        <span>{{ item.name }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ filterModel.users.length - 1 }} других)
                                    </span>
                                </template>
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @mousedown.prevent @click="ToggleUsers">
                                        <v-list-item-action>
                                            <v-icon :color="filterModel.users.length > 0 ? 'indigo darken-4' : ''">
                                                {{ iconUsers }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Выбрать всех
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>

                            <v-autocomplete :disabled="loading" label="Устройство" no-data-text="Нет элементов"
                                persistent-hint
                                hint="Если выбрано устройство и не выбрана дата, то отображаются последние 1000 записей"
                                @change="ChangeDevices" item-value="imei"
                                :item-text='(item) => `${GetDeviceText(item)}`' :items="AllDevices"
                                v-model="filterModel.imei">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index == 0" x-small>
                                        <span>{{ item.imei }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ filterModel.imei.length - 1 }} других)
                                    </span>
                                </template>
                                <template v-slot:append v-if="filterModel.imei != null">
                                    <v-icon @click="filterModel.imei = null">mdi-close-circle</v-icon>
                                </template>
                            </v-autocomplete>

                            <div class="d-flex flex-grow-1 my-5 align-center justify-center"><v-btn class="white--text"
                                    color="indigo lighten-3" :disabled="loading" :loading="loading"
                                    @click="GetReport()">Применить</v-btn></div>
                        </div>
                    </v-col>

                    <v-col cols="12" md="9" class="col">
                        <v-data-table :search="filter" sort-by="date" :sort-desc="true" :fixed-header="!$vuetify.breakpoint.mobile" :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint"
                            loading-text="Загрузка..." :header-props="header_props" :footer-props="footer_props"
                            :loading="isBusy" no-results-text="Не найдено" no-data-text="Нет данных" :headers="fields"
                            :items="logs" locale="ru-Ru" :items-per-page.sync="perPage" class="elevation-0">
                            <template v-slot:item.index="{ item, index, props }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:item.date="{ item, index, props }">
                                {{ GetDate(item.date) }}
                            </template>
                            <template v-slot:item.userID="{ item, index, props }">
                                {{ UserById(item.userID)?.login ?? '-' }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-modal ref="FilterModal" max-width="500">
            <template v-slot:MainModal>
                <v-card>
                    <v-card-title class="text-h6 font-weight-black justify-space-between">
                        {{ 'Фильтры' }}
                        <v-btn icon @click="$refs.FilterModal.Hide()"><v-icon> fa-solid fa-x</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text class="pt-1 pb-1">

                        <div class="w-100 text-end">
                            <v-btn class="align-self-end text-decoration-underline" text x-small :disabled="loading"
                                @click="ClearFilterModel()">очистить</v-btn>
                        </div>
                        <vs-date-picker mode="dateTime" update-on-input is-range v-model="date_range"
                            :max-date='new Date()' show-weeknumbers :is24hr="true" :columns="1" :popover="popover"
                            @input="ChangeDate">
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                                <v-text-field :disabled="loading" label="Дата/время"
                                    append-icon="mdi-calendar-blank-multiple" persistent-hint
                                    hint="Если дата/время не выбраны, отображаются последние 1000 записей"
                                    :value="inputValue.start != null && inputValue.end != null ? inputValue.start + ' - ' + inputValue.end : ''"
                                    readonly v-on="inputEvents.start">
                                    <template v-slot:append-outer v-if="inputValue.start != null">
                                        <v-icon @click="ClearDate()">mdi-close-circle</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                        </vs-date-picker>

                        <v-select :disabled="loading" label="Тип лога" no-data-text="Нет элементов" multiple
                            @change="ChangeLogs" class="mt-5" item-value="id" item-text="name"
                            :items="LoggerActionSelect" v-model="filterModel.logsAction">
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 3" x-small>
                                    <span>{{ item.id }}</span>
                                </v-chip>
                                <span v-if="index === 3" class="grey--text text-caption">
                                    (+{{ filterModel.logsAction.length - 3 }} других)
                                </span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @mousedown.prevent @click="ToggleLoggerAction">
                                    <v-list-item-action>
                                        <v-icon :color="filterModel.logsAction.length > 0 ? 'indigo darken-4' : ''">
                                            {{ iconLogerAction }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Выбрать всё
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>

                        <v-autocomplete :disabled="loading" label="Пользователь" no-data-text="Нет элементов" multiple
                            @change="ChangeUsers" item-value="id" item-text="name" :items="AllUsers"
                            v-model="filterModel.users">
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index == 0" x-small>
                                    <span>{{ item.name }}</span>
                                </v-chip>
                                <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ filterModel.users.length - 1 }} других)
                                </span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @mousedown.prevent @click="ToggleUsers">
                                    <v-list-item-action>
                                        <v-icon :color="filterModel.users.length > 0 ? 'indigo darken-4' : ''">
                                            {{ iconUsers }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Выбрать всех
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-autocomplete>

                        <v-autocomplete :disabled="loading" label="Устройство" no-data-text="Нет элементов"
                            @change="ChangeDevices" item-value="imei" :item-text='(item) => `${GetDeviceText(item)}`'
                            persistent-hint
                            hint="Если выбрано устройство и не выбрана дата, то отображаются последние 1000 записей "
                            :items="AllDevices" v-model="filterModel.imei">
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index == 0" x-small>
                                    <span>{{ item.imei }}</span>
                                </v-chip>
                                <span v-if="index === 1" class="grey--text text-caption">
                                    (+{{ filterModel.imei.length - 1 }} других)
                                </span>
                            </template>
                            <template v-slot:append v-if="filterModel.imei != null">
                                <v-icon @click="filterModel.imei = null">mdi-close-circle</v-icon>
                            </template>
                        </v-autocomplete>

                        <div class="d-flex flex-grow-1 my-5 align-center justify-center"><v-btn class="white--text"
                                color="indigo lighten-3" :disabled="loading" :loading="loading"
                                @click="GetReport()">Применить</v-btn></div>
                    </v-card-text>
                </v-card>
            </template>
        </v-modal>
    </section>
</template>
<script>
import {
    eventBus
} from "@/main.js";
import moment from "moment";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            loading: false,
            perPage: 100,
            filterModel: {
                users: [],
                imei: null,
                logsAction: []
            },
            defaultFilterModel: {
                users: [],
                imei: null,
                logsAction: []
            },
            popover: { visibility: 'click' },
            date_range: { start: null, end: null },
            filter: "",
            logs: [],
            fields: [
                /*{
                    text: '',
                    sortable: false,
                    align: "center",
                    value: "index",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3",
                    width: 75,
                },*/
                /*  {
                      text: 'ID',
                      sortable: true,
                      align: "center",
                      value: "id",
                      cellClass: ["text-center", "align-middle"],
                      class: " grey lighten-3",
                      width: 75,
                  },*/
                {
                    text: "Дата",
                    align: "center",
                    sortable: true,
                    value: "date",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Dev_ID",
                    align: "center",
                    sortable: true,
                    value: "deviceID",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "IMEI",
                    align: "center",
                    sortable: true,
                    value: "imei",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Пользователь",
                    align: "center",
                    sortable: true,
                    value: "userID",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                /* {
                     text: "Тип лога",
                     align: "center",
                     sortable: true,
                     value: "loggerAction",
                     cellClass: ["text-center", "align-middle"],
                     class: " grey lighten-3"
                 },*/
                {
                    text: "Описание",
                    align: "center",
                    sortable: true,
                    value: "desctiption",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
            ],
            footer_props: {
                'items-per-page-all-text': 'Все',
                'items-per-page-options': [
                    50,
                    100,
                    500,
                    -1
                ],
                'items-per-page-text': 'Строк на странице:',
                'pageText': '{0}-{1} из {2}'
            },
            header_props: {
                'sort-by-text': 'Сортировать по'
            },
        };
    },
    computed: {
        logsFilter() {

        },
        LoggerActionSelect() {
            return [
                {
                    id: 0,
                    type: "DeviceJoined",
                    name: "(0) Присоединение устройства",
                },
                {
                    id: 1,
                    type: "CreateComandUpdateFirmware",
                    name: "(1) Команда обновления ПО (создание)",
                },
                {
                    id: 2,
                    type: "CreateComandUpdateParameters",
                    name: "(2) Команда обновления параметров (создание)",
                },
                {
                    id: 3,
                    type: "CreateComandUpdateParameters",
                    name: "(3) Команда обновления тарировки (создание)",
                },
                {
                    id: 4,
                    type: "RecivedComandUpdateFirmware",
                    name: "(4) Команда обновления ПО (получение устройством)",
                },
                {
                    id: 5,
                    type: "RecivedComandUpdateParameters",
                    name: "(5) Команда обновления параметров (получение устройством)",
                },
                {
                    id: 6,
                    type: "RecivedComandSetCalibrationActive",
                    name: "(6) Команда обновления тарировки (получение устройством)",
                },
                {
                    id: 7,
                    type: "CancelComandUpdateFirmawre",
                    name: "(7) Команда обновления ПО (отмена)",
                },
                {
                    id: 8,
                    type: "CancelComandUpdateParameters",
                    name: "(8) Команда обновления тарировки (отмена)",
                },
                {
                    id: 9,
                    type: "CancelComandSetCalibrationActive",
                    name: "(9) Команда обновления тарировки (отмена)",
                },
                {
                    id: 10,
                    type: "PollingServer",
                    name: "(10) Опрос устройством сервера",
                },
            ]
        },
        AllActionSelected() {
            return this.filterModel.logsAction.length === this.LoggerActionSelect.length
        },
        SomeAction() {
            return this.filterModel.logsAction.length > 0 && !this.AllActionSelected
        },
        iconLogerAction() {
            if (this.AllActionSelected) return 'mdi-close-box'
            if (this.SomeAction) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        AllUsersSelected() {
            return this.filterModel.users.length === this.AllUsers.length
        },
        SomeUserSelected() {
            return this.filterModel.users.length > 0 && !this.AllUsersSelected
        },
        iconUsers() {
            if (this.AllUsersSelected) return 'mdi-close-box'
            if (this.SomeUserSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        isBusy() {
            if ((this.LoggerGetHaveError || this.LoggerGetAreLoaded) && (this.UsersGetAreLoaded || this.UsersGetHaveError)) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters([
            'UserById',
            'AllUsers',
            'AllDevices',
            'UsersGetHaveError',
            'UsersGetAreLoaded',
            'LoggerGetHaveError',
            'LoggerGetAreLoaded',
        ]),
    },

    async created() {
        if (!this.DevicesOnceLoaded) {
            await this.$store.dispatch(DEVICES_GET_REQUEST, "admin");
        }
        if (!this.UsersOnceLoaded) {
            await this.$store.dispatch(USERS_GET_REQUEST);
        }
    }, watch: {
        perPage(new_val) {
            localStorage.setItem('AdminLoggerTablePerPage', new_val)
        },
    },

    async mounted() {
        await this.InitModel()
        var perPage = localStorage.getItem('AdminLoggerTablePerPage');
        if (perPage != null) {
            this.perPage = parseInt(perPage)
        }
        else {
            this.perPage = 100;
        }
    },
    methods: {
        handleDateClick(date) {
        },
        async ClearFilterModel() {
            this.ClearDate();
            this.filterModel = this.defaultFilterModel;
            await this.InitModel();
        },
        ShowFilterModal() {
            this.$refs.FilterModal.Show();
        },
        GetDeviceText(item) {
            var name = item.name != null ? '(' + item.name + ')' : '';
            var devName = `${item.imei} ${name}`;
            return devName
        },
        async InitModel() {
            this.filterModel.logsAction = this.LoggerActionSelect.map(a => a.id);
            await this.GetReport();
        },

        async ChangeDevices() {


        }
        ,
        async ChangeUsers() {

        }
        ,
        async ChangeLogs() {


        },
        async ChangeDate() {
        },
        async GetReport() {
            this.loading = true;
            await this.$store.dispatch(LOGGER_PUT_REQUEST, { params: { StartDate: this.date_range.start, EndDate: this.date_range.end }, data: this.filterModel }).then(resp => {
                this.logs = resp.data;

                this.$nextTick(() => { this.loading = false; })
            })
                .catch(() => { this.logs = []; this.loading = false; })
        },
        async Refresh() {
            await this.GetReport();
            //await this.$store.dispatch(LOGGER_GET_REQUEST, "admin").then(resp => this.logs = resp.data)
        },
        ToggleLoggerAction() {
            this.$nextTick(() => {
                if (this.AllActionSelected) {
                    this.filterModel.logsAction = []
                } else {
                    this.filterModel.logsAction = this.LoggerActionSelect.map(a => a.id)
                }
            })
        },
        ToggleUsers() {
            this.$nextTick(() => {
                if (this.AllUsersSelected) {
                    this.filterModel.users = []
                } else {
                    this.filterModel.users = this.AllUsers.slice().map(a => a.id)
                }
            })
        },
        ClearDate() {
            this.date_range = { start: null, end: null };
        },
        GetDate(date) {
            moment.locale('ru')
            return moment(date).format(" DD.MM.YYYY/HH:mm:ss")
        }
    }
}
</script>
<style>
.logger-row {
    .col+.col {
        border-left: 1px solid #eeeeee;
        /*grey lightgreeb*/
    }
}
</style>