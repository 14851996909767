<template>
    <div class="content-body">
        <v-card outlined>
            <v-card-title>
                <v-row no-gutters align="center" justify="space-between">
                    <v-col class="d-flex flex-wrap align-center justify-space-between justify-md-start">
                        <div class="d-flex">
                            <v-icon small class="nav-icon mx-2"> fa fa-light fa-display</v-icon>
                            <h3 class="title">
                                Устройства
                            </h3>
                        </div>
                        <v-btn small icon @click="Refresh()">
                            <v-icon small style="cursor:pointer" title="Обновить" class="px-2">fa
                                fa-refresh</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Поиск" single-line hide-details
                            class="ma-0 pa-0"></v-text-field>
                    </v-col> </v-row>
            </v-card-title><!--custom-sort="deviceTableSort"-->
            <v-card-text class="pa-0">
                <v-data-table :hide-default-header="!$vuetify.breakpoint.mobile" show-group-by :sort-desc="true"
                    sortBy="imei" :search="filter" :fixed-header="!$vuetify.breakpoint.mobile"
                    :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint" loading-text="Загрузка..."
                    :header-props="header_props" :footer-props="footer_props" :loading="isBusy"
                    no-results-text="Не найдено" :headers="fields" :items="AllDevicesWithDevType" locale="ru-Ru"
                    :items-per-page.sync="perPage" class="elevation-0">
                    <template v-slot:header="HeaderOPT">
                        <thead v-if="!HeaderOPT.isMobile" class="v-data-table-header">
                            <tr>
                                <th v-for="header in HeaderOPT.props.headers" role="columnheader"
                                    :aria-label="header.text" scope="col" :key="header.value" class="text-center"
                                    :width="header.width"
                                    :class="[{ 'sortable': header.sortable }, header.class,
                                    { 'active': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value },
                                    { 'asc': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value && !HeaderOPT.props.options.sortDesc[0] },
                                    { 'desc': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value && HeaderOPT.props.options.sortDesc[0] }]">
                                    <span>{{ header.text }}</span>
                                    <div class="mx-1 d-inline-flex align-center" style="gap:5px">
                                        <v-icon small title="Группировка"
                                            v-if="header.groupable && HeaderOPT.props.showGroupBy"
                                            @click="HeaderOPT.on.group(header.value)" style="opacity: 1 !important">mdi
                                            mdi-format-list-group</v-icon>
                                        <v-icon small v-if="header.sortable" @click="HeaderOPT.on.sort(header.value)"
                                            class="v-data-table-header__icon">mdi-arrow-up</v-icon>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <!---<thead v-else-if="!$vuetify.breakpoint.mobile" class="v-data-table-header v-data-table-header-mobile">
                            <tr>
                                <th>
                                    <div class="v-data-table-header-mobile__wrapper">
                                        <v-select :items="HeaderOPT.props.headers.filter(a => a.sortable)"
                                            @change="ChangeSelectInMobileHeader($event, HeaderOPT.on)"
                                            :value="HeaderOPT.props.options.sortBy.length > 0 ? HeaderOPT.props.options.sortBy[0] : null"
                                            item-text="text" :label="HeaderOPT.props.sortByText">

                                            <template v-slot:selection="{ item, index }">
                                                <v-chip v-if="index === 0">
                                                    <span>{{ item.text }}</span>

                                                    <v-icon small @click.stop="HeaderOPT.on.sort(item.value)">{{
                                                        HeaderOPT.props.options.sortBy.length
                                                            > 0 && HeaderOPT.props.options.sortBy[0] == item.value &&
                                                            HeaderOPT.props.options.sortDesc[0] ?
                                                            'mdi-arrow-down' : 'mdi-arrow-up' }}</v-icon>
                                                </v-chip>
                                            </template>
</v-select>
</div>
</th>
</tr>
</thead>-->
                    </template>

                    <template v-slot:item.index="{ item, index, props }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.calibrationCount="{ item, index, prop }"><span
                            class="d-flex align-center justify-center">{{ item.calibrationCount }}
                            шт.</span>
                    </template>
                    <template v-slot:item.online="{ item, index, props }">
                        <v-lazy>
                            <v-icon :color="item.online ? 'rgb(101 229 107)' : 'rgb(231 231 231)'"
                                :title="!item.online ? OffTime(item.lastConnectionDateTime) : OnlineTime(item.lastConnectionDateTime)">mdi-circle</v-icon>
                        </v-lazy>
                    </template>

                    <template v-slot:no-data>
                        <span v-if="DevicesGetHaveError || UsersGetHaveError || DeviceTypesGetHaveError"
                            class="red--text">Ошибка загрузки данных</span>
                        <span v-if="DevicesGetAreLoaded && AllDevices.length == 0">Нет пользователй</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <span v-if="item.name != null">{{ item.name }}</span>
                        <span v-else class="d-flex justify-center"> - </span>
                    </template>

                    <template v-slot:item.softwareVersion="{ item, isMobile, header }">
                        <v-lazy>
                            <div class="d-flex gap-3 justify-space-between align-center">
                                <div class="flex-grow-1 d-flex flex-wrap  align-center justify-start"
                                    v-if="FirmwareFlag(item)"> {{
                                        item.softwareVersion }} <v-icon small>mdi
                                        mdi-arrow-right-thin</v-icon> {{ item.newSoftwareVersion }}</div>
                                <div v-else> {{ item.newSoftwareVersion != null ?
                                    item.newSoftwareVersion + '?' : item.softwareVersion }}
                                </div>
                                <div class="actions" style="cursor:pointer">
                                    <v-btn x-small v-if="FirmwareFlag(item)" icon color="red darken-3"
                                        @click="СancelFirmwareUpdate(item.id)" title="Отмена команды обновления ПО">
                                        <v-icon small>fa fa-x fa-icon-small</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-lazy>
                    </template>
                    <template v-slot:item.userName="{ item }">
                        <div class="d-flex gap-3 align-center justify-space-between">
                            <div>{{ item.userName }}</div>
                            <div class="actions" style="cursor:pointer">
                                <v-btn x-small color="primary" icon @click="ShowEditDeviceModal(item, 'user_device')"
                                    title="Передача контроля">
                                    <v-icon small>fa fa-pencil fa-icon-small</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-btn x-small color="red darken-3" icon @click="ShowDeleteDeviceModal(item)"
                            title="Удалить устройство">
                            <v-icon small>fa fa-trash fa-icon-small</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
                <v-modal ref="CreateDeviceModal" max-width="500" :persistent="persistent">
                    <template v-slot:MainModal>
                        <v-card :loading="loading">
                            <v-card-title v-if="!edit" class="text-h6 grey lighten-3">
                                Новое устройство
                            </v-card-title>
                            <v-card-title v-else class="text-h6 grey lighten-3">
                                {{ edit_title }}
                            </v-card-title>
                            <v-card-text class="pt-1 pb-1">
                                <v-form v-model="valid" ref="form_devices" id="form_devices">
                                    <v-text-field v-model="edited_item.imei" :rules="IMEI_rules" label="IMEI"
                                        placeholder="447402515437650" counter="15" :disabled="loading || edit"
                                        required></v-text-field>
                                    <v-text-field v-if="edit_type == null || edit_type == 'common_parametrs'"
                                        v-model="edited_item.name" :rules="name_rules" label="Наименование"
                                        placeholder="Устройство 1" :disabled="loading" required></v-text-field>
                                    <v-autocomplete no-data-text="Нет пользователей, которым доступен данный тип"
                                        v-if="edit_type == null || edit_type == 'user_device'"
                                        :item-text='(item) => `${item.name} (${item.login})`' item-value="id"
                                        :items="UsersByDeviceTypeId(edited_item.deviceTypeID)" :rules="name_rules"
                                        label="Пользователь" placeholder="Выберите пользователя кстройства"
                                        v-model="edited_item.userID" :disabled="loading" required></v-autocomplete>
                                    <!--append-icon="fa fa-solid fa-caret-down fa-2xs" clear-icon="fa fa-solid fa-xmark fa-2xs"-->
                                </v-form>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" form="form_devices" depressed @click="CreateOrEditDevice"
                                    :disabled="loading || !valid">
                                    Сохранить
                                </v-btn>
                                <v-btn color="error" depressed @click="$refs.CreateDeviceModal.Hide()"
                                    :disabled="loading">
                                    Закрыть
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-modal>
                <v-modal ref="DeleteDeviceModal" max-width="350" :persistent="persistent">
                    <template v-slot:MainModal>
                        <v-card :loading="loading">
                            <v-card-title class="text-h6 grey lighten-3">
                                Удаление устройства
                            </v-card-title>
                            <v-card-text class="py-1">
                                Вы действительно хотите удалить устройство с IMEI "{{ edited_item.imei }}"?
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" depressed @click="DeleteDevice" :disabled="loading">
                                    Да
                                </v-btn>
                                <v-btn color="error" depressed @click="$refs.DeleteDeviceModal.Hide()"
                                    :disabled="loading">
                                    Нет
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-modal>
                <v-modal ref="EmptyModal" persistent width="500" />

            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import {
    cls_center,
    cls_left
} from "@/helpers/const";
import {
    eventBus
} from "@/main.js";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            valid: true,
            edit_type: null,
            edit_title: null,
            items_test: [{
                "id": 0,
                "imei": "123456789012345",
                "deviceTypeID": 0,
                "name": "Устройство 1",
                "deviceTypeName": "Тип 1",
                "hardwareVersion": 0,
                "softwareVersion": 0,
                "userID": 0,
                "userName": "string",
                "calibrationCount": 0,
            }
            ],
            filtered_items: [],
            filter: "",
            /*Столбцы таблицы*/
            footer_props: {
                'items-per-page-all-text': 'Все',
                'items-per-page-text': 'Строк на странице:',
                'items-per-page-options': [
                    50,
                    100,
                    500,
                    1000,
                    -1,
                ],
                'pageText': '{0}-{1} из {2}'
            },
            itemsPerPageOptionsDefault: [
                50,
                100,
                500,
                1000,
                -1,
            ],
            header_props: {
                'sort-by-text': 'Сортировать по'
            },
            fields: [/*{
                text: '',
                sortable: false,
                align: "center",
                value: "index",
                cellClass: ["text-center", "align-middle"],
                class: " grey lighten-3",
                width: 75,
            },*/    {
                    text: "Cтатус",
                    align: "center",
                    sortable: true,
                    groupable: false,
                    value: "online",
                    cellClass: ["text-center", "align-middle"],
                    class: "grey lighten-3"
                },
                {
                    text: 'Id',
                    sortable: true,
                    groupable: false,
                    align: "center",
                    value: "id",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3",
                    width: 75,
                },

                {
                    text: "IMEI",
                    align: "center",
                    groupable: false,
                    sortable: true,
                    value: "imei",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Наименование",
                    align: "center",
                    sortable: true,
                    groupable: false,
                    value: "name",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Тип",
                    align: "center",
                    sortable: true,
                    groupable: false,
                    value: "deviceTypeName",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Версия АО",
                    align: "center",
                    sortable: true,
                    groupable: true,
                    value: "hardwareVersion",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Версия ПО",
                    align: "center",
                    groupable: true,
                    sortable: true,
                    value: "softwareVersion",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Тарировки",
                    align: "center",
                    groupable: false,
                    sortable: false,
                    value: "calibrationCount",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Пользователь",
                    align: "center",
                    groupable: false,
                    sortable: true,
                    value: "userName",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: '',
                    value: 'delete',
                    groupable: false,
                    sortable: false,
                    cellClass: ["text-start", "align-middle"],
                    width: 10,
                    class: " grey lighten-3"
                },
            ],
            edit: false,
            /*Связь с модальным окном*/
            dialog: false,
            /*поля ввода*/
            default_item: {
                "id": "",
                "imei": "",
                "deviceTypeID": "",
                "name": "",
                "deviceTypeName": "",
                "hardwareVersion": 0,
                "softwareVersion": 0,
                "userID": "",
                "userName": "",
                "calibrationCount": "",
            },
            edited_item: {
                "id": "",
                "imei": "",
                "deviceTypeID": "",
                "name": "",
                "deviceTypeName": "",
                "hardwareVersion": 0,
                "softwareVersion": 0,
                "userID": "",
                "userName": "",
                "calibrationCount": "",
            },
            /*правила валидации*/
            name_rules: [
                v => !!v || 'Поле должно быть заполнено',
            ],
            IMEI_rules: [
                v => !!v || 'Поле должно быть заполнено',
                v => /[0-9]/.test(v) || 'IMEI должен содержать только цифры 0 - 9',
                v => /^[1-9]/.test(v) || 'Первая цифра не ноль',
                v => v && v.length == 15 || 'IMEI должен содержать 15 цифр',
            ],
            software_hardware_rules: [
                v => !!v || 'Поле должно быть заполнено',
                v => /[0-9]/.test(v) || 'Поле должно содержать только цифры 0 - 9',
            ],
            password_rules: [
                v => !!v || 'Поле должно быть заполнено',
                v => /\w/.test(v) || 'Только латинские буквы, "_", цифры: 0 - 9',
                v => v && v.length > 3 || 'Минимум 4 символа',
                v => v && v.length < 21 || 'Максимум 20 символов',
            ],
            /*Отчёт о выполнении действия*/
            persistent: false,
            perPage: 100,
            currentPage: 1,
            action: {
                type: null,
                method: null
            },
        };
    },
    computed: {
        AllDevicesWithDevType() {
            return this.AllDevices.map(a => {
                let itemsArray = Object.assign({}, a);
                itemsArray.deviceTypeName = this.DeviceTypeById(a.deviceTypeID)?.name ?? null;
                itemsArray.hardwareVersion = this.DeviceTypeById(a.deviceTypeID)?.hardwareVersion ?? null
                return itemsArray;
            }
            )
        },
        loading() {
            switch (this.action.method) {
                case "get":
                    if (this.action.type === "devices") return this.DevicesGetAreLoading;
                    break;
                case "post":
                    if (this.action.type === "devices") return this.DevicesPostAreLoading;
                    break;
                case "put":
                    if (this.action.type === "devices") return this.DevicesPutAreLoading;
                    break;
                case "delete":
                    if (this.action.type === "devices") return this.DevicesDeleteAreLoading;
                    break;
                default:
                    return false;
            }
        },
        isBusy() {
            if ((this.UsersGetHaveError || this.UsersGetAreLoaded) && (this.DevicesGetHaveError || this.DevicesGetAreLoaded) && (this.DeviceTypesGetHaveError || this.DeviceTypesGetAreLoaded)) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters([
            "AllUsers",
            "UserById",
            "UsersOnceLoaded",
            "UsersGetAreLoading",
            "UsersGetHaveError",
            "UsersGetAreLoaded",
            "UsersPostAreLoading",
            "UsersPostHaveError",
            "UsersPostAreLoaded",
            "UsersPutAreLoading",
            "UsersPutHaveError",
            "UsersPutAreLoaded",
            "UsersDeleteAreLoading",
            "UsersDeleteHaveError",
            "UsersDeleteAreLoaded",
            "AllDevices",
            "DeviceById",
            "DevicesOnceLoaded",
            "DevicesGetAreLoading",
            "DevicesGetHaveError",
            "DevicesGetAreLoaded",
            "DevicesPostAreLoading",
            "DevicesPostHaveError",
            "DevicesPostAreLoaded",
            "DevicesPutAreLoading",
            "DevicesPutHaveError",
            "DevicesPutAreLoaded",
            "DevicesDeleteAreLoading",
            "DevicesDeleteHaveError",
            "DevicesDeleteAreLoaded",
            "AllDeviceTypes",
            "DeviceTypeById",
            "DeviceTypesOnceLoaded",
            "DeviceTypesGetAreLoading",
            "DeviceTypesGetHaveError",
            "DeviceTypesGetAreLoaded",
            'UsersByDeviceTypeId',
        ]),
    },
    async created() {
        if (!this.UsersOnceLoaded) {
            await this.$store.dispatch(USERS_GET_REQUEST);
        }
        if (!this.DevicesOnceLoaded) {
            await this.$store.dispatch(DEVICES_GET_REQUEST, "admin");
        }
        if (!this.DeviceTypesOnceLoaded) {
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
        }
    },
    beforeDestroy() {
        eventBus.$off['UpdateParametersAcceptedResponse', 'UpdateFirmwareAcceptedResponse', 'UpdateCalibrationResponse', 'UpdateFirmwareOKResponse', 'UpdateParametersOkResponse', 'UpdateCalibrationOKResponse']
    },
    watch: {
      
        perPage(new_val) {

            localStorage.setItem('AdminDeviceTablePerPage', new_val)
        },
    },
    mounted() {
        var perPage = localStorage.getItem('AdminDeviceTablePerPage');
        if (perPage != null) {
            this.perPage = parseInt(perPage)
        }
        else {
            this.perPage = 100;
        }
    },
    methods: {
        СancelFirmwareUpdate(device_id) {
            this.$store.dispatch(CANCEL_FIRMWARE_UPDATE_PUT_REQUEST, device_id).then(() => { this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "admin", device_id: device_id }) }
            ).catch(err => {
                err = "Ошибка отмены команды обновления ПО"
                this.$refs.EmptyModal.Error(err);
            })
        },
        FirmwareFlag(item) {
            return item.flagUpdateFirware
        },
        isNumber(num) {
            return typeof num === 'number' && !Number.isNaN(num);
        },

        deviceTableSort(items, index, isDesc) {
            return items.sort((a, b) => {
                if (index[0] == 'hardwareVersion') {
                    var elemA = this.DeviceTypeById(a.deviceTypeID)?.hardwareVersion;
                    var elemB = this.DeviceTypeById(b.deviceTypeID)?.hardwareVersion;
                    if (!isDesc[0]) {
                        return elemA - elemB
                    }
                    else {
                        return elemB - elemA;
                    }
                }
                else if (index[0] == 'deviceTypeName') {
                    var elemA = this.DeviceTypeById(a.deviceTypeID)?.name;
                    var elemB = this.DeviceTypeById(b.deviceTypeID)?.name;
                    if (!isDesc[0]) {
                        return elemA < elemB ? -1 : 1;
                    }
                    else {
                        return elemB < elemA ? -1 : 1;
                    }
                }
                else if (index[0] == 'userName') {
                    if (a[index[0]] === null) {
                        return -1;
                    }
                    if (b[index[0]] === null) {
                        return 1;
                    }
                    if (!isDesc[0]) {
                        return (a[index[0]] < b[index[0]]) ? -1 : 1;
                    } else {
                        return (b[index[0]] < a[index[0]]) ? -1 : 1;
                    }
                }

                else if ((this.isNumber(a[index[0]]))) {
                    if (a[index[0]] === null) {
                        return 1;
                    }
                    if (b[index[0]] === null) {
                        return -1;
                    }
                    if (!isDesc[0]) {
                        return (a[index[0]] - b[index[0]]);
                    } else {
                        return (b[index[0]] - a[index[0]]);
                    }
                } else {

                    if (a[index[0]] === null) {
                        return 1;
                    }
                    if (b[index[0]] === null) {
                        return -1;
                    }
                    if (!isDesc[0]) {
                        return (a[index[0]] < b[index[0]]) ? -1 : 1;
                    } else {
                        return (b[index[0]] < a[index[0]]) ? -1 : 1;
                    }
                }
            });
            return items;
        },
        OnlineTime(elem) {
            var text1 = "Активен c" + moment(elem).format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        OffTime(elem) {
            //window.momentDurationFormatSetup();
            var now = moment();
            var before = moment(elem);
            var text = moment.duration(now.diff(before)).format('YYYY[ л.] D[ дн.] H[ ч.] m[ м.] s[ с.]');
            var text1 = "Выкл. " + text + ". c" + before.format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        async Refresh() {
            await this.$store.dispatch(USERS_GET_REQUEST);
            await this.$store.dispatch(DEVICES_GET_REQUEST, "admin");
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
        },
        ShowCreateDeviceModal() {
            this.edit_type = null;
            this.edit = false;
            this.edited_item = Object.assign({}, this.default_item)
            this.$refs.CreateDeviceModal.Show();
            if (this.$refs.form_devices) this.$refs.form_devices.resetValidation()
        },
        ShowEditDeviceModal(item, type) {
            this.edit_type = type;
            switch (type) {
                case "common_parametrs":
                    this.edit_title = "Общие параметры";
                    break;
                case "device_type":
                    this.edit_title = "Тип устройства";
                    break;
                case "user_device":
                    this.edit_title = "Пользователь устройства";
                    break;
                default:
                    break;
            }
            this.edit = true;
            this.edited_item = Object.assign({}, item)
            this.$refs.CreateDeviceModal.Show();
            if (this.$refs.form_devices) this.$refs.form_devices.resetValidation()
        },
        async CreateOrEditDevice() {
            this.persistent = true;
            if (this.edit) {
                this.action = {
                    type: "devices",
                    method: "put"
                };
                if (this.edit_type == "user_device") {
                    await this.$store.dispatch(DEVICES_PUT_TRANSFER_REQUEST, {
                        user_id: this.edited_item.userID,
                        device_id: this.edited_item.id
                    }).then(() => {
                        this.$refs.CreateDeviceModal.Success();
                    }).catch(err => {
                        this.$refs.CreateDeviceModal.Error(err);
                    })
                } else
                //  let user = JSON.parse(JSON.stringify(this.UserById(this.edited_item.id)));
                {
                    await this.$store.dispatch(DEVICES_PUT_REQUEST, this.edited_item).then(() => {
                        this.$refs.CreateDeviceModal.Success();
                    }).catch(err => {
                        this.$refs.CreateDeviceModal.Error(err);
                    })
                }
            } else {
                this.action = {
                    type: "devices",
                    method: "post"
                };
                await this.$store.dispatch(DEVICES_POST_REQUEST, this.edited_item).then(() => {
                    this.$refs.CreateDeviceModal.Success();
                }).catch(err => {
                    this.$refs.CreateDeviceModal.Error(err);
                })
            }
        },
        ShowDeleteDeviceModal(item) {
            this.edited_item = Object.assign({}, item)
            this.$refs.DeleteDeviceModal.Show();
        },
        async DeleteDevice() {
            this.persistent = true;
            this.action = {
                type: "devices",
                method: "delete"
            }
            await this.$store.dispatch(DEVICES_DELETE_REQUEST, this.edited_item.id).then(() => {
                this.$refs.DeleteDeviceModal.Success();
            }).catch(err => {
                this.$refs.DeleteDeviceModal.Error(err);
            })
        },
    }
}
</script>
