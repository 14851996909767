//#region AUTH
export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";
export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGIN_ERROR"
export const AUTH_GET_MAGIC_TOKEN_REQUEST = 'AUTH_GET_MAGIC_TOKEN_REQUEST'
export const AUTH_GET_MAGIC_TOKEN_SUCCESS = 'AUTH_GET_MAGIC_TOKEN_SUCCESS'
export const AUTH_GET_MAGIC_TOKEN_ERROR = 'AUTH_GET_MAGIC_TOKEN_ERROR'
//#endregion
//#region USERS
export const USERS_GET_REQUEST = "USERS_GET_REQUEST";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_GET_ERROR = "USERS_GET_ERROR";
export const USERS_POST_REQUEST = "USERS_POST_REQUEST";
export const USERS_POST_SUCCESS = "USERS_POST_SUCCESS";
export const USERS_POST_ERROR = "USERS_POST_ERROR";
export const USERS_PUT_REQUEST = "USERS_PUT_REQUEST";
export const USERS_PUT_SUCCESS = "USERS_PUT_SUCCESS";
export const USERS_PUT_ERROR = "USERS_PUT_ERROR";
export const USERS_DELETE_REQUEST = "USERS_DELETE_REQUEST";
export const USERS_DELETE_SUCCESS = "USERS_DELETE_SUCCESS";
export const USERS_DELETE_ERROR = "USERS_DELETE_ERROR";
//#endregion
//#region DEICES
export const DEVICES_GET_REQUEST = "DEVICES_GET_REQUEST";
export const DEVICES_GET_BY_ID_REQUEST = "DEVICES_GET_BY_ID_REQUEST";
export const DEVICES_GET_SUCCESS = "DEVICES_GET_SUCCESS";
export const DEVICES_GET_ERROR = "DEVICES_GET_ERROR";
export const DEVICE_TYPES_GET_REQUEST = "DEVICE_TYPES_GET_REQUEST";
export const DEVICE_TYPES_GET_SUCCESS = "DEVICE_TYPES_GET_SUCCESS";
export const DEVICE_TYPES_GET_ERROR = "DEVICE_TYPES_GET_ERROR";
export const DEVICE_TYPES_PUT_REQUEST = "DEVICE_TYPES_PUT_REQUEST";
export const DEVICE_TYPES_PUT_SUCCESS = "DEVICE_TYPES_PUT_SUCCESS";
export const DEVICE_TYPES_PUT_ERROR = "DEVICE_TYPES_PUT_ERROR";
export const DEVICES_POST_REQUEST = "DEVICES_POST_REQUEST";
export const DEVICES_POST_SUCCESS = "DEVICES_POST_SUCCESS";
export const DEVICES_POST_ERROR = "DEVICES_POST_ERROR";
export const DEVICES_PUT_REQUEST = "DEVICES_PUT_REQUEST";
export const DEVICES_PUT_SUCCESS = "DEVICES_PUT_SUCCESS";
export const DEVICES_PUT_ERROR = "DEVICES_PUT_ERROR";
export const DEVICES_PUT_TRANSFER_REQUEST = "DEVICES_PUT_TRANSFER_REQUEST";
export const DEVICES_PUT_TRANSFER_SUCCESS = "DEVICES_PUT_TRANSFER_SUCCESS";
export const DEVICES_PUT_TRANSFER_ERROR = "DEVICES_PUT_TRANSFER_ERROR";
export const DEVICES_DELETE_REQUEST = "DEVICES_DELETE_REQUEST";
export const DEVICES_DELETE_SUCCESS = "DEVICES_DELETE_SUCCESS";
export const DEVICES_DELETE_ERROR = "DEVICES_DELETE_ERROR";
//#endregion
//#region PARAMETERS
export const PARAMETERS_GET_REQUEST = "PARAMETERS_GET_REQUEST";
export const PARAMETERS_GET_SUCCESS = "PARAMETERS_GET_SUCCESS";
export const PARAMETERS_GET_ERROR = "PARAMETERS_GET_ERROR";
export const PARAMETERS_PUT_REQUEST = "PARAMETERS_PUT_REQUEST";
export const PARAMETERS_PUT_SUCCESS = "PARAMETERS_PUT_SUCCESS";
export const PARAMETERS_PUT_ERROR = "PARAMETERS_PUT_ERROR";
export const PARAMETERS_DELETE_REQUEST = "PARAMETERS_DELETE_REQUEST";
export const PARAMETERS_DELETE_SUCCESS = "PARAMETERS_DELETE_SUCCESS";
export const PARAMETERS_DELETE_ERROR = "PARAMETERS_DELETE_ERROR";

export const CAN_FILE_PUT_REQUEST ="CAN_FILE_PUT_REQUEST";
export const CAN_FILE_PUT_ERROR ="CAN_FILE_PUT_ERROR";
export const CAN_FILE_PUT_SUCCESS="CAN_FILE_PUT_SUCCESS";

//#region FIRMWARES
export const FIRMWARES_GET_REQUEST = "FIRMWARES_GET_REQUEST";
export const FIRMWARES_GET_BY_DEVICE_REQUEST = "FIRMWARES_GET_BY_DEVICE_REQUEST";
export const FIRMWARES_GET_SUCCESS = "FIRMWARES_GET_SUCCESS";
export const FIRMWARES_GET_ERROR = "FIRMWARES_GET_ERROR";
export const FIRMWARES_UPLOAD_PUT_REQUEST = "FIRMWARES_UPLOAD_PUT_REQUEST";
export const FIRMWARES_STATE_PUT_REQUEST = "FIRMWARES_STATE_PUT_REQUEST";
export const FIRMWARES_UPDATE_PUT_REQUEST = "FIRMWARES_UPDATE_PUT_REQUEST";
export const FIRMWARES_UPDATE_MULTIPLY_PUT_REQUEST ="FIRMWARES_UPDATE_MULTIPLY_PUT_REQUEST"
export const FIRMWARES_PUT_REQUEST = "FIRMWARES_PUT_REQUEST";
export const FIRMWARES_PUT_SUCCESS = "FIRMWARES_PUT_SUCCESS";
export const FIRMWARES_PUT_ERROR = "FIRMWARES_PUT_ERROR";

export const FIRMWARES_DELETE_REQUEST = "FIRMWARES_DELETE_REQUEST";
export const FIRMWARES_DELETE_SUCCESS = "FIRMWARES_DELETE_SUCCESS";
export const FIRMWARES_DELETE_ERROR = "FIRMWARES_DELETE_ERROR";

//#endregion
//#region CALIBRATIONS
export const CALIBRATIONS_GET_REQUEST = "CALIBRATIONS_GET_REQUEST";
export const CALIBRATIONS_GET_SUCCESS = "CALIBRATIONS_GET_SUCCESS";
export const CALIBRATIONS_GET_ERROR = "CALIBRATIONS_GET_ERROR";
export const DEVICES_WTIH_CALIBRATIONS_REQUEST = "DEVICES_WTIH_CALIBRATIONS_REQUEST";
export const CALIBRATIONS_POST_REQUEST = "CALIBRATIONS_POST_REQUEST";
export const CALIBRATIONS_POST_FROM_TO_REQUEST ="CALIBRATIONS_POST_FROM_TO_REQUEST"
export const CALIBRATIONS_POST_SUCCESS = "CALIBRATIONS_POST_SUCCESS";
export const CALIBRATIONS_POST_ERROR = "CALIBRATIONS_POST_ERROR";
export const CALIBRATIONS_PUT_REQUEST = "CALIBRATIONS_PUT_REQUEST";
export const CALIBRATIONS_PUT_SUCCESS = "CALIBRATIONS_PUT_SUCCESS";
export const CALIBRATIONS_PUT_ERROR = "CALIBRATIONS_PUT_ERROR";
export const CALIBRATIONS_DELETE_REQUEST = "CALIBRATIONS_DELETE_REQUEST";
export const CALIBRATIONS_DELETE_SUCCESS = "CALIBRATIONS_DELETE_SUCCESS";
export const CALIBRATIONS_DELETE_ERROR = "CALIBRATIONS_DELETE_ERROR";
export const CALIBRATIONS_PUT_ACTIVE_REQUEST = "CALIBRATIONS_PUT_ACTIVE_REQUEST";
export const CALIBRATIONS_PUT_DEACTIVE_REQUEST = "CALIBRATIONS_PUT_DEACTIVE_REQUEST";
export const CALIBRATIONS_GET_REFRESH_REQUEST = "CALIBRATIONS_GET_REFRESH_REQUEST";


//#endregion
//#region CANCEL_COMMAND
export const CANCEL_FIRMWARE_UPDATE_PUT_REQUEST="CANCEL_FIRMWARE_UPDATE_PUT_REQUEST"
export const CANCEL_FIRMWARE_UPDATE_PUT_SUCCESS="CANCEL_FIRMWARE_UPDATE_PUT_SUCCESS"
export const CANCEL_FIRMWARE_UPDATE_PUT_ERROR="CANCEL_FIRMWARE_UPDATE_PUT_ERROR"
export const CANCEL_CALIBRATION_ACTIVE_PUT_REQUEST="CANCEL_CALIBRATION_ACTIVE_PUT_REQUEST"
export const CANCEL_CALIBRATION_ACTIVE_PUT_ERROR="CANCEL_CALIBRATION_ACTIVE_PUT_ERROR"
export const CANCEL_CALIBRATION_ACTIVE_PUT_SUCCESS="CANCEL_CALIBRATION_ACTIVE_PUT_SUCCESS"
export const CANCEL_PATAMETERS_UPDATE_PUT_REQUEST ="CANCEL_PATAMETERS_UPDATE_PUT_REQUEST"
export const CANCEL_PATAMETERS_UPDATE_PUT_ERROR ="CANCEL_PATAMETERS_UPDATE_PUT_ERROR"
export const CANCEL_PATAMETERS_UPDATE_PUT_SUCCESS ="CANCEL_PATAMETERS_UPDATE_PUT_SUCCESS"
//#endregion
//#region LOGGER
export const LOGGER_GET_REQUEST="LOGGER_GET_REQUEST"
export const LOGGER_PUT_REQUEST="LOGGER_PUT_REQUEST"
export const LOGGER_GET_SUCCESS ="LOGGER_GET_SUCCESS"
export const LOGGER_GET_ERROR="LOGGER_GET_ERROR"
//#endregion
export const SET_HTTP_ERROR_STATUS = "SET_HTTP_ERROR_STATUS";
export const UPDATE_CONNECTION_ERROR="UPDATE_CONNECTION_ERROR";
export const CLEAR_FIRST_SHOW_STATUS = "CLEAR_FIRST_SHOW_STATUS";
export const CLEAR_VUEX_STATE = "CLEAR_VUEX_STATE";
export const CHECK_HTTP_ERROR = 'CHECK_HTTP_ERROR'
