import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import Vuetify from 'vuetify/lib'
import * as exp from '@/helpers/actions.js';
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@/assets/sass/main.scss'
//Vue.use(Vuetify)
Object.entries(exp).forEach(([name, exported]) => { window[name] = exported; });
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  transition: "Vue-Toastification__fade",
  pauseOnHover: false,
  hideProgressBar: true,
  closeOnClick: false,
  position: "bottom-right",
  draggable: true,
  timeout: 5000,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
  closeButton: "button",
   filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
  // You can set your default options here
};
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vs',
});
Vue.use(Toast, options)
Vue.config.productionTip = false
export const eventBus = new Vue()
import ModalDialog from '@/components/ModalDialog.vue';
import Preloader from '@/components/Preloader.vue'
import HeaderPanel from '@/components/HeaderPanel.vue'
import SideNavPanel from '@/components/SideNavPanel.vue'
import VCardReport from '@/components/VCardReport.vue'
import SweetError from '@/components/SweetErrorComponent.vue'
import SweetSuccess from '@/components/SweetSuccessComponent.vue'
import VuePaginateScroll from "vue-paginate-scroll";


Vue.component('vue-paginate-scroll', VuePaginateScroll);

Vue.component('v-modal', ModalDialog)
Vue.component('preloader', Preloader)
Vue.component('header-panel', HeaderPanel)
Vue.component('v-card-report', VCardReport)
Vue.component('sidenav-panel', SideNavPanel)
Vue.component('sweet-success', SweetSuccess)
Vue.component('sweet-error', SweetError)
import '../public/assets/css/plugins/sweetAlert.css'
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
