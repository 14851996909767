<template>
    <section>
        <v-app-bar :style="{ 'margin-left': ml }" class="header-section" outlined color="white"
            :height="hasMiniVariant ? 40 : 72" app elevation=0>
            <v-btn icon class="btn-menu-open" v-if="hasMiniVariant" @click="openSideBar()"> <v-icon>mdi
                    mdi-menu</v-icon></v-btn>
            <v-spacer></v-spacer>
            <div class="me-2">
                <span class="text-right">
                    <b>{{ name }}</b>
                </span>
                <br>
                <span v-if="auth_of != null" class="fw-normal">
                    ({{ auth_of }})
                </span>
            </div>
            <v-icon @click="logout()">
                mdi-logout
            </v-icon>
        </v-app-bar>
        <v-divider></v-divider>
    </section>
</template>
<script>
import { eventBus } from '@/main';
export default {
    props: {
        hasMiniVariant: {
            default: false,
        },
        ml: {
            default: 0,
        },
    },
    data: function () {
        return {
            date: new Date().toLocaleDateString(),
            fio: 'Иванов Иван Иванович',
            name: null,
            icon_menu: 'fas fa-bars',
            icon_close: 'fa fa-close',
            is_open: false,
            auth_of: null,
        };
    },
    async created() {
        let user_id;
        let user_name
        let user_role
        await getUserToken().then(async el => {
            user_id = el.userID;
            user_role = el.role
            user_name = el.userFullName
        });
        if (user_role == "admin") {
            this.name = "SuperAdmin";
            if (this.$route.path.split('/')[1] == "user") {
                await getMagicToken().then(resp => this.auth_of = resp.userFullName)
            }
        }
        else { this.auth_of = null; this.name = user_name }
    },
    methods: {
        openSideBar() {
            eventBus.$emit('openSideBar')
        },
        async logout() {
            this.$store.dispatch(AUTH_LOGOUT_REQUEST)
            //  await deleteUserToken()
            // this.$router.push({ name: "Login" })
        }
    },
}
</script>
<style>
.header-section .v-toolbar__content {
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, .12) !important;
}

@media screen and (min-width: 1401px) {
    .sidenav-toggle {
        display: none !important;
    }
}
</style>