<template>
    <v-navigation-drawer touchless fixed v-model="drawer" v-click-outside="ClickOutside"
        :temporary="hasMiniVariant && !mini" stateless :mini-variant="mini" :mini-variant-width="miniSize">
        <v-list>
            <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-img src="@/assets/images/logo/logo.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-h5">
                        WebConfig
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav dense v-if="admin_area">
            <v-list-item-group color="primary">
                <v-list-item link v-for="(elem, index) in path_admin" :key="elem.url"
                    @click.native="SetActive(path_client, index)" :to="{ path: elem.url }">
                    <v-list-item-icon>
                        <v-icon small>{{ elem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ elem.text }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <v-list nav dense v-else>
            <v-list-item-group color="primary">
                <v-list-item link v-for="(elem, index) in path_client" :key="elem.url"
                    @click.native="SetActive(path_admin, index)" :to="{ path: elem.url }">
                    <v-list-item-icon>
                        <v-icon small>{{ elem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ elem.text }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { eventBus } from '@/main';
export default {
    props: {
        miniSize: {
            type: Number,
            default: 56
        }
    },
    computed: {
        hasOpenState() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return false;
                default: return true;
            }
        },
        hasMiniVariant() {
            return this.$vuetify.breakpoint.mdAndDown;
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true;
                case 'sm': return true;
                case 'md': return true;
                case 'lg': return false;
                case 'xl': return false;
            }
        },
        admin_area() {
            return this.$route.fullPath.indexOf("admin") > -1
        }
    },
    beforeDestroy() {
        eventBus.$off(['openSideBar'])
    },
    mounted() {
        eventBus.$on("openSideBar", () => {
            this.mini = !this.mini;
            this.$nextTick(() => this.drawer = true)
        })
        this.initView();
        /*
            $('#sidenav-menu').on('click', 'a', function (e) {
              var $this = $(this);
              if ($this.attr('href') === '#') {
                e.preventDefault();
                if ($this.siblings('.sub-menu:visible').length) {
                  $this.parent().removeClass('open').find('.sub-menu').slideUp().parent().removeClass('open');
                } else {
                  $this.siblings('.sub-menu').slideDown().parent().addClass('open').siblings().find('.sub-menu').slideUp().parent().removeClass('open');
                }
              }
            });
        */
    },
    data() {
        return {
            path_admin: [{
                url: "/admin/devices",
                text: "Устройства",
                icon: 'fa fa-light fa-display',
                children: [],
                active: this.$route.path === "/admin/devices" ? true : false,
            },
            {
                url: "/admin/users",
                text: "Пользователи",
                icon: 'fa fa-light fa-users ',
                children: [],
                active: this.$route.path === "/admin/users" ? true : false,
            },
            {
                url: "/admin/firmwares",
                text: "Прошивки",
                icon: 'fa fa-paste',
                children: [],
                active: this.$route.path === "/admin/firmwares" ? true : false,
            },
            {
                url: "/admin/logger",
                text: "Логи",
                icon: 'fa fa-file-edit',
                children: [],
                active: this.$route.path === "/admin/logger" ? true : false,
            },
            ],
            path_client: [{
                url: "/user/devices",
                text: "Устройства",
                icon: 'fa fa-light fa-display',
                children: [],
                active: this.$route.path === "/user/devices" ? true : false,
            },
            {
                url: "/user/logger",
                text: "Логи",
                icon: 'fa fa-file-edit',
                children: [],
                active: this.$route.path === "/user/logger" ? true : false,
            },
            ],
            mini: false,
            drawer: false,
            name: null,
        }
    },
    watch: {
        hasMiniVariant(new_value, old_value) {
            if (old_value == false && new_value == true) { this.mini = true; }
            if (old_value == true && new_value == false) { this.mini = false; }
            this.$emit('hasMiniVariantChange', new_value)
        },
        hasOpenState(new_value, old_value) {
            if (old_value == false && new_value == true) { this.drawer = true; }
            if (old_value == true && new_value == false) { this.drawer = false; }
            this.$emit('hasOpenState', new_value)

        },

    },
    methods: {
        ClickOutside(e) {
            if (e.target.className === "v-overlay__scrim") {
                this.drawer = this.hasOpenState;
                this.mini = this.hasMiniVariant;
            }
        },
        initView() {
            var hasMini = this.hasMiniVariant
            var openState = this.hasOpenState
            this.$emit('hasMiniVariantChange', hasMini)
            this.$emit('hasOpenState', openState)

            if (hasMini) this.mini = true;
            else this.mini = false;
            if (openState) this.drawer = true;
            else this.drawer = false;
        },
        SetActive(array, index) {
            this.drawer = this.hasOpenState;
            this.mini = this.hasMiniVariant
            for (var i = 0; i < array.length; i++) {
                if (i == index) {
                    if (!array[i].active) array[i].active = true;
                } else {
                    array[i].active = false;
                }
            }
        },
        ToggleOpen(array, index) {
            array[index].open = !array[index].open
        },
        Select: function () {
            if (this.isFolder) {
                this.$emit('select', this.guid);
            }
        }
    }
}
</script>
