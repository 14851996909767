<template>
    <div>
        <!--Default Data Table Start-->
        <v-card outlined>
            <v-card-title>
                <v-row no-gutters align="center" justify="space-between">
                    <v-col style="gap:3px" class="d-flex flex-wrap align-center justify-space-between justify-md-start">
                        <div class="d-flex">
                            <v-icon small class="nav-icon mx-2">fa fa-users</v-icon>
                            <h3 class="title">
                                Пользователи
                            </h3>
                        </div>
                        <v-item-group>
                            <v-btn small icon @click="Refresh()"><v-icon small style="cursor:pointer" title="Обновить"
                                    class="mx-2">fa
                                    fa-refresh</v-icon></v-btn>
                            <v-btn  outlined @click.stop="ShowCreateUserModal" color="green lighten-2">
                                <v-icon small left>
                                    fa fa-plus
                                </v-icon>
                                Добавить
                            </v-btn>
                            
                        </v-item-group>
                    </v-col>
                    <v-col cols="12" md="4" lg="2">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Поиск" single-line
                            class="ma-0 pa-0" hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-data-table :search="filter" :fixed-header="!$vuetify.breakpoint.mobile" :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint" loading-text="Загрузка..." :header-props="header_props"
                    :footer-props=footer_props :loading="isBusy" no-results-text="Не найдено" :headers="fields"
                    :items="AllUsers" locale="ru-Ru" :items-per-page.sync="perPage" class="elevation-0">
                    <!-- items_test-->
                    <template v-slot:no-data>
                        <span v-if="UsersGetHaveError || DevicesGetHaveError || DeviceTypesGetHaveError"
                            class="red--text">Ошибка загрузки данных</span>
                        <span v-if="UsersGetAreLoaded && AllUsers.length == 0">Нет пользователй</span>
                    </template>
                    <template v-slot:item.deviceTypes="{ item }">

                        <span v-if="item.deviceTypes && item.deviceTypes.length == 0" class="d-flex justify-center">
                            - </span>
                        <div style="display:flex; flex-wrap: wrap; gap:5px;"
                            v-if="item.deviceTypes && item.deviceTypes.length <= 6">
                            <v-chip x-small v-for="elem in item.deviceTypes" :key="elem.ID">
                                {{ DeviceTypeById(elem)?.name }}</v-chip>
                        </div>
                        <div style="display:flex; flex-wrap: wrap; gap:5px;" v-else>
                            <v-chip x-small v-for="n in 6" :key="n">{{ DeviceTypeById(item.deviceTypes[n -
                                1])?.name
                                }}</v-chip>
                            и ещё {{ item.deviceTypes.length - 6 }}
                        </div>
                    </template>
                    <template v-slot:item.index="{ item, index }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.lastLoginUTC="{ item }">
                        {{ GetLastLoginUTC(item) }}
                    </template>
                    <template v-slot:item.devicesCount="{ item }">
                        {{ AllDevices?.filter(a => a.userID == item.id)?.length ?? 0 }} шт.
                    </template>
              



                    <template v-slot:item.actions="{ item }">
                       <div class="d-flex align-center jsutify-center" style="gap:5px">
                        <v-btn x-small @click="LoginLikeUser(item.id)" icon color="blue lighten-2"
                            title="Авторизоваться под">
                            <v-icon small>fa fa-solid fa-right-to-bracket fa-icon-small</v-icon>
                        </v-btn>
                        <v-btn x-small @click="ShowEditUserModal(item)" icon color="primary lighten-1"
                            title="Изменить пользователя">
                            <v-icon small>fa fa-pencil fa-icon-small</v-icon>
                        </v-btn>
                        <v-btn x-small class="btn delete" @click="ShowDeleteUserModal(item)" color="red darken-3" icon
                            title="Удалить пользователя">
                            <v-icon small> fa fa-trash fa-icon-small</v-icon>
                        </v-btn>
                    </div>
                    </template>
                   
                </v-data-table>
                <v-modal ref="CreateUserModal" max-width="500" :persistent="persistent">
                    <template v-slot:MainModal>
                        <v-card :loading="loading">
                            <v-card-title class="text-h6 grey lighten-3">
                                {{ edit ? 'Свойства пользователя' : 'Новый пользователь' }}
                            </v-card-title>
                            <v-card-text class="pt-1 pb-1">
                                <v-form v-model="valid" ref="form_users" id="form_users">
                                    <v-text-field v-model="edited_item.name" :rules="name_rules" label="Имя"
                                        placeholder="Иванов Иван Иванович" :disabled="loading" required></v-text-field>
                                    <!--<v-text-field v-model="edited_item.email" :rules="email_rules" label="E-mail" placeholder="some@yandex.ru" :disabled="loading" required></v-text-field>-->
                                    <v-text-field v-model="edited_item.login" :rules="login_rules" label="Логин"
                                        placeholder="some_login" :disabled="loading" required></v-text-field>
                                    <v-text-field v-model="edited_item.password" :rules="password_rules" label="Паполь"
                                        placeholder="some_password" :disabled="loading" required></v-text-field>
                                    <v-autocomplete no-data-text="Нет элементов" item-text="name" item-value="id"
                                        :rules="type_rules" :items="AllDeviceTypes" clearable deletable-chips multiple
                                        small-chips label="Доступные типы устройств"
                                        placeholder="Выберите типы устройств" v-model="edited_item.deviceTypes"
                                        :disabled="loading" required>
                                        <template v-slot:prepend-item>
                                            <v-list-item ripple @click="ToggleTypes()">
                                                <v-list-item-action>
                                                    <v-icon
                                                        :color="edited_item.deviceTypes.length > 0 ? 'indigo darken-4' : ''">
                                                        {{ iconTypes }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Выбрать все
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                        </template>
                                    </v-autocomplete>
                                    <!--append-icon="fa fa-solid fa-caret-down fa-2xs" clear-icon="fa fa-solid fa-xmark fa-2xs"-->
                                </v-form>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" form="form_users" depressed @click="CreateOtEditUser"
                                    :disabled="loading || !valid">
                                    Сохранить
                                </v-btn>
                                <v-btn color="error" depressed @click="$refs.CreateUserModal.Hide()"
                                    :disabled="loading">
                                    Закрыть
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-modal>
                <v-modal ref="DeleteUserModal" max-width="350" :persistent="persistent">
                    <template v-slot:MainModal>
                        <v-card :loading="loading">
                            <v-card-title class="text-h6 grey lighten-3">
                                Удаление пользователя
                            </v-card-title>
                            <v-card-text class="py-1">
                                Вы действительно хотите удалить пользователя "{{ edited_item.name }}"?
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn color="primary" depressed @click="DeleteUser" :disabled="loading">
                                    Да
                                </v-btn>
                                <v-btn color="error" depressed @click="$refs.DeleteUserModal.Hide()"
                                    :disabled="loading">
                                    Нет
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-modal>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import {
    cls_center,
    cls_left
} from "@/helpers/const";
import {
    eventBus
} from "@/main.js";
import {
    mapGetters
} from "vuex";
import moment from 'moment';

export default {
    data() {
        return {
            valid: true,
            filtered_items: [],
            filter: "",
            /*Столбцы таблицы*/
            footer_props: {
                'items-per-page-all-text': 'Все',
                'items-per-page-text': 'Строк на странице:',
                'pageText': '{0}-{1} из {2}',
                'items-per-page-options': [
                    50,
                    100,
                    -1
                ],
            },
            header_props: {
                'sort-by-text': 'Сортировать по'
            },
            fields: [{
                text: '',
                sortable: false,
                align: "center",
                value: "index",
                cellClass: ["text-center", "align-middle"],
                class: " grey lighten-3",
                width: 75,
            },
            {
                text: "Имя",
                sortable: true,
                align: "center",
                value: "name",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3"
            },
            {
                text: "Дата/время",
                align: "center",
                sortable: true,
                value: "lastLoginUTC",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3"
            },
            {
                text: "Логин",
                align: "center",
                sortable: true,
                value: "login",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3"
            },
            /*  {
                        text: "Пароль",
                        align: "center",
                        sortable: true,
                        value: "password",
                        cellClass: ["text-start", "align-middle"],
                        class: " grey lighten-3"
                    },*/
            {
                text: "Типы устройств",
                align: "center",
                sortable: true,
                value: "deviceTypes",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3",
                width: "34%"
            },
            {
                text: "Устройства",
                align: "center",
                sortable: false,
                value: "devicesCount",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3",

            },
           
            {
                text: '',
                value: 'actions',
                sortable: false,
                cellClass: ["text-start", "align-middle"],
                width: 10,
                class: " grey lighten-3"
            },
            
            ],
            edit: false,
            /*Связь с модальным окном*/
            dialog: false,
            /*поля ввода*/
            default_item: {
                name: "",
                //email: "",
                login: "",
                password: "",
                deviceTypes: [],
            },
            edited_item: {
                id: 0,
                name: "",
                // email: "",
                login: "",
                password: "",
                deviceTypes: [],
            },
            /*правила валидации*/
            name_rules: [
                v => !!v || 'Поле должно быть заполнено',
            ],
            /*
                        email_rules: [
                            v => !!v || 'Поле должно быть заполнено',
                            v => /.+@.+\..+/.test(v) || 'E-mail должен быть валидным',
                        ],*/
            login_rules: [
                v => !!v || 'Поле должно быть заполнено',
                v => /\w/.test(v) || 'Только латинские буквы, "_", цифры: 0 - 9',
                v => v && v.length > 2 || 'Минимум 3 символа',
                v => v && v.length < 11 || 'Максимум 10 символов'
            ],
            password_rules: [
                v => !!v || 'Поле должно быть заполнено',
                v => /\w/.test(v) || 'Только латинские буквы, "_", цифры: 0 - 9',
                v => v && v.length > 2 || 'Минимум 3 символа',
                v => v && v.length < 21 || 'Максимум 20 символов',
            ],
            type_rules: [
                v => !!v || 'Должнен быть выбран хотя бы 1 тип',
            ],
            /*Отчёт о выполнении действия*/
            persistent: false,
            perPage: 100,
            currentPage: 1,
            action: {
                type: null,
                method: null
            },
        };
    },
    computed: {
        AllTypesSelected() {
            return this.edited_item.deviceTypes.length === this.AllDeviceTypes.length
        },
        SomeTypeSelected() {
            return this.edited_item.deviceTypes.length > 0 && !this.AllTypesSelected
        },
        iconTypes() {
            if (this.AllTypesSelected) return 'mdi-close-box'
            if (this.SomeTypeSelected) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        loading() {
            switch (this.action.method) {
                case "get":
                    if (this.action.type === "users") return this.UsersGetAreLoading;
                    break;
                case "post":
                    if (this.action.type === "users") return this.UsersPostAreLoading;
                    break;
                case "put":
                    if (this.action.type === "users") return this.UsersPutAreLoading;
                    break;
                case "delete":
                    if (this.action.type === "users") return this.UsersDeleteAreLoading;
                    break;
                default:
                    return false;
            }
        },
        isBusy() {

            if ((this.UsersGetHaveError || this.UsersGetAreLoaded) && (this.DeviceTypesGetAreLoaded || this.DeviceTypesGetHaveError) && (this.DevicesGetAreLoaded || this.DevicesGetHaveError)) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters([
            "AllUsers",
            "AllDevices",
            "UserById",
            "UsersOnceLoaded",
            "UsersGetAreLoading",
            "UsersGetHaveError",
            "UsersGetAreLoaded",
            "UsersPostAreLoading",
            "UsersPostHaveError",
            "UsersPostAreLoaded",
            "UsersPutAreLoading",
            "UsersPutHaveError",
            "UsersPutAreLoaded",
            "UsersDeleteAreLoading",
            "UsersDeleteHaveError",
            "UsersDeleteAreLoaded",
            "AllDeviceTypes",
            "DeviceTypeById",
            "DeviceTypesOnceLoaded",
            "DeviceTypesGetAreLoading",
            "DeviceTypesGetHaveError",
            "DeviceTypesGetAreLoaded",
            "DevicesOnceLoaded",
            "DevicesGetAreLoading",
            "DevicesGetAreLoaded",
            "DevicesGetHaveError"
        ]),
    },
    async created() {
        if (!this.UsersOnceLoaded) {
            await this.$store.dispatch(USERS_GET_REQUEST);
        }
        if (!this.DeviceTypesOnceLoaded) {
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
        }
        if (!this.DevicesOnceLoaded) {
            await this.$store.dispatch(DEVICES_GET_REQUEST, "admin");
        }

    },
    watch: {
        perPage(new_val) {
            localStorage.setItem('AdminUsersTablePerPage', new_val)
        },
    },
    mounted() {
        var perPage = localStorage.getItem('AdminUsersTablePerPage');
        if (perPage != null) {
            this.perPage = parseInt(perPage)
        }
        else {
            this.perPage = 100;
        }
    },
    methods: {
        GetLastLoginUTC(item) {
            return moment(item.lastLoginUTC).format('DD.MM.YYYY HH:mm')
        },
        ToggleTypes() {
            this.$nextTick(() => {
                if (this.AllTypesSelected) {
                    this.edited_item.deviceTypes = []
                } else {
                    this.edited_item.deviceTypes = this.AllDeviceTypes.map(a => a.id).slice()
                }
            })
        },
        async Refresh() {
            await this.$store.dispatch(USERS_GET_REQUEST);
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
        },
        async LoginLikeUser(user_id) {
            await this.$store.dispatch(AUTH_GET_MAGIC_TOKEN_REQUEST, user_id).then(async (response) => {
                await addMagicToken(response.data.jwt, response.data.userID, response.data.tokenID, response.data.role, response.data.userFullName).then(() => {
                    const routeData = this.$router.resolve({
                        name: 'UserDevices'
                    });
                    window.open(routeData.href, '_blank');
                })
            })
        },
        ShowCreateUserModal() {
            this.edit = false;
            this.edited_item = Object.assign({}, this.default_item)
            this.$refs.CreateUserModal.Show();
            this.$nextTick(() => this.$refs.form_users.resetValidation())
        },
        ShowEditUserModal(item) {
            this.edit = true;
            this.edited_item = Object.assign({}, item)
            this.$refs.CreateUserModal.Show();
            this.$nextTick(() => this.$refs.form_users.resetValidation())
        },
        async CreateOtEditUser() {
            this.persistent = true;
            if (this.edit) {
                this.action = {
                    type: "users",
                    method: "put"
                };
                //  let user = JSON.parse(JSON.stringify(this.UserById(this.edited_item.id)));
                await this.$store.dispatch(USERS_PUT_REQUEST, this.edited_item).then(() => {
                    this.$refs.CreateUserModal.Success();
                }).catch(err => {
                    this.$refs.CreateUserModal.Error(err);
                })
            } else {
                this.action = {
                    type: "users",
                    method: "post"
                };
                await this.$store.dispatch(USERS_POST_REQUEST, this.edited_item).then(() => {
                    this.$refs.CreateUserModal.Success();
                }).catch(err => {
                    this.$refs.CreateUserModal.Error(err);
                })
            }
        },
        ShowDeleteUserModal(item) {
            this.edited_item = Object.assign({}, item)
            this.$refs.DeleteUserModal.Show()
        },
        async DeleteUser() {
            this.persistent = true;
            this.action = {
                type: "users",
                method: "delete"
            }
            await this.$store.dispatch(USERS_DELETE_REQUEST, this.edited_item.id).then(() => {
                this.$refs.DeleteUserModal.Success()
            }).catch(err => {
                this.$refs.DeleteUserModal.Error(err)
            })
        },
    }
}
</script>
<style></style>