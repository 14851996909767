<template>
    <v-app style="background-color: var(--v-main-color)">
        <router-view />
    </v-app>
</template>
<script>
import {
    eventBus
} from '@/main.js'
import {
    mapGetters
} from "vuex";
export default {
    name: 'App',
    computed: {
        ...mapGetters([
            'GetHttpErrorStatus',
            'GetHttpErrorStatusText',
        ])
    },
}
</script>
<style></style>
