<template>
    <div>
        <!-- Page Headings Start -->
        <!--<page-heading heading=" "></page-heading>-->
        <!-- Page Headings End -->
        <v-row no-gutters>
            <v-card outlined>
                <!--Default Data Table Start-->
                <v-card-title>
                    <v-row no-gutters align="center" justify="space-between">
                        <v-col style="gap:5px"
                            class="d-flex flex-wrap align-center justify-space-between justify-md-start">
                            <div class="d-flex">
                                <v-icon small class="nav-icon mx-2"> fa fa-light fa-display</v-icon>
                                <h3 class="title">
                                    Прошивки устройств
                                </h3>
                            </div>
                            <v-item-group>
                                <v-btn small icon @click="Refresh()"><v-icon small style="cursor:pointer"
                                        title="Обновить" class="mx-2">fa
                                        fa-refresh</v-icon></v-btn>
                                <v-btn outlined color="primary" @click="ShowFirmwareUpdateModal()">
                                    Обновление
                                </v-btn>

                            </v-item-group>

                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="filter" append-icon="mdi-magnify" label="Поиск" single-line
                                hide-details class="ma-0 pa-0"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-data-table :search="filter" :fixed-header="!$vuetify.breakpoint.mobile"
                        :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint" loading-text="Загрузка..."
                        :header-props="header_props" :footer-props="footer_props" :loading="isBusy"
                        no-results-text="Не найдено" :headers="fields" :items="DeviceTypes" locale="ru-Ru"
                        :items-per-page.sync="perPage" class="elevation-0">
                        <template v-slot:item.index="{ item, index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:no-data>
                            <span v-if="FirmwaresGetHaveError || DeviceTypesGetHaveError || DevicesGetHaveError"
                                class="red--text">Ошибка
                                загрузки данных</span>
                            <span
                                v-if="FirmwaresGetAreLoaded && DeviceTypesGetAreLoaded && AllDeviceTypes.length == 0">Нет
                                типов устройств</span>
                        </template>
                        <template v-slot:item.countDevices="{ item }">
                            <div class="d-flex gap-3 align-center justify-space-between">
                                <div>{{ item.countDevices }} шт.</div>
                                <v-btn v-if="item.countDevices > 0" x-small color="primary"
                                    @click="ShowDeviceInfoModal(item)" icon
                                    title="Окно информации о прошивках на устройствах">
                                    <v-icon small>fa fa-eye fa-icon-small</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:item.countFirmwares="{ item }">
                            <div class="d-flex gap-3 align-center justify-space-between">
                                <div>{{ item.countFirmwares }} шт.</div>
                                <v-btn x-small color="primary" @click="ShowFirmwaresModal(item)" icon
                                    title="Окно прошивок">
                                    <v-icon small>fa fa-eye fa-icon-small</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <div class="d-flex gap-3 align-center justify-space-between">
                                <div>{{ item.name }}</div>
                                <div class="actions" style="cursor:pointer">
                                    <v-btn x-small color="primary" icon @click="ShowEditDeviceTypeModal(item)"
                                        title="Изменение свойств типа">
                                        <v-icon small>fa fa-pencil fa-icon-small</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                    <v-modal ref="FirmwaresModal" scrollable fullscreen no-click-animation
                        transition="dialog-bottom-transition" :persistent="persistent">
                        <template v-slot:MainModal>
                            <v-card tile>
                                <v-toolbar flat style="background:#282652; color:white; max-height:64px"
                                    class="toolbar-firmware">
                                    <div class="toolbar-container">
                                        <v-toolbar-title>
                                            <div class="text-h8 color-white">Прошивки устройств типа
                                                {{ edited_item.name}} ({{edited_item.hardwareVersion}})</div>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn icon dark @click="$refs.FirmwaresModal.Hide()">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-toolbar>
                                <v-card-text class="pt-1 pb-1">
                                    <v-container>
                                        <v-row class="" no-gutters>
                                            <v-col cols=12 md=6 order="2" order-md="1">
                                                <v-file-input dense label="Файл прошивки" filled prepend-icon=""
                                                    accept=".ENC, .enc" append-icon="$file"
                                                    placeholder="Выберите файл прошивки" :disabled="report || loading"
                                                    v-model="file" show-size chips truncate-length="20"
                                                    counter></v-file-input>
                                                <v-btn :loading="loading && action.subtype == 'upload'"
                                                    :disabled="file == null || (report && action.subtype == 'upload') || (loading && action.subtype == 'upload')"
                                                    @click="UploadFirmware"><span
                                                        v-if="!report || action.subtype != 'upload'">Сохранить </span>
                                                    <span v-if="action.subtype == 'upload' && report && !error">
                                                        <v-icon color="green darken-2"> fa fa-solid
                                                            fa-check</v-icon>Добавлено
                                                    </span>
                                                    <span v-if="action.subtype == 'upload' && report && error">
                                                        <v-icon color="red darken-3">fa fa-solid fa-exclamation</v-icon>
                                                        Ошибка
                                                    </span>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols=12 md=6 order="1" order-md="2" style="color:red"><!---->
                                                <v-alert v-show="report" transition="fade-transition" class="mx-md-10"
                                                    :color="error ? 'red' : 'green'" dense
                                                    :type="error ? 'error' : 'success'">
                                                    {{ report_text }}</v-alert>

                                            </v-col>
                                        </v-row>
                                        <v-divider class="my-2"></v-divider>
                                        <v-row v-if="FirmwaresByDeviceTypeId(edited_item.id).length == 0"
                                            class="align-center justify-center"
                                            style="height: calc(100vh - 500px); min-height:200px">Нет прошивок для
                                            данного типа</v-row>
                                        <v-row>
                                            <v-col v-for=" (elem, index) in FirmwaresByDeviceTypeId(edited_item.id) "
                                                :key="elem.id" cols="12" sm="6" md="4" lg="3">
                                                <v-card class="pt-1">
                                                    <v-card-title class="justify-space-between">
                                                        {{ elem.filename }}
                                                        <v-btn x-small color="red darken-3" icon
                                                            @click="ShowDeleteFirmwareModal(elem.id)"
                                                            title="Удалить прошивку">
                                                            <v-icon small>fa fa-trash fa-icon-small</v-icon>
                                                        </v-btn>

                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div>Программная версия: {{ elem.softwareVersion }}</div>
                                                        <div>Дата: {{ GetDateText(elem.uploadDate) }}</div>
                                                        <div>Состояние: <span class="red--text"
                                                                v-if="elem.disabled">недоступно</span>
                                                            <span style="color:green" v-else>доступно</span>
                                                        </div>
                                                        <v-btn class="mt-1" elevation="2"
                                                            :loading="loading && action.subtype == 'state'"
                                                            :disabled="(report && action.subtype == 'state') || (loading && action.subtype == 'state')"
                                                            small @click="ChangeState(elem.id, elem.disabled)"><span
                                                                v-if="index != action.index">{{ elem.disabled ?
                                                                    'Разблокировать'
                                                                    : 'Заблокировать' }}</span>
                                                            <span
                                                                v-if="action.subtype == 'state' && report && !error && index == action.index">
                                                                <v-icon color="green darken-2"> fa fa-solid
                                                                    fa-check</v-icon>
                                                            </span>
                                                            <span
                                                                v-if="action.subtype == 'state' && report && error && index == action.index">
                                                                <v-icon color="red darken-3">fa fa-solid
                                                                    fa-exclamation</v-icon> Ошибка
                                                            </span>
                                                        </v-btn>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-modal>

                    <v-modal ref="CreateEditDeviceTypeModal" max-width="600" :persistent="persistent">
                        <template v-slot:MainModal>
                            <v-card :loading="loading">
                                <v-card-title v-if="!edit" class="text-h6 grey lighten-3">
                                    Новый тип устройства
                                </v-card-title>
                                <v-card-title v-else class="text-h6 grey lighten-3">
                                    {{ edit_title }}
                                </v-card-title>
                                <v-card-text class="pt-1 pb-1">
                                    <v-form v-model.sync="valid" ref="form_deviceType" id="form_deviceType"
                                        lazy-validation>
                                        <v-text-field v-model="edited_item.name" :rules="name_rules"
                                            label="Наименование" placeholder="GT3-BS" :disabled="loading"
                                            required></v-text-field>
                                        <v-textarea v-model="edited_item.description" label="Описание"
                                            placeholder="Введите что-нибудь" :disabled="loading" rows="1"></v-textarea>
                                        <v-text-field v-model.number="edited_item.hardwareVersion" type="number"
                                            :rules="name_rules" label="Номер аппаратной версии" placeholder="32944"
                                            :disabled="loading || edit" required></v-text-field>
                                        <v-textarea v-model="edited_item.parametersStructure"
                                            label="Структура параметров" placeholder="Введите что-нибудь"
                                            :disabled="loading" rows="8"></v-textarea>
                                    </v-form>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn color="primary" form="form_deviceType" depressed
                                        @click="CreateOrEditDeviceType" :disabled="loading || !valid">
                                        Сохранить
                                    </v-btn>
                                    <v-btn color="error" depressed @click="$refs.CreateEditDeviceTypeModal.Hide()"
                                        :disabled="loading">
                                        Закрыть
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-modal>
                    <v-modal ref="FirmwareUpdateModal" :max-width="question ? '350' : '500'" :persistent="persistent">
                        <template v-slot:MainModal>
                            <v-card :loading="loading">
                                <v-card-title class="text-h6 grey lighten-3">
                                    Массовое обновление
                                </v-card-title>

                                <v-card-text class="pt-1 pb-1">

                                    <v-form v-if="!question" v-model="valid" ref="form_firmware_update"
                                        id="form_firmware_update">
                                        <v-autocomplete no-data-text="Нет типов устройств"
                                            :item-text='(item) => `${item.hardwareVersion} (${item.name})`'
                                            @change="DeviceTypesAutocomplete" item-value="id" :items="AllDeviceTypes"
                                            :rules="name_rules" clearable label="Версия АО"
                                            placeholder="Выберите аппаратную версию" v-model="edited_item.id"
                                            :disabled="loading" required>
                                        </v-autocomplete>
                                        <v-autocomplete no-data-text="Нет устройств" :item-text='(item) => `item`'
                                            item-value="item" :items="AllAvailableSoftwareSelect"
                                            @change="ChangeAvailableSoftwareAutocomplete"
                                            :disabled="edited_item.id == null || loading"
                                            label="Версия устройств для обновления" persistent-hint clearable
                                            :hint="edited_item.sw_start != null ? GetAvailableSoftwareHintText(edited_item.sw_start) : ''"
                                            placeholder="Выберите программную версию начала обновления"
                                            v-model="edited_item.sw_start" required>

                                        </v-autocomplete>
                                        <v-autocomplete no-data-text="Нет доступных прошивок"
                                            :item-text='(item) => `${item.softwareVersion}`'
                                            item-value="softwareVersion" :items="AllAvailableFirmwaresSelect" clearable
                                            :rules="name_rules" @change="ChangeAvailableFirmwaresAutocomplete"
                                            :disabled="edited_item.id == null || loading" persistent-hint
                                            :hint="edited_item.sw_start == null && edited_item.sw_end != null ? GetAvailableSoftwareHintText(edited_item.sw_start) : ''"
                                            label="Версия обновления"
                                            placeholder="Выберите программную версию для обновления"
                                            v-model="edited_item.sw_end" required>

                                        </v-autocomplete>
                                        <!--append-icon="fa fa-solid fa-caret-down fa-2xs" clear-icon="fa fa-solid fa-xmark fa-2xs"-->
                                    </v-form>
                                    <div v-else>

                                        Вы действительно хотите обновить {{ QuantityUpdate }} устройств типа {{
                                            DeviceTypeById(edited_item.id).name }} {{ UpdateText }}?

                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>

                                    <div v-if="!question" class="d-flex" style="gap:5px">
                                        <v-btn color="primary" form="form_firmware_update" depressed
                                            @click="FirmwareUpdate" :disabled="loading || !valid">
                                            Обновить
                                        </v-btn>
                                        <v-btn color="error" depressed @click="$refs.FirmwareUpdateModal.Hide()"
                                            :disabled="loading">
                                            Закрыть
                                        </v-btn>
                                    </div>
                                    <div v-else class="d-flex" style="gap:5px">

                                        <v-btn color="primary" depressed @click="FirmwareUpdate" :disabled="loading">
                                            Да
                                        </v-btn>
                                        <v-btn color="error" depressed @click="question = !question"
                                            :disabled="loading">
                                            Нет
                                        </v-btn>
                                    </div>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-modal>
                    <v-modal ref="DeviceInfoModal" max-width="400" scrollable :persistent="false">
                        <template v-slot:MainModal>
                            <v-card :loading="loading">
                                <v-card-title class="text-h6 grey lighten-3 d-flex flex-column align-start">
                                    <div>Версия ПО/Кол-во устройств</div>
                                    <div v-if="edited_item.name != null">Тип {{edited_item.name}} ({{ edited_item.hardwareVersion }})</div>
                                </v-card-title>
                               
                                <v-card-text class="py-1">
                                    <v-timeline align-top dense>
                                        <v-timeline-item small v-for="elem in devicesGroupBySoftware">
                                            <v-row class="pt-1">
                                                <v-col cols="auto">

                                                    <strong>{{ elem[0].softwareVersion }}</strong>
                                                    <div class="text-caption">
                                                        {{ elem.length }} шт.
                                                    </div>
                                                </v-col>

                                            </v-row>
                                        </v-timeline-item></v-timeline>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn color="error" depressed @click="$refs.DeviceInfoModal.Hide()"
                                        :disabled="loading">
                                        Закрыть
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-modal>
                    <v-modal ref="DeleteFirmwareModal" max-width="350" :persistent="false">
                        <template v-slot:MainModal>
                            <v-card :loading="loading">
                                <v-card-title class="text-h6 grey lighten-3">
                                    Удаление прошивки
                                </v-card-title>
                                <v-card-text class="py-1">
                                    Вы действительно хотите обновить прошивку <b>
                                        {{
                                            FirmwaresByDeviceTypeId(edited_item.id)?.find(a => a.id ==
                                                firmware_id)?.filename
                                        }}</b>?
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn color="primary" depressed @click="DeleteFirmware" :disabled="loading">
                                        Да
                                    </v-btn>
                                    <v-btn color="error" depressed @click="$refs.DeleteFirmwareModal.Hide()"
                                        :disabled="loading">
                                        Нет
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-modal>
                </v-card-text>
            </v-card>
        </v-row>
    </div>
</template>
<script>
import { FIRMWARES_DELETE_REQUEST } from "@/helpers/actions";
import {
    cls_center,
    cls_left
} from "@/helpers/const";
import {
    eventBus
} from "@/main.js";
import { toHandlers } from "@vue/runtime-core";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            file: null,
            valid: true,
            edit_type: null,
            edit_title: null,
            items_test: [{
                "id": 0,
                "name": "Имя типа",
                "name": "Устройство 1",
                "countDevices": "Тип 1",
                "firmwares": "прошивки",
                "description": "описание",
            }
            ],
            filtered_items: [],
            filter: "",
            /*Столбцы таблицы*/
            footer_props: {
                'items-per-page-all-text': 'Все',
                'items-per-page-text': 'Строк на странице:',
                'pageText': '{0}-{1} из {2}',
                'items-per-page-options': [
                    50,
                    100,
                    1000,
                    -1
                ],
            },
            header_props: {
                'sort-by-text': 'Сортировать по'
            },
            fields: [{
                text: '',
                sortable: false,
                align: "center",
                value: "index",
                cellClass: ["text-center", "align-middle"],
                class: " grey lighten-3",
                width: 75,
            },
            {
                text: "Имя типа",
                align: "center",
                sortable: true,
                value: "name",
                cellClass: ["text-start", "align-middle"],
                class: " grey lighten-3",
            },
            {
                text: "Описание типа",
                align: "center",
                sortable: true,
                value: "description",
                cellClass: ["text-start", "align-middle"],
                class: "grey lighten-3",
            },
            {
                text: "Версия АО",
                align: "center",
                sortable: true,
                value: "hardwareVersion",
                cellClass: ["text-start", "align-middle"],
                class: "grey lighten-3",
            },
            {
                text: "Устройства",
                align: "center",
                sortable: true,
                value: "countDevices",
                cellClass: ["text-start", "align-middle"],
                class: "grey lighten-3",
            },
            {
                text: "Прошивки",
                align: "center",
                sortable: false,
                value: "countFirmwares",
                cellClass: ["text-start", "align-middle"],
                class: "grey lighten-3",
            },

            ],
            edit: false,
            //Воппрос в модальном окне
            question: false,
            /*Связь с модальным окном*/
            modal_create_edit: false,
            modal_delete: false,
            /*поля ввода*/
            default_item: {
                "id": null,
                "name": null,
                "countDevices": 0,
                "firmwares": null,
                "description": null,
                "sw_start": null,
                "sw_end": null,
                hardwareVersion: null,

            },
            edited_item: {
                "id": null,
                "name": null,

                "countDevices": 0,
                "firmwares": null,
                "description": null,
                hardwareVersion: null,

            },
            firmware_id: null,



            /*правила валидации*/
            name_rules: [
                v => !!v || 'Поле должно быть заполнено',
            ],



            /*Отчёт о выполнении действия*/
            persistent: false,
            report: false,
            error: false,
            report_text: "Ошибка",
            perPage: 100,
            currentPage: 1,
            action: {
                type: null,
                method: null,
                subtype: null,
                index: null,
            },
            default_action: {
                type: null,
                method: null,
                subtype: null,
                index: null,
            },
        };
    },
    computed: {
        devicesGroupBySoftware() {
            return this.edited_item.id != null ?
                this.groupBy(this.DevicesByType(this.edited_item.id), 'softwareVersion') : [];
        },
        DeviceTypes() {
            return this.AllDeviceTypes.map(a => {
                let itemsArray = Object.assign({}, a);
                itemsArray.countDevices = this.DevicesByType(a.id)?.length ?? 0;
                itemsArray.countFirmwares = this.FirmwaresByDeviceTypeId(a.id)?.length ?? 0
                return itemsArray;
            })
        },
        UpdateText() {
            if (this.edited_item.sw_start != null) return `c версии ${this.edited_item.sw_start} на версию ${this.edited_item.sw_end}`
            else return `на версию ${this.edited_item.sw_end}`
        },
        QuantityUpdate() {
            return this.edited_item.sw_start == null ? this.AllDevices.filter(a => a.deviceTypeID == this.edited_item.id)?.length ?? 0 : this.AllDevices.filter(a => a.softwareVersion == this.edited_item.sw_start && a.deviceTypeID == this.edited_item.id)?.length ?? 0
        },
        AllAvailableFirmwaresSelect() {
            if (this.edited_item.id == null) return [];
            return this.AllFirmwares.filter(a => a.deviceTypeID == this.edited_item.id).sort((a, b) => { return a.softwareVersion - b.softwareVersion > 0 ? 1 : -1 })

        },
        AllAvailableSoftwareSelect() {
            if (this.edited_item.id == null) return []
            var softwareVersions = this.DevicesByType(this.edited_item.id).map(a => a.softwareVersion);
            return softwareVersions.filter((item, index) => { return softwareVersions.indexOf(item) === index }).sort((a, b) => { return a - b > 0 ? 1 : -1 });
        },

        loading() {
            switch (this.action.method) {
                case "get":
                    if (this.action.type === "firmwares") return this.FirmwaresGetAreLoading;
                    break;
                case "put":
                    if (this.action.type === "firmwares") return this.FirmwaresPutAreLoading;
                    if (this.action.type === "deviceType") return this.DeviceTypesPutAreLoading;
                default:
                    return false;
            }
        },
        isBusy() {
            if ((this.DeviceTypesGetHaveError || this.DeviceTypesGetAreLoaded) && (this.FirmwaresGetHaveError || this.FirmwaresGetAreLoaded) && (this.DevicesGetHaveError || this.DevicesGetAreLoaded)) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters([
            "AllUsers",
            "UserById",
            "UsersOnceLoaded",
            "UsersGetAreLoading",
            "UsersGetHaveError",
            "UsersGetAreLoaded",
            "UsersPostAreLoading",
            "UsersPostHaveError",
            "UsersPostAreLoaded",
            "UsersPutAreLoading",
            "UsersPutHaveError",
            "UsersPutAreLoaded",
            "UsersDeleteAreLoading",
            "UsersDeleteHaveError",
            "UsersDeleteAreLoaded",
            "UserByIdds",
            "AllDevices",
            "DeviceById",
            "DevicesByType",
            "DevicesOnceLoaded",
            "DevicesGetAreLoading",
            "DevicesGetHaveError",
            "DevicesGetAreLoaded",
            "DevicesPostAreLoading",
            "DevicesPostHaveError",
            "DevicesPostAreLoaded",
            "DevicesPutAreLoading",
            "DevicesPutHaveError",
            "DevicesPutAreLoaded",
            "DevicesDeleteAreLoading",
            "DevicesDeleteHaveError",
            "DevicesDeleteAreLoaded",
            "AllDeviceTypes",
            "DeviceTypeById",
            "DeviceTypesOnceLoaded",
            "DeviceTypesGetAreLoading",
            "DeviceTypesPutAreLoading",
            "DeviceTypesGetHaveError",
            "DeviceTypesGetAreLoaded",
            "FirmwaresByDeviceTypeId",
            'FirmwaresPutAreLoading',
            'AllFirmwares',
            'FirmwaresGetAreLoading',
            'FirmwaresGetHaveError',
            'FirmwaresGetAreLoaded',
            'FirmwaresGetAreLoading',
            'FirmwaresGetHaveError',
            'FirmwaresGetAreLoaded',
        ]),
    },
    async created() {
        if (!this.DevicesOnceLoaded) {
            await this.$store.dispatch(DEVICES_GET_REQUEST, "admin");
        }
        if (!this.DeviceTypesOnceLoaded) {
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
        }
        if (!this.FirmwaresOnceLoaded) {
            await this.$store.dispatch(FIRMWARES_GET_REQUEST);
        }
    },
    watch: {
        perPage(new_val) {
            localStorage.setItem('AdminFirmwaresTablePerPage', new_val)
        },
    },
    mounted() {
        var perPage = localStorage.getItem('AdminFirmwaresTablePerPage');
        if (perPage != null) {
            this.perPage = parseInt(perPage)
        }
        else {
            this.perPage = 100;
        }
    },

    methods: {
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        ShowDeviceInfoModal(item) {
            this.edited_item = Object.assign({}, item)
            this.$refs.DeviceInfoModal.Show();
        },
        GetDateText(date) {
            return moment(date).format('DD.MM.YY/HH:mm:ss')
        },
        ShowDeleteFirmwareModal(id) {
            this.firmware_id = id;
            this.$refs.DeleteFirmwareModal.Show();
        },
        async DeleteFirmware() {
            this.$store.dispatch(FIRMWARES_DELETE_REQUEST, this.firmware_id).then(() => {
                this.$refs.DeleteFirmwareModal.Success();
            }
            ).catch(err => {
                this.$refs.DeleteFirmwareModal.Error(err)
            }
            )
        },
        GetAvailableSoftwareHintText(softwareVersion) {
            return softwareVersion == null ? `Количество устройств, которое будет обновлено` : `Количество устройств с данной версией = ${this.QuantityUpdate} шт.`
        },
        DeviceTypesAutocomplete(event) {
            this.edited_item.sw_start = null;
            this.edited_item.sw_end = null;
        },
        ChangeAvailableSoftwareAutocomplete(event) {

        },
        ChangeAvailableFirmwaresAutocomplete(event) { },



        ShowFirmwareUpdateModal() {
            this.persistent = false;
            this.question = false;

            this.edited_item = Object.assign({}, this.default_item);
            this.$refs.FirmwareUpdateModal.Show();
            this.$nextTick(() => this.$refs.form_firmware_update.resetValidation())

        },
        async FirmwareUpdate() {
            if (this.question) {
                this.action = {
                    type: "firmwares",
                    method: "put",
                };
                this.$store.dispatch(FIRMWARES_UPDATE_MULTIPLY_PUT_REQUEST, this.edited_item)
                    .then(() => {

                        this.$refs.FirmwareUpdateModal.Success()


                    }).catch((err) => {
                        this.$refs.FirmwareUpdateModal.Error(err)
                    })

            }
            else {
                if (this.$refs.form_firmware_update.validate()) {

                    this.question = true;
                }

            }
        },
        СancelFirmwareUpdate(device_id) {
            this.$store.dispatch(CANCEL_FIRMWARE_UPDATE_PUT_REQUEST, device_id).then().catch(err => {
                err = "Ошибка отмены команды обновления ПО"
                this.$refs.EmptyModal.Error(err);
            })
        },
        async Refresh() {
            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "admin");
            await this.$store.dispatch(FIRMWARES_GET_REQUEST);
        },
        ShowEditDeviceTypeModal(item) {
            this.persistent = false;

            this.edit = true;
            this.edit_title = "Свойства типа";;
            this.edited_item = Object.assign({}, item)
            if (this.edited_item.parametersStructure != null) {
                this.edited_item.parametersStructure = JSON.stringify(JSON.parse(this.edited_item.parametersStructure), null, 4)
            }
            this.$refs.CreateEditDeviceTypeModal.Show();
            this.$nextTick(() => this.$refs.form_deviceType.resetValidation())


        },
        async CreateOrEditDeviceType() {
            if (this.$refs.form_deviceType.validate()) {

                this.persistent = true;

                if (this.edit) {
                    this.action = {
                        type: "deviceType",
                        method: "put",
                    };

                    await this.$store.dispatch(DEVICE_TYPES_PUT_REQUEST, this.edited_item
                    ).then(() => {
                        this.$refs.CreateEditDeviceTypeModal.Success();
                    }).catch(err => {
                        this.$refs.CreateEditDeviceTypeModal.Error({ err: err, action: 'no_hide' });
                    })
                }
                else {
                    ///Не реализовано
                }
            }

        },
        ShowFirmwaresModal(item) {
            this.file = null;
            /* this.edit_type = null;
            this.edit = false;*/
            this.report = false;
            this.error = false;
            this.edited_item = Object.assign({}, item, this.FirmwaresByDeviceTypeId(item.id))
            this.$refs.FirmwaresModal.Show();
            //  if (this.$refs.form_devices) this.$refs.form_devices.resetValidation()
        },
        async UploadFirmware() {
            this.persistent = true;
            this.action = {
                type: "firmwares",
                method: "put",
                subtype: "upload"
            };
            const formData = new FormData();
            formData.append("files", this.file)
            formData.append("device_type_id", this.edited_item.id)
            var firmwares = this.FirmwaresByDeviceTypeId(this.edited_item.id);
            await this.$store.dispatch(FIRMWARES_UPLOAD_PUT_REQUEST, {
                filename: this.file.name,
                formData: formData
            }).then(() => {
                var filename = firmwares.find(a => a.filename == this.file.name)?.filename ?? undefined;
                if (filename == undefined) {
                    this.report_text = this.file.name + ' успешно добавлена'
                }
                else {
                    this.report_text = this.file.name + ' успешно обновлена'
                }
                this.error = false;
                this.report = true;
                setTimeout(() => {
                    this.file = null;
                    this.error = false;
                    this.report = false
                    this.persistent = false;
                }, 4000);
            }).catch(err => {
                if (err.response?.status == 400) {
                    err.message = err.response.data
                }
                this.report_text = err.message;
                this.error = true;
                this.report = true;
                setTimeout(() => {
                    this.error = false;
                    this.report = false
                    this.persistent = false;
                }, 4000);
            })
        },
        async ChangeState(id, current_state) {
            var firmwares = this.FirmwaresByDeviceTypeId(this.edited_item.id);
            var index = firmwares.findIndex(el => el.id == id) ?? -1;
            this.action = {
                type: "firmwares",
                method: "put",
                subtype: "state",
                index
            };
            await this.$store.dispatch(FIRMWARES_STATE_PUT_REQUEST, {
                id,
                state: !current_state,
            }).then(() => {
                var filename = firmwares.find(a => a.id == id).filename;
                var state = current_state ? ' успешно разблокирована' : ' успешно заблокирована';
                var text = filename + state;
                this.report_text = text
                this.error = false;
                this.report = true;
                setTimeout(() => {
                    this.file = null;
                    this.error = false;
                    this.report = false
                    this.persistent = false;
                    this.action = this.default_action;
                }, 4000);
            }).catch(err => {
                if (err.response?.status == 400) {
                    err.message = err.response.data
                }
                this.report_text = err.message;
                this.error = true;
                this.report = true;
                setTimeout(() => {
                    this.error = false;
                    this.report = false
                    this.action = this.default_action;
                    this.persistent = false;
                }, 4000);
            })
        },
    }
}
</script>
