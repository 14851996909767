import Vue from 'vue'
import Vuex from 'vuex'
import API  from '@/store/axios';
import router from '@/router';
import { useRoute } from 'vue-router';
import { CAN_FILE_PUT_SUCCESS, DEVICES_GET_BY_ID_REQUEST, FIRMWARES_DELETE_ERROR, FIRMWARES_DELETE_SUCCESS, FIRMWARES_GET_ERROR, FIRMWARES_GET_SUCCESS } from '@/helpers/actions';
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    auth_get_magic_token_status: "",
    users: [],
    users_once_loaded: false,
    users_get_status: "",
    users_post_status: "",
    users_put_status: "",
    users_delete_status: "",
    devices: [],
    devices_once_loaded: false,
    devices_get_status: "",
    devices_post_status: "",
    devices_put_status: "",
    devices_delete_status: "",
    device_types: [],
    device_types_once_loaded: false,
    device_types_get_status: "",
    device_types_put_status: "",

    firmwares: [],
    firmwares_once_loaded: false,
    firmwares_get_status: "",
    firmwares_post_status: "",
    firmwares_put_status: "",
    firmwares_delete_status: "",
    calibrations_get_status: "",
    calibrations_post_status: "",
    calibrations_put_status: "",
    calibrations_delete_status: "",
    logger_get_status: "",
    first_show_status: true,
    http_errorStatus: null,
    http_errorStatus_text: "",
    connection_error: false,
  },
  getters: {
    GetConnectionError: state => state.connection_error,
    GetHttpErrorStatus: state => state.http_errorStatus,
    GetHttpErrorStatusText: state => state.http_errorStatus_text,

    GetFirstShowStatus: state => state.first_show_status,
    AuthMagicTokenGetAreLoading: state => state.auth_get_magic_token_status == "loading",
    AuthMagicGetHaveError: state => state.auth_get_magic_token_status == "error",
    AuthMagicGetAreLoaded: state => state.auth_get_magic_token_status == "success",
    LoggerGetAreLoading: state => state.logger_get_status == "loading",
    LoggerGetHaveError: state => state.logger_get_status == "error",
    LoggerGetAreLoaded: state => state.logger_get_status == "success",
    AllUsers: state => state.users,
    UserById: (state, getters) => id => getters.AllUsers?.find(a => a.id == id),
    UsersByDeviceTypeId: (state, getters) => device_type_id => getters.AllUsers?.filter(a => a.deviceTypes?.find(b => b == device_type_id) != undefined) ?? [],
    UsersOnceLoaded: state => state.users_once_loaded,
    UsersGetAreLoading: state => state.users_get_status == "loading",
    UsersGetHaveError: state => state.users_get_status == "error",
    UsersGetAreLoaded: state => state.users_get_status == "success",
    UsersPostAreLoading: state => state.users_post_status == "loading",
    UsersPostHaveError: state => state.users_post_status == "error",
    UsersPostAreLoaded: state => state.users_post_status == "success",
    UsersPutAreLoading: state => state.users_put_status == "loading",
    UsersPutHaveError: state => state.users_put_status == "error",
    UsersPutAreLoaded: state => state.users_put_status == "success",
    UsersDeleteAreLoading: state => state.users_delete_status == "loading",
    UsersDeleteHaveError: state => state.users_delete_status == "error",
    UsersDeleteAreLoaded: state => state.users_delete_status == "success",
    AllDevices: state => state.devices,
    DeviceById: (state, getters) => id => getters.AllDevices?.find(a => a.id == id),
    DevicesByType: (state, getters) => type_id => getters.AllDevices?.filter(a => a.deviceTypeID == type_id) ?? [],
    DevicesOnceLoaded: state => state.devices_once_loaded,
    DevicesGetAreLoading: state => state.devices_get_status == "loading",
    DevicesGetHaveError: state => state.devices_get_status == "error",
    DevicesGetAreLoaded: state => state.devices_get_status == "success",
    DevicesPostAreLoading: state => state.devices_post_status == "loading",
    DevicesPostHaveError: state => state.devices_post_status == "error",
    DevicesPostAreLoaded: state => state.devices_post_status == "success",
    DevicesPutAreLoading: state => state.devices_put_status == "loading",
    DevicesPutHaveError: state => state.devices_put_status == "error",
    DevicesPutAreLoaded: state => state.devices_put_status == "success",
    DevicesDeleteAreLoading: state => state.devices_delete_status == "loading",
    DevicesDeleteHaveError: state => state.devices_delete_status == "error",
    DevicesDeleteAreLoaded: state => state.devices_delete_status == "success",
    AllDeviceTypes: state => state.device_types.sort((a, b) => {
      if (a.hardwareVersion - b.hardwareVersion > 0) {
        return 1;
      }
      if (a.hardwareVersion - b.hardwareVersion < 0) {
        return -1;
      }
    }),
    DeviceTypeById: (state, getters) => id => getters.AllDeviceTypes?.find(a => a.id == id),
    DeviceTypesOnceLoaded: state => state.device_types_once_loaded,
    DeviceTypesGetAreLoading: state => state.device_types_get_status == "loading",
    DeviceTypesGetHaveError: state => state.device_types_get_status == "error",
    DeviceTypesGetAreLoaded: state => state.device_types_get_status == "success",

    DeviceTypesPutAreLoading: state => state.device_types_put_status == "loading",
    DeviceTypesPutHaveError: state => state.device_types_put_status == "error",
    DeviceTypesPutAreLoaded: state => state.device_types_put_status == "success",

    ParametersGetAreLoading: state => state.parameters_get_status == "loading",
    ParametersGetHaveError: state => state.parameters_get_status == "error",
    ParametersGetAreLoaded: state => state.parameters_get_status == "success",
    ParametersPostAreLoading: state => state.parameters_post_status == "loading",
    ParametersPostHaveError: state => state.parameters_post_status == "error",
    ParametersPostAreLoaded: state => state.parameters_post_status == "success",
    ParametersPutAreLoading: state => state.parameters_put_status == "loading",
    ParametersPutHaveError: state => state.parameters_put_status == "error",
    ParametersPutAreLoaded: state => state.parameters_put_status == "success",
    ParametersDeleteAreLoading: state => state.parameters_delete_status == "loading",
    ParametersDeleteHaveError: state => state.parameters_delete_status == "error",
    ParametersDeleteAreLoaded: state => state.parameters_delete_status == "success",
    CalibrationsGetAreLoading: state => state.calibrations_get_status == "loading",
    CalibrationsGetHaveError: state => state.calibrations_get_status == "error",
    CalibrationsGetAreLoaded: state => state.calibrations_get_status == "success",
    CalibrationsPostAreLoading: state => state.calibrations_post_status == "loading",
    CalibrationsPutAreLoading: state => state.calibrations_put_status == "loading",
    CalibrationsDeleteAreLoading: state => state.calibrations_delete_status == "loading",
    AllFirmwares: state => state.firmwares,
    FirmwaresByDeviceTypeId: (state, getters) => device_type_id => getters.AllFirmwares?.filter(a => a.deviceTypeID === device_type_id) ?? [],
    FirmwaresGetAreLoading: state => state.firmwares_get_status == "loading",
    FirmwaresGetHaveError: state => state.firmwares_get_status == "error",
    FirmwaresGetAreLoaded: state => state.firmwares_get_status == "success",
    FirmwaresPutAreLoading: state => state.firmwares_put_status == "loading",
  },
  mutations: {
    [CLEAR_FIRST_SHOW_STATUS](state, code) {
      state.first_show_status = false;
    },
    [SET_HTTP_ERROR_STATUS](state, info) {
      state.http_errorStatus = info.code;
      state.http_errorStatus_text = info.error_text;
    },
    [UPDATE_CONNECTION_ERROR](state, value = false) {
      state.connection_error = value;
    },
    [CLEAR_VUEX_STATE](state) {
      state.users = [];
      state.users_once_loaded = false;
      state.auth_get_magic_token_status = "";
      state.users_get_status = "";
      state.users_post_status = "";
      state.users_put_status = "";
      state.users_delete_status = "";
      state.devices = [];
      state.devices_once_loaded = false;
      state.devices_get_status = "";
      state.devices_post_status = "";
      state.devices_put_status = "";
      state.devices_delete_status = "";
      state.parameters_get_status = "";
      state.parameters_post_status = "";
      state.parameters_put_status = "";
      state.device_types = [];
      state.device_types_once_loaded = false;
      state.device_types_get_status = "";
      state.device_types_put_status = "";

      state.firmwares = [];
      state.firmwares_once_loaded = false;
      state.firmwares_get_status = "";
      state.firmwares_post_status = "";
      state.firmwares_put_status = "";
      state.firmwares_delete_status = "";
      state.calibrations_get_status = "";
      state.calibrations_post_status = "";
      state.calibrations_put_status = "";
      state.calibrations_delete_status = "";
      state.http_errorStatus = null;
      state.http_errorStatus_text = "";

      state.first_show_status = true;
      state.logger_get_status = ""
    },
    //#region MUTATION AUTH
    [AUTH_GET_MAGIC_TOKEN_REQUEST](state) {
      state.auth_get_magic_token_status = "loading";
    },
    [AUTH_GET_MAGIC_TOKEN_SUCCESS](state) {
      Vue.nextTick(() => { state.auth_get_magic_token_status = "success" })
    },
    [AUTH_GET_MAGIC_TOKEN_ERROR](state) {
      state.auth_get_magic_token_status = "error";
    },
    //#endregion
    //#region MUTATION USERS
    [USERS_GET_REQUEST](state) {
      state.users_get_status = "loading";
    },
    [USERS_GET_SUCCESS](state, data) {
      Vue.set(state, "users", data);
      state.users_once_loaded = true;
      Vue.nextTick(() => { state.users_get_status = "success" })
    },
    [USERS_GET_ERROR](state) {
      state.users_get_status = "error";
    },
    [USERS_POST_REQUEST](state) {
      state.users_post_status = "loading";
    },
    [USERS_POST_ERROR](state) {
      state.users_post_status = "error";
    },
    [USERS_POST_SUCCESS](state, data) {
      state.users_post_status = "success";
      state.users.push(data);
    },
    [USERS_PUT_REQUEST](state) {
      state.users_put_status = "loading";
    },
    [USERS_PUT_ERROR](state) {
      state.users_put_status = "error";
    },
    [USERS_PUT_SUCCESS](state, data) {
      let i = state.users?.findIndex(p => p.id == data.id) ?? -1;
      if (i > -1)
        state.users.splice(i, 1, data);
      Vue.nextTick(() => {
        state.users_put_status = "success";
      })
    },
    [USERS_DELETE_REQUEST](state) {
      state.users_delete_status = "loading";
    },
    [USERS_DELETE_SUCCESS](state, id) {
      let i = state.users?.findIndex(p => p.id == id) ?? -1;
      if (i > -1)
        state.users.splice(i, 1);
      Vue.nextTick(() => {
        state.users_delete_status = "success";
      })
    },
    [USERS_DELETE_ERROR](state) {
      state.users_delete_status = "error";
    },
    //#endregion
    //#region MUTATION DEVICES
    [DEVICES_GET_REQUEST](state) {
      state.devices_get_status = "loading";
    },
    [DEVICES_GET_SUCCESS](state, obj) {
      state.devices_get_status = "success";
      if (obj.type == "all") {
        Vue.set(state, "devices", obj.data);
        state.devices_once_loaded = true;
      }
      if (obj.type == "id") {
        if (state.devices.length == 0) {
          Vue.set(state, "devices", obj.data);
        }
        else {
          let i = state.devices?.findIndex(p => p.id == obj.data[0].id) ?? -1;
          if (i > -1)
            state.devices?.splice(i, 1, obj.data[0]);
        }
      }
      Vue.nextTick(() => { state.devices_get_status = "success" })
    },
    [DEVICES_GET_ERROR](state) {
      state.devices_get_status = "error";
    },
    [DEVICE_TYPES_GET_REQUEST](state) {
      state.device_types_get_status = "loading";
    },

    [DEVICE_TYPES_GET_SUCCESS](state, data) {
      Vue.set(state, "device_types", data);
      state.device_types_once_loaded = true;
      Vue.nextTick(() => { state.device_types_get_status = "success" })
    },
    [DEVICE_TYPES_GET_ERROR](state) {
      state.device_types_get_status = "error";
    },
    [DEVICE_TYPES_PUT_REQUEST](state) {
      state.device_types_put_status = "loading";
    },
    [DEVICE_TYPES_PUT_SUCCESS](state, data) {
      let i = state.device_types?.findIndex(p => p.id == data.id) ?? -1;
      if (i > -1)
        state.device_types.splice(i, 1, data);
      Vue.nextTick(() => {
        state.device_types_put_status = "success";
      })
    },
    [DEVICE_TYPES_PUT_ERROR](state) {
      state.device_types_put_status = "error";
    },


    [DEVICES_POST_REQUEST](state) {
      state.devices_post_status = "loading";
    },
    [DEVICES_POST_ERROR](state) {
      state.devices_post_status = "error";
    },
    [DEVICES_POST_SUCCESS](state, data) {
      state.devices.push(data);
      Vue.nextTick(() => { state.devices_post_status = "success"; })
    },
    [DEVICES_PUT_TRANSFER_REQUEST](state) {
      state.devices_put_status = "loading";
    },
    [DEVICES_PUT_TRANSFER_ERROR](state) {
      state.devices_put_status = "error";
    },
    [DEVICES_PUT_TRANSFER_SUCCESS](state, data) {
      let i = state.devices?.findIndex(p => p.id == data.id) ?? -1;
      if (i > -1)
        state.devices.splice(i, 1, data);
      Vue.nextTick(() => {
        state.devices_put_status = "success";
      })
    },
    [DEVICES_PUT_REQUEST](state) {
      state.devices_put_status = "loading";
    },
    [DEVICES_PUT_ERROR](state) {
      state.devices_put_status = "error";
    },
    [DEVICES_PUT_SUCCESS](state, data) {
      let i = state.devices?.findIndex(p => p.id == data.id) ?? -1;
      if (i > -1)
        state.devices.splice(i, 1, data);
      Vue.nextTick(() => {
        state.devices_put_status = "success";
      })
    },
    [DEVICES_DELETE_REQUEST](state) {
      state.devices_delete_status = "loading";
    },
    [DEVICES_DELETE_SUCCESS](state, id) {
      let i = state.devices?.findIndex(p => p.id == id) ?? -1;
      if (i > -1)
        state.devices.splice(i, 1);
      Vue.nextTick(() => {
        state.devices_delete_status = "success";
      })
    },
    [DEVICES_DELETE_ERROR](state) {
      state.devices_delete_status = "error";
    },
    //#endregion
    //#region MUTATION PARAMETERS
    [PARAMETERS_GET_REQUEST](state) {
      state.parameters_get_status = "loading";
    },
    [PARAMETERS_GET_SUCCESS](state) {
      state.parameters_get_status = "success";
    },
    [PARAMETERS_GET_ERROR](state, data) {
      state.parameters_get_status = "error";
    },
    [PARAMETERS_PUT_REQUEST](state) {
      Vue.nextTick(() => { state.parameters_put_status = "loading" });
    },
    [PARAMETERS_PUT_ERROR](state) {
      Vue.nextTick(() => { state.parameters_put_status = "error" });
    },
    [PARAMETERS_PUT_SUCCESS](state, data) {
      Vue.nextTick(() => { state.parameters_put_status = "success" });
      //state.parameters.push(data);
    },


    [CAN_FILE_PUT_SUCCESS](state, data) {
      if (data != null) {
        let i = state.devices?.findIndex(p => p.id == data.dev_id) ?? -1;
        if (i > -1)
          state.devices[i].dataCAN = data.dataCAN;
      }
    },
    [CAN_FILE_PUT_ERROR](state, data) {

    },
    //#endregion
    //#region MUTATION FIRMWARES
    [FIRMWARES_GET_REQUEST](state) {
      state.firmwares_get_status = "loading";
    },
    [FIRMWARES_GET_SUCCESS](state, data) {
      if (data.type == "all") {
        Vue.set(state, "firmwares", data.data); state.firmwares_once_loaded = true;
      }
      Vue.nextTick(() => { state.firmwares_get_status = "success" })
    },
    [FIRMWARES_GET_ERROR](state) {
      state.firmwares_get_status = "error";
    },
    [FIRMWARES_PUT_REQUEST](state) {
      state.firmwares_put_status = "loading";
    },
    [FIRMWARES_PUT_ERROR](state) {
      state.firmwares_put_status = "error";
    },



    [FIRMWARES_PUT_SUCCESS](state, data) {
      if (data != undefined && data.hasOwnProperty("type")) {
        for (var i = 0; i < data.devices.length; i++) {
          let j = state.devices?.findIndex(p => p.id == data.devices[i].id) ?? -1;
          state.devices.splice(j, 1, data.devices[i])

        }
      }
      else if (data != undefined) {
        let i = state.firmwares?.findIndex(p => p.id == data.id) ?? -1;
        if (i > -1)
          state.firmwares.splice(i, 1, data)
        else state.firmwares.push(data)
      }
      Vue.nextTick(() => { state.firmwares_put_status = "success" })
    },
    [FIRMWARES_DELETE_REQUEST](state) {
      state.firmwares_delete_status = "loading";
    },
    [FIRMWARES_DELETE_ERROR](state) {
      state.firmwares_delete_status = "error";
    },
    [FIRMWARES_DELETE_SUCCESS](state, id) {
      let i = state.firmwares?.findIndex(p => p.id == id) ?? -1;
      if (i > -1)
        state.firmwares.splice(i, 1)
      state.firmwares_delete_status = "success";
    },
    //#endregion
    //#region  MUTATION CALIBRATIONS
    [CALIBRATIONS_GET_REQUEST](state) {
      state.calibrations_get_status = "loading";
    },
    [CALIBRATIONS_GET_SUCCESS](state) {
      state.calibrations_get_status = "success";
    },
    [CALIBRATIONS_GET_ERROR](state, data) {
      state.calibrations_get_status = "error";
    },
    [CALIBRATIONS_PUT_REQUEST](state) {
      state.calibrations_put_status = "loading";
    },
    [CALIBRATIONS_PUT_SUCCESS](state) {
      state.calibrations_put_status = "success";
    },
    [CALIBRATIONS_PUT_ERROR](state, data) {
      state.calibrations_put_status = "error";
    },
    [CALIBRATIONS_POST_REQUEST](state) {
      state.calibrations_post_status = "loading";
    },
    [CALIBRATIONS_POST_SUCCESS](state) {
      state.calibrations_post_status = "success";
    },
    [CALIBRATIONS_POST_ERROR](state, data) {
      state.calibrations_post_status = "error";
    },
    [CALIBRATIONS_DELETE_REQUEST](state) {
      state.calibrations_delete_status = "loading";
    },
    [CALIBRATIONS_DELETE_SUCCESS](state) {
      state.calibrations_delete_status = "success";
    },
    [CALIBRATIONS_DELETE_ERROR](state, data) {
      state.calibrations_delete_status = "error";
    },
    //#endregion
    /*#region CANCEL_COMMAND
    [CANCEL_FIRMWARE_UPDATE_PUT_REQUEST](state) {
    },
    [CANCEL_FIRMWARE_UPDATE_PUT_SUCCESS](state, device_id) {
     var device = state.devices.find(a => a.id == device_id);
      if (device != undefined)
        device.flagUpdateFirware = false;
    },
    [CANCEL_FIRMWARE_UPDATE_PUT_ERROR](state) {
    },
    [CANCEL_CALIBRATION_ACTIVE_PUT_REQUEST](state,) {
    },
    [CANCEL_CALIBRATION_ACTIVE_PUT_SUCCESS](state, device_id) {
      var device = state.devices.find(a => a.id == device_id);
      if (device != undefined)
        device.flagUpdateCalibrations = false;
    },
    [CANCEL_CALIBRATION_ACTIVE_PUT_ERROR](state, device_id) {
    },
    [CANCEL_PATAMETERS_UPDATE_PUT_REQUEST](state) {
    },
    [CANCEL_PATAMETERS_UPDATE_PUT_ERROR](state) {
    },
    [CANCEL_PATAMETERS_UPDATE_PUT_SUCCESS](state, device_id) {
      var device = state.devices.find(a => a.id == device_id);
      if (device != undefined)
        device.flagUpdateParameters = false;
    },*/
    //#endregion
    //#region LOGGER
    [LOGGER_GET_REQUEST](state) {
      Vue.nextTick(() => { state.logger_get_status = "loading" })
    },
    [LOGGER_GET_SUCCESS](state) {
      Vue.nextTick(() => { state.logger_get_status = "success" })
    },
    [LOGGER_GET_ERROR](state) {
      Vue.nextTick(() => { state.logger_get_status = "error" })
    },
    //#endregion
  },
  actions: {
    //HELPERS
    [CHECK_HTTP_ERROR]: async ({ dispatch, commit, getters }, err) => {
      if ((err.status === 401 || err.response?.status === 401) && getters.GetHttpErrorStatus != 403) {
        commit(SET_HTTP_ERROR_STATUS, { code: 401, error_text: "Время авторизационной сессии истекло" });
        dispatch(AUTH_LOGOUT_REQUEST);
      }
      if ((err.status === 403 || err.response?.status === 403) && getters.GetHttpErrorStatus != 403) {
        commit(SET_HTTP_ERROR_STATUS, { code: 403, error_text: "Нет доступа к запрашиваемому ресурсу" })
        dispatch(AUTH_LOGOUT_REQUEST);
      }
    },
    //#region ACTION AUTH
    [AUTH_LOGIN_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        //commit(AUTH_LOGIN);
        API({ url: "Account", data, method: "POST" })
          .then(resp => {
            console.log(resp)
            resolve(resp);
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [AUTH_LOGOUT_REQUEST]: async ({ commit, dispatch, }) => {
      API({ url: 'Account/logout' }).then(() => {
        commit(CLEAR_VUEX_STATE);
        router.push({ name: "Login" })
      })
      await deleteUserToken();
      await deleteMagicToken()

    },
    [AUTH_GET_MAGIC_TOKEN_REQUEST]: ({ commit, dispatch }, user_id) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_GET_MAGIC_TOKEN_REQUEST);
        API({ url: `Account/magictoken/${user_id}`, method: "GET" })
          .then(resp => {
            commit(AUTH_GET_MAGIC_TOKEN_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(AUTH_GET_MAGIC_TOKEN_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
    //#region ACTION USERS
    [USERS_GET_REQUEST]: ({ commit, dispatch }) => {
      commit(USERS_GET_REQUEST);
      API({ url: "User" })
        .then(resp => {
          commit(USERS_GET_SUCCESS, resp.data);
        })
        .catch(err => {
          commit(USERS_GET_ERROR);
          dispatch(CHECK_HTTP_ERROR, err);
        });
    },
    [USERS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(USERS_PUT_REQUEST);
        API({ url: "User", data, method: "PUT" })
          .then(resp => {
            commit(USERS_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(USERS_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [USERS_POST_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(USERS_POST_REQUEST);
        API({ url: "User", data, method: "POST" })
          .then(resp => {
            commit(USERS_POST_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(USERS_POST_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [USERS_DELETE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit(USERS_DELETE_REQUEST);
        API({ url: `User/${id}`, method: "DELETE" })
          .then(resp => {
            commit(USERS_DELETE_SUCCESS, id);
            resolve(resp);
          })
          .catch(err => {
            commit(USERS_DELETE_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
    //#region ACTION DEVICES
    [DEVICES_GET_REQUEST]: ({ commit, dispatch }, type) => {
      commit(DEVICES_GET_REQUEST);
      var url;
      if (type == "admin") {
        url = `Device/admin`;
      }
      else {
        url = `Device/user/`
      }
      API({ url: url })
        .then(resp => {
          commit(DEVICES_GET_SUCCESS, { type: "all", data: resp.data });
        })
        .catch(err => {
          commit(DEVICES_GET_ERROR);
          dispatch(CHECK_HTTP_ERROR, err);
        });
    },
    [DEVICES_GET_BY_ID_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(DEVICES_GET_REQUEST);
        var url;
        if (data.type == "admin") {
          url = `Device/admin`;
        }
        else {
          url = `Device/user/`
        }
        API({ url, params: { dev_id: data.device_id } })
          .then(resp => {
            commit(DEVICES_GET_SUCCESS, { type: "id", data: resp.data });
            resolve(resp.data);
          })
          .catch(err => {
            commit(DEVICES_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [DEVICE_TYPES_GET_REQUEST]: ({ commit, dispatch }, type) => {
      commit(DEVICE_TYPES_GET_REQUEST);
      var url;
      if (type == "admin") {
        url = `Device/DeviceTypes/admin`;
      }
      else {
        url = `Device/DeviceTypes/user`
      }
      API({ url })
        .then(resp => {
          commit(DEVICE_TYPES_GET_SUCCESS, resp.data);
        })
        .catch(err => {
          commit(DEVICE_TYPES_GET_ERROR);
          dispatch(CHECK_HTTP_ERROR, err);
        });
    },
    [DEVICE_TYPES_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(DEVICE_TYPES_PUT_REQUEST);
        API({ url: "Device/DeviceTypes/admin", data, method: "PUT" })
          .then(resp => {
            commit(DEVICE_TYPES_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(DEVICE_TYPES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },

    [DEVICES_POST_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        ///МЕТОД НЕ ИСПОЛЬЗУЕТСЯ
        commit(DEVICES_POST_REQUEST);
        API({ url: "Device/admin", data, method: "POST" })
          .then(resp => {
            commit(DEVICES_POST_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(DEVICES_POST_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [DEVICES_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        ///МЕТОД НЕ ИСПОЛЬЗУЕТСЯ
        commit(DEVICES_PUT_REQUEST);
        API({ url: "Device/rename", data, method: "PUT" })
          .then(resp => {
            commit(DEVICES_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(DEVICES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [DEVICES_PUT_TRANSFER_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(DEVICES_PUT_TRANSFER_REQUEST);
        API({ url: `Device/transfer/${data.device_id}/${data.user_id}`, method: "PUT" })
          .then(resp => {
            commit(DEVICES_PUT_TRANSFER_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(DEVICES_PUT_TRANSFER_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [DEVICES_DELETE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit(DEVICES_DELETE_REQUEST);
        API({ url: `Device/admin/${id}`, method: "DELETE" })
          .then(resp => {
            commit(DEVICES_DELETE_SUCCESS, id);
            resolve(resp);
          })
          .catch(err => {
            commit(DEVICES_DELETE_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
    //#region ACTION PARAMETERS
    [PARAMETERS_GET_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        commit(PARAMETERS_GET_REQUEST);
        API({ url: `Parameter/${device_id}`, method: "GET" })
          .then(resp => {
            commit(PARAMETERS_GET_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(PARAMETERS_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [PARAMETERS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        ///МЕТОД НЕ ИСПОЛЬЗУЕТСЯ
        commit(PARAMETERS_PUT_REQUEST);
        var parameters = data.parameters
        API({ url: `Parameter/${data.device_id}`, data: parameters, method: "PUT" })
          .then(resp => {
            commit(PARAMETERS_PUT_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(PARAMETERS_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CAN_FILE_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      API({ url: `Parameter/UploadCAN/${data.device_id}`, data: data.dataCAN, method: "PUT" })
        .then(resp => {
          commit(CAN_FILE_PUT_SUCCESS, { dev_id: data.device_id, dataCAN: data.dataCAN });
        })
        .catch(err => {
          commit(CAN_FILE_PUT_REQUEST);
          dispatch(CHECK_HTTP_ERROR, err);
        });
    },

    //#endregion
    //#region ACTION CALIBRATIONS
    [CALIBRATIONS_GET_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_GET_REQUEST);
        API({ url: `Calibration/${device_id}`, method: "GET" })
          .then(resp => {
            commit(CALIBRATIONS_GET_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [DEVICES_WTIH_CALIBRATIONS_REQUEST]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_GET_REQUEST);
        API({ url: `Calibration/devices`, method: "GET" })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        ///МЕТОД НЕ ИСПОЛЬЗУЕТСЯ
        commit(CALIBRATIONS_PUT_REQUEST);
        var calibration = data.calibration
        API({ url: `Calibration/${data.device_id}`, data: calibration, method: "PUT" })
          .then(resp => {
            commit(CALIBRATIONS_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_PUT_ACTIVE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_PUT_REQUEST);
        API({ url: `Calibration/active/${id}`, method: "PUT" })
          .then(resp => {
            commit(CALIBRATIONS_PUT_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_PUT_DEACTIVE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_PUT_REQUEST);
        API({ url: `Calibration/deActive/${id}`, method: "PUT" })
          .then(resp => {
            commit(CALIBRATIONS_PUT_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_GET_REFRESH_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        API({ url: `Calibration/reload/${device_id}`, method: "GET" })
          .then(resp => {
            dispatch(DEVICES_GET_BY_ID_REQUEST, { device_id, type: 'user' })
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },

    [CALIBRATIONS_POST_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_POST_REQUEST);
        var calibration = data.calibration
        API({ url: `Calibration/${data.device_id}`, data: calibration, method: "POST" })
          .then(resp => {
            commit(CALIBRATIONS_POST_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_POST_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_POST_FROM_TO_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_POST_REQUEST);
        API({ url: `Calibration/transfer/${data.from_device_id}/${data.to_device_id}`, method: "POST" })
          .then(resp => {
            commit(CALIBRATIONS_POST_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(CALIBRATIONS_POST_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CALIBRATIONS_DELETE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {
        commit(CALIBRATIONS_DELETE_REQUEST);
        API({ url: `Calibration/${id}`, method: "DELETE" })
          .then(resp => {
            commit(CALIBRATIONS_DELETE_SUCCESS);
            resolve(id);
          })
          .catch(err => {
            commit(CALIBRATIONS_DELETE_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
    //#region ACTION FIRMWARES
    [FIRMWARES_GET_REQUEST]: ({ commit, dispatch }, type) => {
      commit(FIRMWARES_GET_REQUEST);
      API({ url: `Firmware` })
        .then(resp => {
          commit(FIRMWARES_GET_SUCCESS, { type: "all", data: resp.data });
        })
        .catch(err => {
          commit(FIRMWARES_GET_ERROR);
          dispatch(CHECK_HTTP_ERROR, err);
        });
    },
    [FIRMWARES_DELETE_REQUEST]: ({ commit, dispatch }, id) => {
      return new Promise((resolve, reject) => {

        commit(FIRMWARES_DELETE_REQUEST);
        API({ url: `Firmware/admin/${id}`, method: "DELETE" })
          .then(resp => {
            commit(FIRMWARES_DELETE_SUCCESS, id);
            resolve()
          })
          .catch(err => {
            commit(FIRMWARES_DELETE_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err)
          });
      })
    },
    [FIRMWARES_GET_BY_DEVICE_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        ///МЕТОД НЕ ИСПОЛЬЗУЕТСЯ
        commit(FIRMWARES_GET_REQUEST);
        API({ url: `Firmware/device/${device_id}`, method: "GET" })
          .then(resp => {
            commit(FIRMWARES_GET_SUCCESS, { type: "firmware_by_device" });
            resolve(resp);
          })
          .catch(err => {
            commit(FIRMWARES_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [FIRMWARES_UPLOAD_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(FIRMWARES_PUT_REQUEST);
        API({ url: `Firmware/upload/${data.filename}`, data: data.formData, method: "PUT" })
          .then(resp => {
            commit(FIRMWARES_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(FIRMWARES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [FIRMWARES_UPDATE_MULTIPLY_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        var params =
        {
          sw_start: data.sw_start,
          sw_end: data.sw_end
        }
        commit(FIRMWARES_PUT_REQUEST);
        API({ url: `Firmware/update/${data.id}`, params, method: "PUT" })
          .then(resp => {
            var data = {
              devices: resp.data,
              type: "MultiplyDevices"
            }
            commit(FIRMWARES_PUT_SUCCESS, data);
            resolve(resp);
          })
          .catch(err => {
            commit(FIRMWARES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [FIRMWARES_UPDATE_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(FIRMWARES_PUT_REQUEST);
        API({ url: `Firmware/update/${data.device_id}/${data.filename}`, method: "PUT" })
          .then(resp => {
            commit(FIRMWARES_PUT_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(FIRMWARES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },



    [FIRMWARES_STATE_PUT_REQUEST]: ({ commit, dispatch }, data) => {
      return new Promise((resolve, reject) => {
        commit(FIRMWARES_PUT_REQUEST);
        API({ url: `Firmware/${data.id}/${data.state}`, method: "PUT" })
          .then(resp => {
            commit(FIRMWARES_PUT_SUCCESS, resp.data);
            resolve(resp);
          })
          .catch(err => {
            commit(FIRMWARES_PUT_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
    //#region ACTION CANCEL COMAND
    //#endregion
    [CANCEL_FIRMWARE_UPDATE_PUT_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        API({ url: `Firmware/update/cancel/${device_id}`, method: "PUT" })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CANCEL_CALIBRATION_ACTIVE_PUT_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        API({ url: `Calibration/active/cancel/${device_id}`, method: "PUT" })
          .then(resp => {
            resolve();
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [CANCEL_PATAMETERS_UPDATE_PUT_REQUEST]: ({ commit, dispatch }, device_id) => {
      return new Promise((resolve, reject) => {
        API({ url: `Parameter/cancel/${device_id}`, method: "PUT" })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#region ACTION LOGGER
    [LOGGER_GET_REQUEST]: ({ commit, dispatch }, type) => {
      return new Promise((resolve, reject) => {
        commit(LOGGER_GET_REQUEST);
        var url;
        if (type == "admin") {
          url = `Logger/admin`;
        }
        else {
          url = `Logger/user/`
        }
        API({ url, method: "GET" })
          .then(resp => {
            commit(LOGGER_GET_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(LOGGER_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    [LOGGER_PUT_REQUEST]: ({ commit, dispatch }, elems) => {
      return new Promise((resolve, reject) => {
        commit(LOGGER_GET_REQUEST);
        var url;
        API({ url: `Logger`/*report_history*/, params: elems.params, data: elems.data, method: "PUT" })
          .then(resp => {
            commit(LOGGER_GET_SUCCESS);
            resolve(resp);
          })
          .catch(err => {
            commit(LOGGER_GET_ERROR);
            dispatch(CHECK_HTTP_ERROR, err);
            reject(err);
          });
      });
    },
    //#endregion
  },
  modules: {
  }
})
 
