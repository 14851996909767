<template>
    <div>
        <v-card outlined>
            <v-card-title>
                     <v-row no-gutters align="center" justify="space-between">
                        <v-col class="d-flex flex-wrap align-center justify-space-between justify-md-start">
                            <div class="d-flex">
                                <v-icon small class="nav-icon mx-2"> fa fa-light fa-display</v-icon>
                                <h3 class="title"> Устройства </h3>
                            </div>
                            <v-btn small icon @click="Refresh()">
                                <v-icon small style="cursor:pointer" title="Обновить" class="px-2">fa
                                    fa-refresh</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <v-text-field v-model="filter" append-icon="mdi-magnify" label="Поиск" single-line
                                hide-details class="ma-0 pa-0"></v-text-field>
                        </v-col>
                    </v-row>
             </v-card-title>
            <v-card-text class="pa-0"><!--:custom-sort="deviceTableSort"-->
                <v-data-table :hide-default-header="!$vuetify.breakpoint.mobile" v-if="!$vuetify.breakpoint.mobile" show-group-by :sort-desc="true"
                    sortBy="imei" :search="filter" :fixed-header="!$vuetify.breakpoint.mobile" :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint" loading-text="Загрузка..." :header-props="header_props"
                    :footer-props.sync="footer_props" :loading="isBusy" no-results-text="Не найдено" :headers="fields"
                    :items="AllDevicesWithDevType" locale="ru-Ru" :items-per-page.sync="perPage" class="elevation-0">
                    <template v-slot:header="HeaderOPT">
                        <thead v-if="!HeaderOPT.isMobile" class="v-data-table-header">
                            <tr>
                                <th v-for="header in HeaderOPT.props.headers" role="columnheader"
                                    :aria-label="header.text" scope="col" :key="header.value" class="text-center"
                                    :width="header.width"
                                    :class="[{ 'sortable': header.sortable }, header.class,
                                    { 'active': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value },
                                    { 'asc': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value && !HeaderOPT.props.options.sortDesc[0] },
                                    { 'desc': HeaderOPT.props.options.sortBy.length > 0 && HeaderOPT.props.options.sortBy[0] == header.value && HeaderOPT.props.options.sortDesc[0] }]">
                                    <span>{{ header.text }}</span>
                                    <div class="mx-1 d-inline-flex align-center" style="gap:5px">
                                        <v-icon small title="Группировка"
                                            v-if="header.groupable && HeaderOPT.props.showGroupBy"
                                            @click="HeaderOPT.on.group(header.value)" style="opacity: 1 !important">mdi
                                            mdi-format-list-group</v-icon>
                                        <v-icon small v-if="header.sortable" @click="HeaderOPT.on.sort(header.value)"
                                            class="v-data-table-header__icon">mdi-arrow-up</v-icon>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item.index="{ item, index, props }">
                        {{ index + 1 }}
                    </template>
                    <template v-slot:item.online="{ item, index, props }">
                        <v-lazy>
                            <v-icon :color="item.online ? 'rgb(101 229 107)' : 'rgb(231 231 231)'"
                                :title="!item.online ? OffTime(item.lastConnectionDateTime) : OnlineTime(item.lastConnectionDateTime)">mdi-circle</v-icon>
                        </v-lazy>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div class="d-flex gap-3 align-center justify-space-between">
                            <div class="flex-grow-1" v-if="item.name != null">{{ item.name }}</div>
                            <div class="flex-grow-1 text-center" v-else>-</div>
                            <div class="actions flex-grow-0" style="cursor:pointer">
                                <v-btn x-small icon color="primary"
                                    @click="ShowEditDeviceModal(item, 'common_parametrs')"
                                    title="Изменение общих параметров">
                                    <v-icon small> fa fa-pencil fa-icon-small </v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.softwareVersion="{ item }">
                        <v-lazy>
                            <div class="d-flex gap-3 justify-space-between align-center">
                                <div class="flex-grow-1 d-flex flex-wrap  align-center justify-start"
                                    v-if="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined)">
                                    {{ item.softwareVersion }} <v-icon small>mdi
                                        mdi-arrow-right-thin</v-icon> {{ item.newSoftwareVersion != null ?
                                            item.newSoftwareVersion : devices_files?.find(el => el.id ==
                                                item.id)?.software_version ??
                                            0 }}</div>
                                <div v-else> {{ item.newSoftwareVersion != null ?
                                    item.newSoftwareVersion + '?' : item.softwareVersion }}
                                </div>
                                <div class="actions" style="cursor:pointer">
                                    <v-btn x-small icon
                                        :color="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined) ? 'info' : '#4CAF50'"
                                        @click="ShowUpdateFirmwareModal(item)"
                                        :title="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined) ? 'Обновление ПО/Устройство на обновлении' : 'Обновление ПО'">

                                        <v-icon small class="animated"
                                            v-if="FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined">
                                            fa fa-refresh </v-icon>
                                        <v-icon v-else> mdi-arrow-up-bold</v-icon>
                                    </v-btn>
                                    <v-btn x-small v-if="FirmwareFlag(item)" icon color="red darken-3"
                                        @click="СancelFirmwareUpdate(item.id)" title="Отмена команды обновления ПО">
                                        <v-icon small>fa fa-x fa-icon-small</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-lazy>
                    </template>
                    <template v-slot:item.parameters="{ item }">
                        <v-lazy>
                            <div class="d-flex gap-3 align-center justify-space-between">

                                <div class="flex-grow-1 d-flex flex-wrap justify-center align-center">

                                    <span class="flex-grow-1 text-start">{{ item.parametersCount != null ?
                                        item.parametersCount :
                                        '0' }} шт.</span>
                                </div>
                                <div class="flex-grow-0" style="cursor:pointer">
                                    <v-btn x-small icon :color="ParameterFlag(item) ? 'info' : 'amber lighten-3'"
                                        @click="ShowEditParametersModal(item)"
                                        :title="ParameterFlag(item) ? 'Изменение параметров/Параметры на обновлении' : 'Изменение параметров'">
                                        <v-icon small class="animated" v-if="ParameterFlag(item)">fa fa-refresh</v-icon>
                                        <v-icon small v-else>fa fa-cog fa-icon-small</v-icon>
                                    </v-btn>
                                    <v-btn x-small v-if="ParameterFlag(item)" icon color="red darken-3"
                                        @click="СancelParametersUpdate(item.id)"
                                        title="Отмена команды обновления параметров">
                                        <v-icon small>fa fa-x fa-icon-small</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-lazy>
                    </template>
                    <template v-slot:item.calibrationCount="{ item }">
                        <v-lazy>
                            <div class="d-flex gap-3 align-center justify-space-between">
                                <div class="flex-grow-1 d-flex flex-wrap justify-center align-center">

                                    <span class="flex-grow-1 text-start">{{ item.calibrationCount != null ?
                                        item.calibrationCount :
                                        '0' }} шт.</span>
                                </div>
                                <div class="flex-grow-0" style="cursor:pointer">
                                    <v-btn x-small icon :color="CalibrationFlag(item) ? 'info' : 'primary'"
                                        @click="ShowCalibrationsModal(item)"
                                        :title="CalibrationFlag(item) ? 'Изменение тарировок/Тарировки на обновлении' : 'Изменение тарировок'">
                                        <v-icon small class="animated" v-if="CalibrationFlag(item)">fa
                                            fa-refresh</v-icon>
                                        <v-icon small v-else>fa fa-pencil fa-icon-small</v-icon>
                                    </v-btn>
                                    <v-btn x-small v-if="CalibrationFlag(item)" icon color="red darken-3"
                                        @click="СancelCalibrationsUpdate(item.id)"
                                        title="Отмена команды обновления тарировок">
                                        <v-icon small>fa fa-x fa-icon-small</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-lazy>
                    </template>
                    <template v-slot:no-data>
                        <span v-if="DevicesGetHaveError || DeviceTypesGetHaveError" class="red--text">Ошибка загрузки
                            данных</span>
                        <span v-if="DevicesGetAreLoaded && AllDevicesWithDevType.length == 0">Нет устройств</span>
                    </template>

                </v-data-table>
                <div v-else>

                    <div v-if="AllDevicesWithDevTypeFiltered.length > 0" v-for="item in DevicePagination"
                        :key="item.imei" class="terminals-panel  mx-1 mb-2">

                        <v-row no-gutters v-if="item.name != null && item.name != ''">
                            <v-col class="pa-2 text-center">{{ item.name }}</v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex pa-2 align-center justify-space-between">
                                <div class="d-flex align-center">
                                    <v-icon class="me-1" :color="item.online ? 'rgb(101 229 107)' : 'rgb(231 231 231)'"
                                        :title="!item.online ? OffTime(item.lastConnectionDateTime) : OnlineTime(item.lastConnectionDateTime)">mdi-circle</v-icon>
                                    <div> {{ item.imei }} </div>
                                </div>


                                <div>
                                    {{ item.hardwareVersion }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="pa-2">
                                <div class="text-center">Версия ПО</div>
                                <div class="d-flex justify-space-between align-center">
                                    <div style="font-size: 10px;"
                                        v-if="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined)">
                                        {{ item.softwareVersion }}
                                        to
                                        {{ item.newSoftwareVersion != null ?
                                            item.newSoftwareVersion : devices_files?.find(el => el.id ==
                                                item.id)?.software_version ??
                                            0 }}
                                    </div>
                                    <div v-else>{{ item.newSoftwareVersion != null ? item.newSoftwareVersion + '?' :
                                        item.softwareVersion }}</div>
                                    <div style="cursor:pointer;display: flex; flex-wrap: nowrap;">
                                        <v-btn x-small icon
                                            :color="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined) ? 'info' : '#4CAF50'"
                                            @click="ShowUpdateFirmwareModal(item)"
                                            :title="(FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined) ? 'Обновление ПО/Устройство на обновлении' : 'Обновление ПО'">
                                            <v-icon x-small
                                                v-if="FirmwareFlag(item) || devices_files.find(el => el.id == item.id) != undefined">
                                                fa fa-refresh </v-icon>
                                            <v-icon small v-else> mdi-arrow-up-bold</v-icon>
                                        </v-btn>
                                        <v-btn x-small v-if="FirmwareFlag(item)" icon color="red darken-3"
                                            @click="СancelFirmwareUpdate(item.id)" title="Отмена команды обновления ПО">
                                            <v-icon x-small>fa fa-x</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="pa-2">
                                <div class="text-center">Параметры</div>
                                <div class="d-flex gap-3 align-center justify-space-between">

                                    <div class="flex-grow-1 d-flex flex-wrap justify-center align-center">

                                        <span class="flex-grow-1 text-start">{{ item.parametersCount != null ?
                                            item.parametersCount :
                                            '0' }} шт.</span>
                                    </div>
                                    <div class="flex-grow-0" style="cursor:pointer">
                                        <v-btn x-small icon :color="ParameterFlag(item) ? 'info' : 'amber lighten-3'"
                                            @click="ShowEditParametersModal(item)"
                                            :title="ParameterFlag(item) ? 'Изменение параметров/Параметры на обновлении' : 'Изменение параметров'">
                                            <v-icon x-small v-if="ParameterFlag(item)">fa
                                                fa-refresh</v-icon>
                                            <v-icon x-small v-else>fa fa-cog fa-icon-small</v-icon>
                                        </v-btn>
                                        <v-btn x-small v-if="ParameterFlag(item)" icon color="red darken-3"
                                            @click="СancelParametersUpdate(item.id)"
                                            title="Отмена команды обновления параметров">
                                            <v-icon x-small>fa fa-x fa-icon-small</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="pa-2 ">
                                <div class="text-center">Тарировки</div>

                                <div class="d-flex gap-3 align-center justify-space-between">
                                    <div class="flex-grow-1 d-flex flex-wrap justify-center align-center">

                                        <span class="flex-grow-1 text-start">{{ item.calibrationCount != null ?
                                            item.calibrationCount :
                                            '0' }} шт.</span>
                                    </div>
                                    <div class="flex-grow-0" style="cursor:pointer">
                                        <v-btn x-small icon :color="CalibrationFlag(item) ? 'info' : 'primary'"
                                            @click="ShowCalibrationsModal(item)"
                                            :title="CalibrationFlag(item) ? 'Изменение тарировок/Тарировки на обновлении' : 'Изменение тарировок'">
                                            <v-icon x-small v-if="CalibrationFlag(item)">fa
                                                fa-refresh</v-icon>
                                            <v-icon x-small v-else>fa fa-pencil fa-icon-small</v-icon>
                                        </v-btn>
                                        <v-btn x-small v-if="CalibrationFlag(item)" icon color="red darken-3"
                                            @click="СancelCalibrationsUpdate(item.id)"
                                            title="Отмена команды обновления тарировок">
                                            <v-icon x-small>fa fa-x fa-icon-small</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="text-center py-5" v-if="AllDevicesWithDevTypeFiltered.length == 0">


                        <span v-if="DevicesGetHaveError || DeviceTypesGetHaveError" class="red--text">Ошибка загрузки
                            данных</span>
                        <span v-if="DevicesGetAreLoaded && AllDevicesWithDevType.length == 0">Нет устройств</span>
                        <span v-else>Не найдено</span>

                    </div>
                    <v-pagination v-if="AllDevicesWithDevTypeFiltered.length > 10" v-model="page"
                        :length="parseInt( Math.ceil(AllDevicesWithDevTypeFiltered.length / 10))" circle></v-pagination>
                </div>
            </v-card-text>
        </v-card>
        <v-modal ref="EditDeviceModal" scrollable max-width="500" :persistent="persistent">
            <template v-slot:MainModal> <v-card :loading="loading">
                    <v-card-title v-if="!edit" class="text-h6 grey lighten-3">
                        Новое устройство
                    </v-card-title>
                    <v-card-title v-else class="text-h6 grey lighten-3">
                        {{ edit_title }}
                    </v-card-title>
                    <v-card-text class="pt-1 pb-1">
                        <v-form v-model="valid" ref="form_devices" id="form_devices">
                            <v-text-field v-model="edited_item.imei" label="IMEI" placeholder="447402515437650"
                                counter="15" :disabled="loading || edit" required></v-text-field>
                            <v-text-field v-if="edit_type == null || edit_type == 'common_parametrs'"
                                v-model="edited_item.name" :rules="name_rules" label="Наименование"
                                placeholder="Устройство 1" :disabled="loading" required></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary" form="form_devices" depressed @click="CreateOrEditDevice"
                            :disabled="loading || !valid">
                            Сохранить
                        </v-btn>
                        <v-btn color="error" depressed @click="$refs.EditDeviceModal.Hide()" :disabled="loading">
                            Отмена
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="UpdateFirmwareModal" max-width="500" :persistent="persistent"
            loading_text="Загрузка списка прошивок устройства, подождите">
            <template v-slot:MainModal>
                <v-card :loading="loading">
                    <v-card-title class="text-h6 grey lighten-3 flex-column align-start">
                        <b>Обновление ПО</b>{{ edited_item.imei }} {{
                            edited_item.name != null ? `(${edited_item.name})` : '' }}
                    </v-card-title>
                    <v-card-text class="pt-1 pb-1">
                        <v-form v-model="valid" ref="form_firmware" id="form_firmware">
                            <v-alert icon="mdi mdi-alert" class="my-1" dense type="warning"
                                v-if="DeviceHaveFirmwareFlag && FIleInfo == undefined">Устройство
                                ожидает обновления. (<b>Версия: {{ edited_item.newSoftwareVersion != null ?
                                    edited_item.newSoftwareVersion : software.softwareVersion }}</b>)
                            </v-alert>
                            <v-alert icon="mdi mdi-alert" class="my-1" dense type="warning"
                                v-else-if="DeviceHaveFirmwareFlag || FIleInfo != undefined">Устройство
                                обновляется. (<b>Версия:
                                    {{ devices_files.find(el => el.id ==
                                        edited_item.id).software_version
                                    }}</b>)</v-alert>
                            <v-progress-linear v-if="FIleInfo == undefined && DeviceHaveFirmwareFlag" rounded dark
                                style="pointer-events: none" :active="true" height="25">
                                {{ 0 }}%
                            </v-progress-linear>
                            <v-progress-linear v-else-if="FIleInfo != undefined" rounded dark
                                style="pointer-events: none" :active="true" v-model="FIleInfo.value" height="25">
                                {{ Math.ceil(FIleInfo.value)
                                }}%
                            </v-progress-linear>
                            <div><b>Текущая версия: {{ edited_item.softwareVersion }}</b></div>
                            <div v-if="FIleInfo == undefined && !DeviceHaveFirmwareFlag"
                                class="d-flex flex-wrap align-center">
                                <v-autocomplete no-data-text="Нет элементов" item-text="softwareVersion" return-object
                                    :items="firmwares_of_device" :rules="firmware_rules" label="Доступные версии"
                                    placeholder="Выберите прошивку" v-model="software"
                                    :disabled="loading || DeviceHaveFirmwareFlag || FIleInfo != undefined"
                                    required></v-autocomplete>
                            </div>
                            <!--append-icon="fa fa-solid fa-caret-down fa-2xs" clear-icon="fa fa-solid fa-xmark fa-2xs"-->
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn v-if="FIleInfo == undefined && !DeviceHaveFirmwareFlag" color="primary"
                            form="form_firmware" depressed @click="UpdateFirmware()"
                            :disabled="loading || !valid || DeviceHaveFirmwareFlag || FIleInfo != undefined">
                            Обновить
                        </v-btn>
                        <v-btn color="error" depressed @click="() => $refs['UpdateFirmwareModal'].Hide()"
                            :disabled="loading">
                            Закрыть
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="CalibrationFromDeviceModal" max-width="500" :persistent="persistent" havePreloader
            loading_text="Загрузка устройств с тарировками">
            <template v-slot:MainModal>
                <v-card :loading="loading">
                    <v-card-title class="text-h6 grey lighten-3">
                        Получение тарировок
                    </v-card-title>
                    <v-card-text class="pt-5 pb-5">
                        <v-form v-model="valid" ref="form_calibration_from_device" id="form_calibration_from_device">
                            <div class="d-flex flex-wrap align-center">
                                <v-autocomplete no-data-text="Нет элементов"
                                    :item-text="(item) => `${item.imei}${item.name ? ' (' + item.name + ')' : ''}`"
                                    class="pt-0" return-object :items="devices_with_calibrations"
                                    label="Доступные устройства" :rules="calibration_from_device_rules"
                                    placeholder="Выберите IMEI" v-model="selected_device_with_calibrations"
                                    :disabled="loading" required></v-autocomplete>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary" form="form_calibration_from_device" depressed
                            @click="GetCalibrationFromDecive()" :disabled="loading || !valid">
                            Получить
                        </v-btn>
                        <v-btn color="error" depressed @click="$refs.CalibrationFromDeviceModal.Hide()"
                            :disabled="loading">
                            Закрыть
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="ParametersModal" scrollable fullscreen no-click-animation transition="dialog-bottom-transition"
            havePreloader loading_text="Загрузка параметров устройства, подождите" :persistent="persistent">
            <template v-slot:MainModal>
                <v-card tile>
                    <v-toolbar flat style="background:#282652; color:white; max-height:112px"
                        class="toolbar-parameters">
                        <div class="toolbar-container">
                            <v-toolbar-title class="text-8 color-white">
                                <b>Конфигурация устройства</b>
                                <div style="font-size: 13px;"> {{ edited_item?.imei }} {{ edited_item?.name !=
                                    null ?
                                    `(${edited_item?.name})` : '' }}</div>
                                <div style="font-size: 12px;"> Даные от {{
                                    GetLastParamsDate(edited_item?.lastParamsDate) }}
                                </div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="$refs.ParametersModal.Hide()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                        <template v-slot:extension class="parameters-toolbar-extension">
                            <div class="toolbar-container">
                                <v-tabs background-color="rgb(40, 38, 82) !important" dark center-active color="cyan"
                                    show-arrows v-model="tabs_numb">
                                    <v-tabs-slider color="white"></v-tabs-slider>
                                    <v-tab v-for="( tab, index_1 ) in parameters?.tabs " :key="index_1">
                                        {{ tab.name }}
                                    </v-tab>
                                </v-tabs>
                            </div>
                        </template>
                    </v-toolbar>
                    <v-card-text v-if="!ObjectIsEmpty(parameters)" class="mt-1">
                        <v-container class="container-parameters pa-0 ">
                            <v-form v-model="valid" id="form_parameters" ref="form_parameters"
                                @submit.prevent="EditParameters">
                                <v-tabs-items v-model="tabs_numb">
                                    <v-tab-item :eager="true" v-for="( tab, index_2 ) in parameters?.tabs "
                                        :key="index_2" :transition="false">
                                        <v-alert class="my-1" icon="mdi mdi-alert" dense type="warning"
                                            v-if="DeviceHaveParametersFlag">Параметры на
                                            обновлении</v-alert>
                                        <v-alert class="my-1" icon="mdi mdi-alert" dense type="warning"
                                            v-if="role == 'admin' && (parametersError?.hasFail ?? false)">Неизвестные
                                            параметры:
                                            <ul>
                                                <li v-for=" elem in parametersError.unknowParams "
                                                    :key="elem.deviceParameterID">{{
                                                        'ID:' +
                                                        elem.deviceParameterID + `, Value: ` + elem.value }}</li>
                                            </ul>
                                        </v-alert>
                                        <fieldset v-if="group.parameters.length > 0" v-for=" group in tab.groups "
                                            class="py-1">
                                            <legend class="legend-parameters py-1">
                                                {{ group.name }}
                                            </legend>
                                            <hr class="hr-parameters" />
                                            <section v-for=" parameter in group.parameters "
                                                :key="parameter.deviceParameterID">
                                                <!--&& parameter.value != null-->
                                                <v-row no-gutters v-if="parameter.details.parameterType != 'CheckBox'"
                                                    align="baseline">
                                                    <v-col cols=12 md="6">
                                                        {{ parameter.name }}
                                                    </v-col>
                                                    <v-col cols=12 md="6"
                                                        v-if="parameter.details.parameterType == 'Int'">
                                                        <!--:min="parameter.details.minValueInt" :max="parameter.details.maxValueInt" -->
                                                        <v-text-field filled dense :min="parameter.details.minValueInt"
                                                            :max="parameter.details.maxValueInt" type="number"
                                                            v-model.number="parameter.value"
                                                            :rules="TextBoxRules(parameter.value, parameter.details.readOnly, parameter.details.parameterType, parameter.details.maxValueInt, parameter.details.minValueInt)"
                                                            :disabled="parameter.details.readOnly || loading/*_parameters || DeviceHavParametersFlag*/"></v-text-field>
                                                    </v-col>
                                                    <v-col cols=12 md="6"
                                                        v-if="parameter.details.parameterType == 'String'">
                                                        <v-text-field filled dense
                                                            :counter="parameter.details.maxStringLength"
                                                            v-model="parameter.value"
                                                            :rules="TextBoxRules(parameter.value, parameter.details.readOnly, parameter.details.parameterType, parameter.details.maxStringLength)"
                                                            :disabled="parameter.details.readOnly || loading/*_parameters || DeviceHaveParametersFlag*/"></v-text-field>
                                                    </v-col>
                                                    <v-col cols=12 md="6"
                                                        v-if="parameter.details.parameterType == 'ComboBox'">
                                                        <v-autocomplete filled dense no-data-text="Нет элементов"
                                                            item-text="text"
                                                            :rules="parameter.deviceParameterID == 66? [] :ComboBoxRules(parameter.value, parameter.details.readOnly, GetOptionsFromObject(parameter.details.comboBoxOptions))"
                                                            item-value="value"
                                                            :items="GetOptionsFromObject(parameter.details.comboBoxOptions)"
                                                            @change="ComboBoxChange" v-model="parameter.value"
                                                            :disabled="parameter.details.readOnly || loading/*_parameters || DeviceHaveParametersFlag*/"
                                                            placeholder="Выберите элемент из списка"
                                                            required></v-autocomplete>
                                                        <!--СAN_шина-->
                                                        <v-textarea
                                                            v-if="parameter.deviceParameterID == 66 && parameter.value == '1'"
                                                            v-model="CanFileContent" filled rows="10"
                                                            label="Данные CAN-файла" />
                                                    </v-col>
                                                </v-row>
                                                <!--&& parameter.value != null-->
                                                <v-row no-gutters
                                                    v-else-if="parameter.details.parameterType == 'CheckBox'"
                                                    align="baseline" class="flex-column">
                                                    <v-checkbox hide-details
                                                        v-for=" elem in GetCheckBoxesFromObject(parameter.details.checkBoxArray) "
                                                        :key="elem.value" :value="elem.value" :label="elem.text"
                                                        :input-value="checkBoxArray[parameter.deviceParameterID]"
                                                        @change="CheckBoxValueChanged(parameter, elem.value)"
                                                        :disabled="parameter.details.readOnly || loading/*_parameters || DeviceHaveParametersFlag*/"></v-checkbox>
                                                </v-row>
                                            </section>
                                        </fieldset>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-text v-else class="d-flex align-center justify-center mt-1 text-center">
                        <v-container>
                            У устройства нет параметров
                        </v-container>
                    </v-card-text>
                    <v-divider v-if="ObjectIsEmpty(parameters)"></v-divider>
                    <v-card-actions v-if="!ObjectIsEmpty(parameters)">
                        <v-container class="container-parameters pa-0">
                            <v-btn color="primary" type="submit"
                                :disabled="loading/*_parameters || DeviceHaveParametersFlag*/" :loading="loading"
                                form="form_parameters" depressed>
                                Сохранить
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="CalibrationModal" scrollable fullscreen no-click-animation transition="dialog-bottom-transition"
            havePreloader loading_text="Загрузка тарировок устройства, подождите" :persistent="persistent">
            <template v-slot:MainModal>
                <v-card tile>
                    <v-toolbar flat style="background:#282652; color:white; max-height:112px"
                        class="toolbar-parameters">
                        <div class="toolbar-container">
                            <v-toolbar-title>
                                <div class="text-h8 color-white"><b>Тарировки
                                        устройства</b><br>{{ edited_item?.imei }} {{
                                            edited_item?.name != null ? `(${edited_item?.name})` : '' }}</div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn small icon dark title="Принудительное обновление" @click="RefreshFlag()"
                                :disabled="calibrations.length == 0">
                                <v-icon color="white">mdi mdi-refresh</v-icon>
                            </v-btn>
                            <v-btn small icon dark title="От другого устройства"
                                @click="ShowCalibrationFromDeviceModal()">
                                <v-icon color="white">mdi-file-arrow-up-down</v-icon>
                            </v-btn>
                            <v-btn small icon dark @click="$refs.CalibrationModal.Hide()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <!--    <v-btn icon dark @click="AddCalibration()" title="Новая тарировка" :disabled="plus_disabled"> <v-icon>mdi-plus</v-icon></v-btn> -->
                        </div>
                        <template v-slot:extension class="parameters-toolbar-extension">
                            <div class="toolbar-container">
                                <v-tabs background-color="rgb(40, 38, 82) !important" dark center-active color="cyan"
                                    show-arrows v-model="tabs_numb">
                                    <v-tabs-slider color="white"></v-tabs-slider>
                                    <v-tab v-for=" index in 6 " :key="index">
                                        <!--:class="{ 'v-tab-color': CalibrationsBySensor.length > 0 }"-->
                                        {{ CalibrationTextIndex(index) }} <v-badge color="red" offset-y="-3"
                                            :content="CalibrationsBySensorQuantity(index) > 0 ? CalibrationsBySensorQuantity(index) : '0'"></v-badge>
                                    </v-tab>
                                </v-tabs>
                            </div>
                        </template>
                    </v-toolbar>
                    <v-card-text class="mt-1">
                        <v-container class="container-parameters pa-0">
                            <v-form v-model="valid" id="form_calibrations" ref="form_calibrations"
                                @submit.prevent="CreateOrEditCalibration(CalibrationsBySensor[calib_in_sensor_numb])">
                                <v-alert class="my-1" icon="mdi mdi-alert" dense type="warning"
                                    v-if="DeviceHaveCalibrationFlag">Тарировки на обновлении</v-alert>
                                <v-tabs-items v-model="tabs_numb">
                                    <v-tab-item :eager="true" v-for=" index in 6 " :key="index" :transition="false">
                                        <div class="d-flex">
                                            <v-tabs v-if="CalibrationsBySensor.length != 0" center-active show-arrows
                                                v-model="calib_in_sensor_numb">
                                                <!--<v-tabs-slider color="white"></v-tabs-slider>-->
                                                <v-tab v-for="( calibration, index_2 ) in CalibrationsBySensor "
                                                    :key="index_2">
                                                    Тар. №{{ parseInt(index_2) + 1 }}
                                                    <v-icon right v-if="CheckCalibrationActive(index_2)"
                                                        color="success ">mdi-check-bold</v-icon>
                                                </v-tab>
                                            </v-tabs>
                                            <div v-else style="flex:1 auto ; height: 48px"></div>
                                            <div class="px-2 d-flex align-center">
                                                <v-btn icon @click="AddCalibration()"
                                                    title="Новая тарировка для сенсора" :disabled="plus_disabled">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div v-if="CalibrationsBySensor.length != 0">
                                            <v-tabs-items v-model="calib_in_sensor_numb">
                                                <v-tab-item :eager="true"
                                                    v-for=" calibration in CalibrationsBySensor          "
                                                    :key="calibration.id" :transition="false">
                                                    <!-- <div v-for="calibration in CalibrationsBySensor">-->
                                                    <v-row no-gutters align="baseline" v-if="calibration.edit">
                                                        <v-col cols=12 md="6" class="py-2">
                                                            Статус
                                                        </v-col>
                                                        <v-col cols=12 md="6"
                                                            class="d-flex  py-2 justify-space-between flex-wrap">
                                                            <span
                                                                :class="{ 'green--text': calibration.active, 'error--text': !calibration.active }">{{
                                                                    calibration.active ? 'Активна' : 'Не активна' }}
                                                            </span>
                                                            <v-btn v-if="!calibration.active" small
                                                                @click="SetCalibrationActive()" :disabled="loading">
                                                                Акт-ть. </v-btn>
                                                            <v-btn v-else small @click="SetCalibrationDeActive()"
                                                                :disabled="loading">
                                                                Деакт-ть. </v-btn>
                                                        </v-col>
                                                        <v-col cols=6 md="6">
                                                            Дата
                                                        </v-col>
                                                        <v-col cols=6 md="6" class="py-3">
                                                            {{ GetDateFromCalibrationDate(calibration.date)
                                                            }}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters align="baseline">
                                                        <v-col cols=12 md="6">
                                                            Скорость слива (л/мин)
                                                        </v-col>
                                                        <v-col cols=12 md="6">
                                                            <v-text-field filled dense type="number"
                                                                v-model.number="calibration.leakageThreshold"
                                                                :disabled="loading"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols=12 md="6">
                                                            Скорость заправки (л/мин)
                                                        </v-col>
                                                        <v-col cols=12 md="6">
                                                            <!--:min="parameter.details.minValueInt" :max="parameter.details.maxValueInt" -->
                                                            <v-text-field filled dense type="number"
                                                                v-model.number="calibration.fillingthreshold"
                                                                :disabled="loading"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="8" class="pa-0">
                                                            <v-btn outlined class="my-1" small @click="
                                                                ShowDialogCalibration(false)
                                                                " color="green lighten-2">
                                                                <v-icon left>
                                                                    fa fa-plus
                                                                </v-icon>
                                                                Новый уровень
                                                            </v-btn>
                                                            <v-data-table dense fixed-header mobile-breakpoint="0"
                                                                class="table-calibration elevation-0"
                                                                loading-text="Загрузка..." id="calibrationTable"
                                                                :header-props="header_props" :items="calibration.table"
                                                                :footer-props="footer_props_calibration"
                                                                no-results-text="Не найдено"
                                                                :headers="fields_calibration" locale="ru-Ru"
                                                                :items-per-page="-1" hide-default-footer>
                                                                <template v-slot:item.index="{ item, index, props }">
                                                                    {{ index + 1 }}
                                                                </template>
                                                                <template v-slot:item.delete="{ item, index, props }">
                                                                    <div class="justify-center d-flex">
                                                                        <v-btn small color="primary" icon
                                                                            :disabled="calibration.table.length == 1"
                                                                            title="Изменить строку" type="button"
                                                                            @click="ShowDialogCalibration(true, index, item)">
                                                                            <v-icon small> fa fa-pencil fa-icon-small
                                                                            </v-icon></v-btn>
                                                                        <v-btn small color="red darken-3" type="button"
                                                                            icon
                                                                            :disabled="calibration.table.length == 2"
                                                                            title="Удаление показания"
                                                                            @click=" calibration.table.splice(index, 1)"><v-icon
                                                                                small>fa
                                                                                fa-trash
                                                                                fa-icon-small</v-icon></v-btn>
                                                                    </div>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                    <!--</div>-->
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </div>
                                        <div v-else>
                                            <v-container class="d-flex justify-center align-center">
                                                Нет тарировок для сенсора {{ CalibrationTextIndex(tabs_numb + 1) }}.
                                                Добавьте новую
                                            </v-container>
                                        </div>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <!--  <v-card-text class="mt-1 d-flex align-center text-center" v-else>
                        <v-container>
                            Нет тарировок. Добавьте новую
                        </v-container>
                    </v-card-text>-->
                    <v-divider v-if="CalibrationsBySensor != 0"></v-divider>
                    <v-card-actions v-if="CalibrationsBySensor != 0">
                        <v-container class="container-parameters pa-0 d-flex" style="gap:5px">
                            <v-btn
                                :color="CalibrationsBySensor[calib_in_sensor_numb]?.edit ?? false ? 'primary' : 'yellow accent-2'"
                                type="submit" form="form_calibrations" depressed :loading="loading" :disabled="loading">
                                {{ CalibrationsBySensor[calib_in_sensor_numb]?.edit ? 'Сохранить' : 'Cоздать' }}
                            </v-btn>
                            <v-btn color="error" @click="ShowDeleteCalibrationModal()" depressed :disabled="loading">
                                {{ CalibrationsBySensor[calib_in_sensor_numb]?.edit ? 'Удалить' : 'Отменить' }}
                            </v-btn>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="DialogCalibrationModal" width="450px" startDialog>
            <template v-slot:MainModal>
                <v-form form="form_dialog_calib" v-model="valid" id="form_dialog_calib" ref="form_dialog_calib"
                    @submit.prevent="SaveCalibrationLevel()">
                    <v-card>
                        <v-card-title>{{ !edit ? 'Новое значение' : 'Изменить значение ' }}</v-card-title>
                        <v-card-text сlass="pa-0 ma-0">
                            <v-row align="baseline" class="pa-0 ma-0">
                                <v-col cols=6 class="pa-0 ma-0">
                                    Значение уровня
                                </v-col>
                                <v-col cols=6 class="pa-0 ma-0">
                                    <v-text-field type="number" autofocus min="0" v-model="edit_calibration_sensor"
                                        :rules="calibration_sensor_rules" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align="baseline" class="pa-0 ma-0">
                                <v-col cols=6 class="pa-0 ma-0">
                                    Значение объёма
                                </v-col>
                                <v-col cols=6 class="pa-0 ma-0">
                                    <v-text-field type="number" min="0" v-model="edit_calibration_volume"
                                        :rules="calibration_volume_rules" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn type="submit" form="form_dialog_calib" color="primary"
                                :disabled="!VerifyCalibration">ОК</v-btn>
                            <v-btn type="button" color="error"
                                @click=" $refs.DialogCalibrationModal.Hide()">Отмена</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-modal>
        <v-modal ref="DeleteCalibrationModal" max-width="350" :persistent="persistent">
            <template v-slot:MainModal>
                <v-card :loading="loading">
                    <v-card-title class="text-h6 grey lighten-3">
                        {{
                            CalibrationsBySensor[calib_in_sensor_numb]?.edit ? `Удаление тарировки` : `Отмена
                        создания`
                        }}
                    </v-card-title>
                    <v-card-text class="py-1" v-if="CalibrationsBySensor[calib_in_sensor_numb]?.edit">
                        Вы действительно хотите удалить тарировку с id "{{
                            CalibrationsBySensor[calib_in_sensor_numb]?.id ?? '' }}"?
                    </v-card-text>
                    <v-card-text class="py-1" v-else>
                        Вы действительно хотите отменить создание тарировки для сенсора "{{
                            CalibrationsBySensor[calib_in_sensor_numb]?.sensorIdx ?? '' }}"?
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary" depressed @click="DeleteCalibration" :disabled="loading">
                            Да
                        </v-btn>
                        <v-btn color="error" depressed @click="$refs.DeleteCalibrationModal.Hide()" :disabled="loading">
                            Нет
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-modal>
        <v-modal ref="EmptyModal" persistent width="500">
        </v-modal>
        <v-snackbar v-model="snackbar_parameters" right top color="red accent-2">
            Проверьте заполнение полей во вкладках!
        </v-snackbar>
    </div>
</template>
<script>
import {
    cls_center,
    cls_left
} from "@/helpers/const";
import Vue from 'vue'
moment.locale("ru")
import {
    eventBus
} from "@/main.js";
import {
    mapGetters
} from "vuex";

import { DEVICES_GET_BY_ID_REQUEST } from "@/helpers/actions";
export default {
    data() {
        return {
            page: 1,

            //Роль пользователя
            role: "user",
            selected_device_with_calibrations: null,
            devices_files: [],
            parametersError: {},
            edit_calibration_volume: null,
            edit_calibration_sensor: null,
            edit_calibration_index: null,
            snackbar_parameters: false,
            tabs_numb: 0,
            calib_in_sensor_numb: 0,
            valid: true,
            software: null,
            edit_type: null,
            edit_title: null,
            filtered_items: [],
            filter: "",
            /*Столбцы таблицы*/
            footer_props: {
                'items-per-page-all-text': 'Все',
                'items-per-page-text': 'Строк на странице:',
                'items-per-page-options': [
                    50,
                    100,
                    500,
                ],
                'pageText': '{0}-{1} из {2}'
            },
            footer_props_calibration: {
                'items-per-page-all-text': 'Все',
                'items-per-page-text': 'Строк на странице:',
                'pageText': '{0}-{1} из {2}'
            },
            header_props: {
                'sort-by-text': 'Сортировать по'
            },
            fields_calibration: [
                /*{
                                    value: 'index',
                                    class: " grey lighten-3",
                                    align: "center",
                                    text: '№',
                                    cellClass: ["text-center", "align-middle"],
                                    sortable: false
                                },*/
                {
                    value: 'sensor',
                    class: " grey lighten-3",
                    align: "center",
                    text: 'Уровень',
                    cellClass: ["text-start", "align-middle"],
                    sortable: false,
                },
                {
                    value: 'volume',
                    class: " grey lighten-3",
                    align: "center",
                    text: "Литры",
                    cellClass: ["text-start", "align-middle"],
                    sortable: false,
                },
                {
                    value: 'delete',
                    class: " grey lighten-3",
                    align: "center",
                    text: "",
                    cellClass: ["text-start", "align-middle"],
                    width: 10,
                    sortable: false,
                },
                /*   {
                       value: 'editOkey',
                       class: " grey lighten-3",
                       align: "center",
                       text: "",
                       cellClass: ["text-stat", "align-middle"],
                       sortable: false,
                       width: "65px",
                   },*/
            ],
            fields: [/*{
                text: '',
                sortable: false,
                align: "center",
                value: "index",
                cellClass: ["text-center", "align-middle"],
                class: " grey lighten-3",
                width: 75,
                groupable:false,
            },*/
                {
                    text: "Cтатус",
                    align: "center",
                    groupable: false,
                    sortable: false,
                    value: "online",
                    cellClass: ["text-center", "align-middle"],
                    class: "grey lighten-3"
                },
                {
                    text: "ID",
                    align: "center",
                    sortable: true,
                    value: "id",
                    groupable: false,
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3",
                    width: 75,
                },
                {
                    text: "IMEI",
                    align: "center",
                    sortable: true,
                    value: "imei",
                    groupable: false,
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Наименование",
                    align: "center",
                    sortable: true,
                    value: "name",
                    groupable: false,
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Тип",
                    align: "center",
                    groupable: false,
                    sortable: true,
                    value: "deviceTypeName",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Версия АО",
                    align: "center",
                    groupable: true,
                    sortable: true,
                    value: "hardwareVersion",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3",
                    width: 155,
                },
                {
                    text: "Версия ПО",
                    align: "center",
                    groupable: true,
                    sortable: true,
                    value: "softwareVersion",
                    cellClass: ["text-center", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Параметры",
                    align: "center",
                    groupable: false,
                    sortable: false,
                    value: "parameters",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
                {
                    text: "Тарировки",
                    align: "center",
                    groupable: false,
                    sortable: true,
                    value: "calibrationCount",
                    cellClass: ["text-start", "align-middle"],
                    class: " grey lighten-3"
                },
            ],
            edit: false,
            /*Связь с модальным окном*/
            dialog: false,
            /*Данные для  */
            firmwares_of_device: [],
            parameters: {},
            calibrations: [],
            devices_with_calibrations: [],
            CanFileContent: null,
            /*Дефолтные поля*/
            default_item: {
                "id": "",
                "imei": "",
                "deviceTypeID": "",
                "name": "",
                "deviceTypeName": "",
                "hardwareVersion": 0,
                "softwareVersion": 0,
                "userID": "",
                "userName": "",
                "calibrationCount": "",
            },
            edited_item: {
                "id": "",
                "imei": "",
                "deviceTypeID": "",
                "name": "",
                "deviceTypeName": "",
                "hardwareVersion": 0,
                "softwareVersion": 0,
                "userID": "",
                "userName": "",
                "calibrationCount": "",
            },
            /*правила валидации*/
            calibration_from_device_rules: [
                v => typeof v === 'object' && v !== null || 'Поле должно быть заполнено'
            ],
            calibration_volume_rules: [
                v => {
                    if (v == null || v == '') {
                        return "Поле должно быть заполнено"
                    } else {
                        return true
                    }
                },
                v => {
                    if (v && !this.ValidVolumeUnique) {
                        return "Значение объёма не должно повторяться"
                    } else {
                        return true
                    }
                }
            ],
            calibration_sensor_rules: [
                v => {
                    if (v == null || v == '') {
                        return "Поле должно быть заполнено"
                    } else {
                        return true
                    }
                },
                v => {
                    if (v && !this.ValidSensorUnique) {
                        return "Значение уровня не должно повторяться"
                    } else {
                        return true
                    }
                }
            ],
            parameter_comboBox_rules: [v => {
                if (v == null) return "Поле должно быть заполнено";
                else return true
            }],
            parameter_rules: [
                /* v => {
                     if (v == null) return "Поле должно быть заполнено";
                     else return true
                 }*/
            ],
            name_rules: [
                v => !!v || 'Поле должно быть заполнено',
            ],
            firmware_rules: [
                v => typeof v === 'object' && v !== null || 'Поле должно быть заполнено',
            ],
            checkBoxArray: {},
            /*Отчёт о выполнении действия*/
            persistent: false,
            perPage: 100,
            currentPage: 1,
            action: {
                type: null,
                method: null
            },
        };
    },
    computed: {
        AllDevicesWithDevTypeFiltered() {
            var filtered_items;
            if (this.filter.length == 0)
                return this.AllDevicesWithDevType
            else
                return filtered_items = this.AllDevicesWithDevType.filter(a => {
                     if (a.name != null && a.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1) return true;
                    if (a.imei.toLowerCase().indexOf(this.filter.toLowerCase()) > -1) return true;
                    if (a.hardwareVersion.toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) return true;
                    return false;
                }

                )
        },
        DevicePagination() {
            return this.AllDevicesWithDevTypeFiltered.slice((this.page - 1) * 10, this.page * 10);
        },
        FIleInfo() {
            return this.devices_files.find(el => el.id == this.edited_item.id)
        },
        AllDevicesWithDevType() {
            return this.AllDevices.map(a => {
                let itemsArray = Object.assign({}, a);
                itemsArray.deviceTypeName = this.DeviceTypeById(a.deviceTypeID)?.name ?? null;
                itemsArray.hardwareVersion = this.DeviceTypeById(a.deviceTypeID)?.hardwareVersion ?? null
                return itemsArray;
            }
            )
        },
        DeviceHaveFirmwareFlag() {
            if (this.edited_item?.id != "" && this.edited_item?.id != null) {
                return this.AllDevices.find(el => el.id == this.edited_item.id)?.flagUpdateFirware ?? false
            } else return false;
        },
        DeviceHaveCalibrationFlag() {
            if (this.edited_item?.id != "" && this.edited_item?.id != null) {
                return this.AllDevices.find(el => el.id == this.edited_item.id).flagUpdateCalibrations
            } else return false
        },
        DeviceHaveParametersFlag() {
            if (this.edited_item?.id != "" && this.edited_item?.id != null) {
                return this.AllDevices.find(el => el.id == this.edited_item.id).flagUpdateParameters
            } else return false
        },
        plus_disabled() {
            if (this.CalibrationsBySensor.length == 0) return false;
            else {
                if (this.CalibrationsBySensor.find(el => el.edit == false) != undefined) return true;
                else return false;
            }
        },
        CalibrationsBySensor() {
            return this.calibrations.filter(el => el.sensorIdx == parseInt(this.tabs_numb)) ?? []
        },
        VerifyCalibration() {
            if (this.edit_calibration_volume == '' || this.edit_calibration_volume == null) return false;
            if (!this.ValidVolumeUnique || !this.ValidSensorUnique) {
                return false;
            }
            return true
        },
        ValidVolumeUnique() {
            if (this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table.length != 0) {
                for (var i = 0; i < this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table.length; i++)
                    if (i != this.edit_calibration_index && this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table[i].volume == this.edit_calibration_volume) return false;
            }
            return true;
        },
        ValidSensorUnique() {
            if (this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table.length != 0) {
                for (var i = 0; i < this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table.length; i++)
                    if (i != this.edit_calibration_index && this.CalibrationsBySensor[this.calib_in_sensor_numb]?.table[i].sensor == this.edit_calibration_sensor) return false;
            }
            return true;
        },
        GroupsTabs() {
            if (this.parameters && this.parameters != undefined && this.parameters.tabs?.length > 0 && this.tabs_numb != undefined) {
                return this.parameters?.tabs[this.tabs_numb] ?? []
            }
        },
        DevicesTypeSelect() {
            return [{
                id: 0,
                name: "Тип 1"
            },
            {
                id: 1,
                name: "Тип 2"
            },
            {
                id: 2,
                name: "Тип 3"
            },
            ]
        },
        loading() {
            switch (this.action.method) {
                case "get":
                    if (this.action.type === "devices") {
                        return this.DevicesGetAreLoading;
                    }
                    if (this.action.type === "calibrations") {
                        return this.CalibrationsGetAreLoading;
                    }
                    break;
                case "post":
                    if (this.action.type === "devices") {
                        return this.DevicesPostAreLoading;
                    }
                    if (this.action.type === "calibrations") {
                        return this.CalibrationsPostAreLoading;
                    }
                    break;
                case "put":
                    if (this.action.type === "devices") {
                        return this.DevicesPutAreLoading;
                    }
                    if (this.action.type === "parameters") {
                        return this.ParametersPutAreLoading;
                    }
                    if (this.action.type === "firmwares") {
                        return this.FirmwaresPutAreLoading;
                    }
                    if (this.action.type === "calibrations") {
                        return this.CalibrationsPutAreLoading;
                    }
                    break;
                case "delete":
                    if (this.action.type === "devices") {
                        return this.DevicesDeleteAreLoading;
                    }
                    if (this.action.type === "calibrations") {
                        return this.CalibrationsDeleteAreLoading;
                    }
                    break;
                default:
                    return false;
            }
        },
        isBusy() {
            if ((this.DevicesGetHaveError || this.DevicesGetAreLoaded) && (this.DeviceTypesGetAreLoaded || this.DeviceTypesGetHaveError)) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters([
            "AllUsers",
            "UserById",
            "UsersOnceLoaded",
            "UsersGetAreLoading",
            "UsersGetHaveError",
            "UsersGetAreLoaded",
            "UsersPostAreLoading",
            "UsersPostHaveError",
            "UsersPostAreLoaded",
            "UsersPutAreLoading",
            "UsersPutHaveError",
            "UsersPutAreLoaded",
            "UsersDeleteAreLoading",
            "UsersDeleteHaveError",
            "UsersDeleteAreLoaded",
            "AllDevices",
            "DeviceById",
            "DevicesOnceLoaded",
            "DevicesGetAreLoading",
            "DevicesGetHaveError",
            "DevicesGetAreLoaded",
            "DevicesPostAreLoading",
            "DevicesPostHaveError",
            "DevicesPostAreLoaded",
            "DevicesPutAreLoading",
            "DevicesPutHaveError",
            "DevicesPutAreLoaded",
            "DevicesDeleteAreLoading",
            "DevicesDeleteHaveError",
            "DevicesDeleteAreLoaded",
            "AllDeviceTypes",
            "DeviceTypeById",
            "DeviceTypesOnceLoaded",
            "DeviceTypesGetAreLoading",
            "DeviceTypesGetHaveError",
            "DeviceTypesGetAreLoaded",
            "CalibrationsGetAreLoading",
            "CalibrationsGetHaveError",
            "CalibrationsGetAreLoaded",
            "CalibrationsPostAreLoading",
            "CalibrationsPutAreLoading",
            "CalibrationsDeleteAreLoading",
            "ParametersGetAreLoading",
            "ParametersGetHaveError",
            "ParametersGetAreLoaded",
            "ParametersPostAreLoading",
            "ParametersPostHaveError",
            "ParametersPostAreLoaded",
            "ParametersPutAreLoading",
            "ParametersPutHaveError",
            "ParametersPutAreLoaded",
            "ParametersDeleteAreLoading",
            "ParametersDeleteHaveError",
            "ParametersDeleteAreLoaded",
            "FirmwaresByDeviceTypeId",
            "FirmwaresGetAreLoading",
            "FirmwaresGetHaveError",
            "FirmwaresGetAreLoaded",
            "FirmwaresPutAreLoading",
            'UsersByDeviceTypeId',
        ]),
    }, beforeDestroy() {
        eventBus.$off['UpdateParametersAcceptedResponse', 'UpdateFirmwareAcceptedResponse', 'UpdateCalibrationResponse', 'UpdateFirmwareOKResponse', 'UpdateParametersOkResponse', 'UpdateCalibrationOKResponse']
    },
    mounted() {
        var perPage = localStorage.getItem('UserDeviceTablePerPage');
        if (perPage != null) {
            this.perPage = parseInt(perPage)
        }
        else {
            this.perPage = 100;
        }
        /*  eventBus.$on('UpdateParametersAcceptedResponse', device_id => {
              var device = this.AllDevices.find(el => el.id == device_id);
              if (device != undefined) {
                  this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })
              }
          });
          eventBus.$on('UpdateFirmwareAcceptedResponse', device_id => {
              var device = this.AllDevices.find(el => el.id == device_id);
              if (device != undefined) {
                  this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })
              }
          });
          eventBus.$on('UpdateCalibrationResponse', device_id => {
              var device = this.AllDevices.find(el => el.id == device_id);
              if (device != undefined) {
                  this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id })
              }
          });*/
        eventBus.$on('UpdateFirmwareOKResponse', device_id => {

            var i = this.devices_files.findIndex(el => el.id = device_id) ?? -1
            var software_version;
            if (i > -1) {
                software_version = this.devices_files[i].software_version;
                clearTimeout(this.devices_files[i].timeoutId)
                this.devices_files.splice(i, 1);
            }
        });
        eventBus.$on('UpdateParametersOkResponse', device_id => {
            if (this.edited_item.id == device_id)
                this.$store.dispatch(PARAMETERS_GET_REQUEST, device_id).then(resp => {
                    this.parameters = resp.data.paramsModel;
                })
        });
        eventBus.$on('UpdateCalibrationOKResponse', device_id => {
            if (this.edited_item.id == device_id)
                this.$store.dispatch(CALIBRATIONS_GET_REQUEST, device_id).then(resp => {
                    if (resp.data != null) {
                        for (var i = 0; i < resp.data.length; i++) {
                            resp.data[i] = Object.assign({}, resp.data[i], {
                                edit: true
                            })
                        }
                        this.calibrations = resp.data;
                    }
                })
        });
        eventBus.$on('SendFileLengthResponse', device_info => {
            var device = this.AllDevices.find(a => a.id == device_info.id)
            if (device != undefined) {
                device.newSoftwareVersion = device_info.software_version;
            }
            /*  if (this.devices_files.length != 0) {*/
            var current_row = this.devices_files.find(el => el.id == device_info.id)
            if (current_row != undefined) {
                var length = this.devices_files.length;
                clearTimeout(current_row.timeoutId)
                current_row.file_length = device_info.file_length
                current_row.offset = device_info.offset
                current_row.value = parseInt(device_info.offset) * 100 / parseInt(device_info.file_length)
                current_row.timeoutId = setTimeout(() => {
                    this.devices_files.splice(length - 1, 1);
                    this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: device_info.id })
                }, 60000)
            }
            else {
                var length = this.devices_files.length;
                var timeoutId = setTimeout(() => {
                    this.devices_files.splice(length, 1), this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: device_info.id })
                }, 60000)
                this.devices_files.push(Object.assign({}, device_info, { value: parseInt(device_info.offset) * 100 / parseInt(device_info.file_length), timeoutId: timeoutId }))
            }
            /*  }
              else {
                  var timeoutId = setTimeout(() => { this.devices_files.splice(0, 1) }, 60000)
                  this.devices_files.push(Object.assign({}, device_info, { value: parseInt(device_info.offset) * 100 / parseInt(device_info.file_length), timeoutId:timeoutId }))
  
              }*/
        });
    },
    async created() {
        {
            await getUserToken().then(async (resp_1) => {
                if (resp_1?.role == "admin") {
                    this.role = resp_1?.role
                    await getMagicToken().then(async (resp_2) => {
                        if (resp_2) {
                            if (!this.DevicesOnceLoaded)
                                await this.$store.dispatch(DEVICES_GET_REQUEST, "user");
                            if (!this.DeviceTypesOnceLoaded) {
                                await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "user");
                            }
                        }
                    })
                }
                else {
                    if (!this.DevicesOnceLoaded)
                        await this.$store.dispatch(DEVICES_GET_REQUEST, "user");
                    if (!this.DeviceTypesOnceLoaded) {
                        await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST, "user");
                    }
                }
            })
        }
    },
    watch: {
    
        filter() {
            this.page = 1;
        },
        perPage(new_val) {
            localStorage.setItem('UserDeviceTablePerPage', new_val)
        },
        calib_in_sensor_numb(new_val) {
            if (new_val == undefined) {
                new_val = 0;
            }
        },
        parameters(new_val) {
            if (!this.ObjectIsEmpty(new_val)) {
                var array_chekBox_parameters, items, parameter_id, value;
                var selected_array = [];
                for (var i = 0; i < new_val.tabs.length; i++) {
                    {
                        for (var j = 0; j < new_val.tabs[i].groups.length; j++) {
                            array_chekBox_parameters = new_val.tabs[i].groups[j]?.parameters?.filter(el => el.details.parameterType == "CheckBox") ?? [];
                            selected_array = [];
                            for (var k = 0; k < array_chekBox_parameters.length; k++) {
                                items = array_chekBox_parameters[k].details.checkBoxArray
                                parameter_id = array_chekBox_parameters[k].deviceParameterID
                                value = array_chekBox_parameters[k].value;
                                for (var k of Object.keys(items)) {
                                    if (parseInt(parseInt(k) & parseInt(value)) > 0) {
                                        selected_array.push(k)
                                    }
                                }
                                if (this.checkBoxArray) {
                                    this.checkBoxArray[parameter_id] = selected_array
                                } else {
                                    this.checkBoxArray = {
                                        [parameter_id]: selected_array
                                    }
                                }
                            }
                        }
                    }
                }
                if (this.$refs.ParametersModal.show_preloader)
                    this.$refs.ParametersModal.ClosePreloader();
            }
        }
    },
    methods: {
        ChangeSelectInMobileHeader(event, on) {
            on.sort(event)
        },
        ComboBoxChange(event) {
            if (event.deviceParameterID == 66) {
                if (event.value == 1) {
                    this.CanFileContent = this.edited_item.dataCAN;
                }
                else this.CanFileContent = null;
            }
        },
        GetLastParamsDate(date) {
            return moment(date).format('DD.MM.YY/HH:mm:ss')
        },
        TextBoxRules(value, readOnly, type, maxVal, minVal) {
            if (readOnly) return [true];
            if (type == "Int") {
                if (parseInt(value) <= parseInt(maxVal) && parseInt(value) >= parseInt(minVal)) return [true]
                else return [`Значение должно находиться в диапазоне [${minVal}..${maxVal}]`]
            }
            if (type == "String") {
                if (value.length <= parseInt(maxVal)) return [true]
                else return [`Количество символов должно быть меньше или равно ${maxVal}`]
            }
        },
        ComboBoxRules(value, readOnly, items) {
            if (items.find(a => a.value == value) != undefined || readOnly) return [true]
            return [`Выбранного значения параметра не существует в структуре! Значение: ${value}`];
        },
        isNumber(num) {
            return typeof num === 'number' && !Number.isNaN(num);
        },
        /* deviceTableSort(items, index, isDesc) {
             return items.sort((a, b) => {
                 if (index[0] == 'hardwareVersion') {
                     var elemA = this.DeviceTypeById(a.deviceTypeID)?.hardwareVersion;
                     var elemB = this.DeviceTypeById(b.deviceTypeID)?.hardwareVersion;
                     if (!isDesc[0]) {
                         return elemA - elemB
                     }
                     else {
                         return elemB - elemA;
                     }
                 }
                 else if (index[0] == 'deviceTypeName') {
                     var elemA = this.DeviceTypeById(a.deviceTypeID)?.name;
                     var elemB = this.DeviceTypeById(b.deviceTypeID)?.name;
                     if (!isDesc[0]) {
                         return elemA < elemB ? -1 : 1;
                     }
                     else {
                         return elemB < elemA ? -1 : 1;
                     }
                 }
                 else if (index[0] == 'userName') {
                     if (a[index[0]] === null) {
                         return -1;
                     }
                     if (b[index[0]] === null) {
                         return 1;
                     }
                     if (!isDesc[0]) {
                         return (a[index[0]] < b[index[0]]) ? -1 : 1;
                     } else {
                         return (b[index[0]] < a[index[0]]) ? -1 : 1;
                     }
                 }
                 else if ((this.isNumber(a[index[0]]))) {
                     if (a[index[0]] === null) {
                         return 1;
                     }
                     if (b[index[0]] === null) {
                         return -1;
                     }
                     if (!isDesc[0]) {
                         return (a[index[0]] - b[index[0]]);
                     } else {
                         return (b[index[0]] - a[index[0]]);
                     }
                 } else {
                     if (a[index[0]] === null) {
                         return 1;
                     }
                     if (b[index[0]] === null) {
                         return -1;
                     }
                     if (!isDesc[0]) {
                         return (a[index[0]] < b[index[0]]) ? -1 : 1;
                     } else {
                         return (b[index[0]] < a[index[0]]) ? -1 : 1;
                     }
                 }
             });
             return items;
         },*/
        ShowDialogCalibration(edit, index, item) {
            if (edit) {
                this.edit = true;
                this.edit_calibration_index = index;
                this.edit_calibration_volume = parseInt(item.volume);
                this.edit_calibration_sensor = parseInt(item.sensor)
                this.$refs.DialogCalibrationModal.Show();
            }
            else {
                this.edit = false;
                this.edit_calibration_volume = '';
                this.edit_calibration_sensor = '';
                this.$refs.DialogCalibrationModal.Show();
            }
            this.$nextTick(() => this.$refs.form_dialog_calib.resetValidation())

        },
        СancelFirmwareUpdate(device_id) {
            this.$store.dispatch(CANCEL_FIRMWARE_UPDATE_PUT_REQUEST, device_id).then().catch(err => {
                err = "Ошибка отмены команды обновления ПО"
                this.$refs.EmptyModal.Error(err);
            })
        },
        СancelParametersUpdate(device_id) {
            this.$store.dispatch(CANCEL_PATAMETERS_UPDATE_PUT_REQUEST, device_id).then().catch(err => {
                err = "Ошибка отмены команды обновления парметров"
                this.$refs.EmptyModal.Error(err);
            })
        },
        СancelCalibrationsUpdate(device_id) {
            this.$store.dispatch(CANCEL_CALIBRATION_ACTIVE_PUT_REQUEST, device_id).then().catch(err => {
                err = "Ошибка отмены команды обновления тарировок"
                this.$refs.EmptyModal.Error(err);
            })
        },
        CheckCalibrationActive(index) {
            return this.CalibrationsBySensor[index]?.active ?? false
        },
        ShowCalibrationFromDeviceModal() {
            this.selected_device_with_calibrations = null;
            this.$refs.CalibrationFromDeviceModal.Show();
            this.$store.dispatch(DEVICES_WTIH_CALIBRATIONS_REQUEST).then(
                resp => {
                    this.devices_with_calibrations = resp.data;
                    this.$nextTick(() => this.$refs.CalibrationFromDeviceModal.ClosePreloader());
                }
            )
                .catch(err => {
                    err = "Ошибка загрузки устройств с тарировками"
                    this.$refs.CalibrationFromDeviceModal.Error(err);
                })
        },
        GetCalibrationFromDecive() {
            this.action = {
                type: "calibrations",
                method: "post"
            }
            this.$store.dispatch(CALIBRATIONS_POST_FROM_TO_REQUEST, {
                from_device_id: this.selected_device_with_calibrations.id,
                to_device_id: this.edited_item.id,
            }).then(resp => {
                /* this.plus_disabled=false;*/
                if (resp.data != null) {
                    for (var i = 0; i < resp.data.length; i++) {
                        resp.data[i] = Object.assign({}, resp.data[i], {
                            edit: true
                        })
                        /* for(var j=0; j<this.calibrations[i].table.length; j++)
                         this.calibrations[i].table[j]=Object.assign({}, this.calibrations[i].table[j],{dialog_calibration:false})*/
                    }
                    this.calibrations = resp.data;
                }
                this.$refs.CalibrationFromDeviceModal.Success();
                this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: this.edited_item.id });
            }).catch(err => {
                this.$refs.CalibrationFromDeviceModal.Error(err);
            })
        },
        CalibrationFlag(item) {
            return item.flagUpdateCalibrations
        },
        FirmwareFlag(item) {
            return item.flagUpdateFirware
        },
        ParameterFlag(item) {
            return item.flagUpdateParameters
        },
        OffTime(elem) {
            //window.momentDurationFormatSetup();
            var now = moment();
            var before = moment(elem);
            var text = moment.duration(now.diff(before)).format('YYYY[ л.] D[ дн.] H[ ч.] m[ м.] s[ с.]');
            var text1 = "Выкл. " + text + ". c" + before.format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        OnlineTime(elem) {
            var text1 = "Активен c" + moment(elem).format(" DD.MM.YY/HH:mm:ss");
            return text1;
        },
        GetDateFromCalibrationDate(date) {
            return moment(date).format("lll");
        },
        CalibrationsBySensorQuantity(index) {
            return this.calibrations.filter(el => el.sensorIdx == (parseInt(index) - 1))?.length ?? 0
        },
        CalibrationTextIndex(index) {
            switch (parseInt(index)) {
                case 1:
                    return "0 LSS (485)"
                case 2:
                    return "1 LSS (485)"
                case 3:
                    return "2 LSS (485)"
                case 4:
                    return "3 LSS (485)"
                case 5:
                    return "0 LSS (част.)"
                case 6:
                    return "1 LSS (част.)"
            }
        },
        async SetCalibrationActive() {
            this.action = {
                type: "calibrations",
                method: "put"
            }
            await this.$store.dispatch(CALIBRATIONS_PUT_ACTIVE_REQUEST, this.CalibrationsBySensor[this.calib_in_sensor_numb].id).then((resp) => {
                if (resp.data != null) {
                    for (var i = 0; i < resp.data.length; i++) {
                        resp.data[i] = Object.assign({}, resp.data[i], {
                            edit: true
                        })
                        /* for(var j=0; j<this.calibrations[i].table.length; j++)
                         this.calibrations[i].table[j]=Object.assign({}, this.calibrations[i].table[j],{dialog_calibration:false})*/
                    }
                    this.calibrations = resp.data;
                    this.AllDevices.find(el => el.id == this.edited_item.id).flagUpdateCalibrations = true;
                }
                this.$refs.EmptyModal.Success()
            }).catch(err => {
                this.$refs.EmptyModal.Error(err);
            })
        },
        async SetCalibrationDeActive() {
            this.action = {
                type: "calibrations",
                method: "put"
            }
            await this.$store.dispatch(CALIBRATIONS_PUT_DEACTIVE_REQUEST, this.CalibrationsBySensor[this.calib_in_sensor_numb].id).then((resp) => {
                if (resp.data != null) {
                    for (var i = 0; i < resp.data.length; i++) {
                        resp.data[i] = Object.assign({}, resp.data[i], {
                            edit: true
                        })
                        /* for(var j=0; j<this.calibrations[i].table.length; j++)
                         this.calibrations[i].table[j]=Object.assign({}, this.calibrations[i].table[j],{dialog_calibration:false})*/
                    }
                    this.calibrations = resp.data;
                    this.AllDevices.find(el => el.id == this.edited_item.id).flagUpdateCalibrations = true;
                }
                this.$refs.EmptyModal.Success()
            }).catch(err => {
                this.$refs.EmptyModal.Error(err);
            })
        },
        async RefreshFlag() {
            await this.$store.dispatch(CALIBRATIONS_GET_REFRESH_REQUEST, this.edited_item.id).then(() => { this.$refs.EmptyModal.Success() }).catch(err => {
                this.$refs.EmptyModal.Error(err);
            })
        },
        AddCalibration() {
            //    this.plus_disabled=true;
            this.calibrations.push({
                "table": [/*{
                    "sensor": 0,
                    "volume": 0
                },
                {
                    "sensor": 4095,
                    "volume": 10000
                },
                */],
                "fillingthreshold": 0,
                "leakageThreshold": 0,
                "sensorIdx": this.tabs_numb,
                "active": false,
                edit: false
            },
            )
            this.$nextTick(() => this.calib_in_sensor_numb += 1)
        },
        ShowDeleteCalibrationModal() {
            this.$refs.DeleteCalibrationModal.Show()
        },
        async DeleteCalibration() {
            this.persistent = true;
            this.action = {
                type: "calibrations",
                method: "delete"
            }
            if (this.CalibrationsBySensor[this.calib_in_sensor_numb]?.edit) {
                await this.$store.dispatch(CALIBRATIONS_DELETE_REQUEST, this.CalibrationsBySensor[this.calib_in_sensor_numb]?.id).then((resp) => {
                    var index = this.calibrations.findIndex(el => el.id == this.CalibrationsBySensor[this.calib_in_sensor_numb]?.id) ?? -1
                    if (index > -1) {
                        this.calibrations.splice(index, 1)
                    }
                    this.$refs.DeleteCalibrationModal.Success()
                }).catch(err => {
                    this.$refs.DeleteCalibrationModal.Error(err)
                })
            }
            else {
                var index = this.calibrations.findIndex(el => el.id == this.CalibrationsBySensor[this.calib_in_sensor_numb]?.id) ?? -1
                if (index > -1) {
                    this.calibrations.splice(index, 1)
                }
            }
            this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: this.edited_item.id })
            this.$refs.DeleteCalibrationModal.Hide()
        },
        async CreateOrEditCalibration(item) {
            if (item.edit) {
                this.action = {
                    type: "calibrations",
                    method: "put"
                }
                this.$store.dispatch(CALIBRATIONS_PUT_REQUEST, {
                    device_id: this.edited_item.id,
                    calibration: item
                }).then(resp => {
                    var calib = Object.assign({}, resp.data, {
                        edit: true
                    });
                    var index = this.calibrations.findIndex(a => a.edit == false && a.sensorIdx == parseInt(this.tabs_numb)) ?? -1;
                    if (index > -1) {
                        this.calibrations.splice(index, 1, calib)
                    }
                    this.$refs.EmptyModal.Success()
                }).catch(err => {
                    this.$refs.EmptyModal.Error(err);
                })
            } else {
                this.action = {
                    type: "calibrations",
                    method: "post"
                }
                this.$store.dispatch(CALIBRATIONS_POST_REQUEST, {
                    device_id: this.edited_item.id,
                    calibration: item
                }).then(resp => {
                    /* this.plus_disabled=false;*/
                    var calib = Object.assign({}, resp.data, {
                        edit: true
                    });
                    var index = this.calibrations.findIndex(a => a.edit == false && a.sensorIdx == parseInt(this.tabs_numb)) ?? -1;
                    if (index > -1) {
                        this.calibrations.splice(index, 1, calib)
                    }
                    this.$refs.EmptyModal.Success()
                }).catch(err => {
                    this.$refs.EmptyModal.Error(err);
                })
            }
            this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: this.edited_item.id })
        },
        SaveCalibrationLevel() {
            if (this.edit) {
                this.CalibrationsBySensor[this.calib_in_sensor_numb].table[this.edit_calibration_index].sensor = this.edit_calibration_sensor;
                this.CalibrationsBySensor[this.calib_in_sensor_numb].table[this.edit_calibration_index].volume = this.edit_calibration_volume;
            } else {
                this.CalibrationsBySensor[this.calib_in_sensor_numb].table.push({
                    sensor: this.edit_calibration_sensor,
                    volume: this.edit_calibration_volume
                })
            }
            this.CalibrationsBySensor[this.calib_in_sensor_numb].table.sort(function (a, b) {
                return parseInt(a.sensor) - parseInt(b.sensor);
            });
            /*for (var i = 0; i < this.CalibrationsBySensor[this.calib_in_sensor_numb].table.length; i++) {
                this.CalibrationsBySensor[this.calib_in_sensor_numb].table[i].sensor = parseInt(i);
            }*/
            this.$refs.DialogCalibrationModal.Hide();
        },
        ObjectIsEmpty(obj) {
            for (var i in obj) {
                if (obj[i].length == 0) return true;
                return false;
            }
            return true;
        },
        CheckBoxValueChanged(item, value) {

            var k = this.checkBoxArray[item.deviceParameterID].findIndex(a => a == value) ?? -1
            if (k > -1) {
                this.checkBoxArray[item.deviceParameterID].splice(k, 1)
            }
            else { this.checkBoxArray[item.deviceParameterID].push(value); }
            var sum = 0;
            for (var i = 0; i < this.checkBoxArray[item.deviceParameterID].length; i++) {
                sum = parseInt(sum + parseInt(this.checkBoxArray[item.deviceParameterID][i]));
            }
            item.value = sum;
        },
        GetCheckBoxesFromObject(item) {
            if (item == undefined) return [];
            var check_boxes = [];
            for (var k of Object.keys(item)) {
                check_boxes.push({
                    text: item[k],
                    value: k
                })
            }
            // this.checkBoxArray={[parameter_id]:selected_array}
            /*
        if(this.checkBoxArray){
            if(this.checkBoxArray[parameter_id]!=undefined) this.checkBoxArray[parameter_id]=selected_array;
            else Object.assign({},this.checkBoxArray,{[parameter_id]:selected_array})
        }
        else this.checkBoxArray={[parameter_id]:selected_array}
            */
            return check_boxes
        },
        GetOptionsFromObject(item) {
            if (item == undefined) return [];
            var options = [];
            for (var k of Object.keys(item)) {
                options.push({
                    text: item[k],
                    value: k
                })
            }
            return options
        },
        async Refresh() {
            await getUserToken().then(async (resp_1) => {
                if (resp_1?.role == "admin")
                    await getMagicToken().then(async (resp_2) => {
                        if (resp_2) {
                            await this.$store.dispatch(DEVICES_GET_REQUEST,
                                "user"
                            );
                            await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST,
                                "user"
                            );
                        }
                    })
                else {
                    await this.$store.dispatch(DEVICES_GET_REQUEST, "user");
                    await this.$store.dispatch(DEVICE_TYPES_GET_REQUEST,
                        "user"
                    );
                }
            })
        },
        ShowCalibrationsModal(item) {
            this.parameters = {};
            this.calibrations = [];
            this.edited_item = Object.assign({}, item)
            this.$nextTick(() => {
                this.calib_in_sensor_numb = 0;
                this.tabs_numb = 0;
            })
            this.$refs.CalibrationModal.Show();
            this.$store.dispatch(CALIBRATIONS_GET_REQUEST, this.edited_item.id).then(resp => {
                if (resp.data != null) {
                    resp.data = resp.data.sort(function (a, b) {
                        return parseInt(a?.sensorIdx) - parseInt(b?.sensorIdx)
                    });
                    if (resp.data.length > 0) {
                        this.tabs_numb = resp.data[0]?.sensorIdx ?? 0;
                    }
                    for (var i = 0; i < resp.data.length; i++) {
                        resp.data[i] = Object.assign({}, resp.data[i], {
                            edit: true
                        })
                        /* for(var j=0; j<this.calibrations[i].table.length; j++)
                         this.calibrations[i].table[j]=Object.assign({}, this.calibrations[i].table[j],{dialog_calibration:false})*/
                    }
                    this.calibrations = resp.data;
                }
                //resp.data;
                this.$refs.CalibrationModal.ClosePreloader();
            }).catch(err => {
                err =
                    "Ошибка загрузки тарировок устройства"
                this.$refs.CalibrationModal.Error(err);
                //   this.$refs.EmptyModal.Error(err);
            })
        },
        ShowEditParametersModal(item) {
            this.calibrations = [];
            this.parameters = {};
            this.parametersError = {};
            this.$nextTick(() => {
                this.calib_in_sensor_numb = 0;
                this.tabs_numb = 0;
            })
            this.edited_item = Object.assign({}, item)
            this.CanFileContent = item.dataCAN == null ? item.dataCAN : JSON.stringify(JSON.parse(item.dataCAN), null, 4);
            this.$refs.ParametersModal.Show();
            this.$store.dispatch(PARAMETERS_GET_REQUEST, this.edited_item.id).then(resp => {
                this.parameters = resp.data.paramsModel;
                this.parametersError = resp.data.deviceParametersError;
                if (resp.data.paramsModel.tabs.length == 0) {
                    this.$refs.ParametersModal.ClosePreloader();
                }
            }).catch(err => {
                this.$refs.ParametersModal.Error(err);
                //this.$refs.EmptyModal.Error(err);
            })
        },
        async EditParameters() {
            if (this.$refs.form_parameters.validate()) {
                var params_data = [];
                for (var i = 0; i < this.parameters.tabs.length; i++) {
                    var tab = this.parameters.tabs[i];
                    for (var j = 0; j < tab.groups.length; j++) {
                        var group = tab.groups[j];
                        for (var k = 0; k < group.parameters.length; k++) {
                            var parameter = group.parameters[k]
                            var value = parameter.value != null ? parameter.value.toString() : "";
                            params_data.push({
                                deviceParameterID: parameter.deviceParameterID,
                                value: value
                            })
                        }
                    }
                }
                this.action = {
                    type: "parameters",
                    method: "put"
                }
                //this.loading_parameters = true;
                await this.$store.dispatch(PARAMETERS_PUT_REQUEST, {
                    device_id: this.edited_item.id,
                    parameters: params_data
                }).then(resp => {
                    this.$store.dispatch(CAN_FILE_PUT_REQUEST, { dataCAN: JSON.stringify(this.CanFileContent), device_id: this.edited_item.id });
                    this.AllDevices.find(el => el.id == this.edited_item.id).flagUpdateParameters = true;
                    this.$refs.EmptyModal.Success()
                }).catch(err => {
                    //this.loading_parameters = false;
                    this.$refs.EmptyModal.Error(err);
                })
            } else {
                this.snackbar_parameters = true;
            }
        },
        ShowUpdateFirmwareModal(item) {
            this.edit = true;
            this.edited_item = Object.assign({}, item);
            this.$refs["UpdateFirmwareModal"].Show();
            this.$store.dispatch(FIRMWARES_GET_BY_DEVICE_REQUEST, this.edited_item.id).then((resp) => {
                this.firmwares_of_device = resp.data;
                this.software =/* this.firmwares_of_device.find(el => el.softwareVersion == this.edited_item.softwareVersion) ??*/ null;
                this.$refs["UpdateFirmwareModal"].ClosePreloader();
                if (this.$refs.form_firmware) this.$refs.form_firmware.resetValidation()
            }).catch(err => {
                this.firmwares_of_device = [];
                this.$refs["UpdateFirmwareModal"].Error(err);
            })
        },
        async UpdateFirmware() {
            this.persistent = true;
            if (this.edit) {
                this.action = {
                    type: "firmwares",
                    method: "put"
                };
                await this.$store.dispatch(FIRMWARES_UPDATE_PUT_REQUEST, {
                    device_id: this.edited_item.id,
                    filename: this.software.filename
                }).then(() => {
                    this.$store.dispatch(DEVICES_GET_BY_ID_REQUEST, { type: "user", device_id: this.edited_item.id });
                }).catch(err => {
                    this.$refs.UpdateFirmwareModal.Error(err)
                })
            }
        },
        ShowEditDeviceModal(item, type) {
            this.edit_type = type;
            switch (type) {
                case "common_parametrs":
                    this.edit_title = "Общие параметры";
                    break;
                case "device_type":
                    this.edit_title = "Тип устройства";
                    break;
                case "user_device":
                    this.edit_title = "Пользователь устройства";
                    break;
                default:
                    break;
            }
            this.edit = true;
            this.edited_item = Object.assign({}, item)
            this.$refs.EditDeviceModal.Show();
            if (this.$refs.form_devices) this.$refs.form_devices.resetValidation()
        },
        async CreateOrEditDevice() {
            this.persistent = true;
            if (this.edit) {
                this.action = {
                    type: "devices",
                    method: "put"
                };
                if (this.edit_type == "common_parametrs") {
                    await this.$store.dispatch(DEVICES_PUT_REQUEST, this.edited_item).then(() => {
                        this.$refs.EditDeviceModal.Success()
                    }).catch(err => {
                        this.$refs.EditDeviceModal.Error(err)
                    })
                }
            }
        },
    }
}
</script>
<style>
fieldset {
    border: none;
}

/**/
/*MODAL PARAMETERS*/
.toolbar-parameters .v-tabs-bar {
    background-color: none !important;
}

@media (min-width: 1904px) {
    .container-parameters {
        max-width: 750px !important;
    }
}

@media (min-width: 1264px) {
    .container-parameters {
        max-width: 750px !important;
    }
}

@media (min-width: 960px) {
    .container-parameters {
        max-width: 750px !important;
    }
}

.container-parameters .row+.row {
    margin-top: 0 !important;
}

.container-parameters .col {
    padding: 0 !important;
}

.container-parameters .row {
    margin: 0 !important;
}

.container-parameters .v-text-field {
    padding: 0 !important;
}

.container-parameters .hr-parameters {
    width: 30%;
    /*height: 2.3px;*/
    background: rgb(40, 38, 82);
    border-radius: 10px;
}

.container-parameters .legend-parameters {
    height: 2.3px;
    color: rgb(40, 38, 82);
    border-radius: 10px;
}

.container-parameters .v-input--selection-controls {
    padding-top: 4px;
    margin-top: 0 !important;
}

.container-parameters {
    padding: 0 !important;
}

/*MODAL PARAMETERS*/
.terminals-panel {

    border: 1px solid rgb(212 212 212)
}

.terminals-panel .col+.col {
    border-left: 1px solid rgb(212 212 212)
}

.terminals-panel .row+.row {
    border-top: 1px solid rgb(212 212 212)
}
</style>
