import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/Login/LoginView.vue'
import AdminUsersView from '@/views/Admin/AdminUsers/AdminUsersView.vue'
import AdminDevicesView from '@/views/Admin/AdminDevices/AdminDevicesView.vue'
import AdminFirmwaresView from '@/views/Admin/AdminFirmwares/AdminFirmaresView.vue'


import UserDevicesView from '@/views/User/UserDevices/UserDevicesView.vue'



import AdminLoggerView from '@/views/Admin/Logger/AdminLoggerView'
import UserLoggerView from '@/views/User/Logger/UserLoggerView'
import Notfound from '@/views/404View'
import store from '@/store'

Vue.use(VueRouter)
const routes = [

  {
    path: '/autorization/login', name: 'Login', component: LoginView,
  },
  {
    path: '/admin', redirect: { name: "AdminDevices" }, name: "Admin"
  },

  {
    path: '/user', redirect: { name: "UserDevices" }, name: "User"
  }, {
    path: '*', component: Notfound
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'admin/users', name: 'AdminUsers', component: AdminUsersView
      },
      {
        path: 'admin/devices', name: 'AdminDevices', component: AdminDevicesView
      },
      {
        path: 'admin/firmwares', name: 'AdminFirmwares', component: AdminFirmwaresView
      },
      {
        path: 'admin/logger', name: 'AdminLogger', component: AdminLoggerView
      },
      {
        path: 'user/devices', name: 'UserDevices', component: UserDevicesView
      },
      {
        path: 'user/logger', name: 'UserLogger', component: UserLoggerView
      },

      /*{
        path: '', name: 'Admin', component: Admin, redirect: { name: 'Admin_Users' },
        children:
          [
            { path: 'users', name: 'AdminUsers', component: AdminUsersView },
            { path: 'cards', name: 'AdminDevices', component: AdminDevicesView }

          ]
      },*/

    ]
  },
]
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
//component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
const router = new VueRouter({
  mode: "history",
  routes
})



router.afterEach(async (to, from) => {
  if (from.fullPath.indexOf("admin") == 1 && to.fullPath.indexOf("user") == 1) location.reload();
  if (from.fullPath.indexOf("user") == 1 && to.fullPath.indexOf("admin") == 1) location.reload();

})

router.beforeEach(async (to, from, next) => {

  //Vue.use(VeeValidate); // good to go.


  if (await isAuthenticated()) {
    if ((to.name == "home") && await hasPermissionsNeed() == "admin") { await next({ path: '/admin' }) }
    if ((to.name == "home") && await hasPermissionsNeed() == "user") { await next({ path: '/user' }) }

    if ((to.name == "AdminUsers") && await hasPermissionsNeed() == "user") { store.dispatch(AUTH_LOGOUT) }
    else if ((to.name == "UserDevices") && await hasPermissionsNeed() == "admin") {

      if (await hasMagicToken()) {

        await next()
      }
      else { await next({ path: '/admin' }) }



    }
    else if (to.name == "Login" && await hasPermissionsNeed() == "user") { await next({ path: '/user' }) }
    else if (to.name == "Login" && await hasPermissionsNeed() == "admin") { await next({ path: '/admin' }) }
    else await next()
  }
  else {
    if (to.name == "Login") {
      await next();
    }
    else {
      await next({ name: 'Login' });
    }
  }

})

async function hasMagicToken() {

  var flag = false;
  await getMagicToken().then((elem) => { if (elem) { flag = true }; })
  return flag;
}
/*
router.beforeEach(async (to, from, next) => {


if (await isAuthenticated()) {
    if (to.name == "Login" && await hasPermissionsNeed(to)=="user") { await next({ path: '/' }) }
    // if (await hasPermissionsNeed(to) == false) {  }
    else next()
} else {
    if (to.name == "Login"  || to.name=="SuperLogin") {
        await next();
    }
    else {
        await next({ name: 'Login' });
    }
}

})
*/

async function isAuthenticated() {
  let flag = false;
  await getUserToken().then((elem) => { if (elem) { flag = true }; })
  return flag
  // return false
  //.catch(err=>{return false;})

}
async function hasPermissionsNeed() {
  var role;
  await getUserToken().then(elem => { role = elem?.role; });
  //    if (param.path.split('/')[2] != role || param.path.split('/').length < 3) { return false; }
  // else true;
  return role
}



export default router
