<template>
    <v-card-text>
        <div v-if="!error" class="col-12 mb-6">
            <div class="row mb-n7 align-items-center">
                <div class="col-12">
                    <div class="sweet-alert">
                        <div class="icon success animate" style="/* display: none; */">
                            <span class="line tip animateSuccessTip"></span>
                            <span class="line long animateSuccessLong"></span>
                            <div class="placeholder"></div>
                            <div class="fix"></div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="h2 text-center"> Сохранено</div>
                </div>
            </div>
        </div>
        <div v-else class="col-12 mb-6">
            <div class="row mb-n11 align-items-center">
                <div class="col-12 align-self-center justify-self-center">
                    <div class="sweet-alert">
                        <div class="icon error animateErrorIcon" style="/* display: none; */">
                            <span class="x-mark animateXMark">
                                <span class="line left"></span>
                                <span class="line right"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="position-relative" style="top:-20px">
                    <div class="col-12">
                        <div class="h2 text-center">Ошибка</div>
                    </div>
                    <div class="col-12 text-center" style="font-size: 20px">
                        {{ error_text }}
                    </div>
                </div>
            </div>
        </div>
    </v-card-text>
</template>
<script>
import {
    VCard
} from 'vuetify/lib'
export default {
    props: {
        error_text: {
            default: "Ошибка"
        },
        error: {
            type: Boolean,
            default: false
        }
    },
}
</script>
