<template>
    <v-main>
        <v-app-bar class="justify-space-between" app elevation=0 style="background-color: var(--v-main-color)">
            <v-toolbar-title class="d-flex align-center"> <v-img max-height="40" class="me-3" max-width="40"
                    src="@/assets/images/logo/logo.png"></v-img>
                <div class="text-h5  font-weight-bold">WebConfig AMS



                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-alert :value="GetHttpErrorStatus != null" dismissible class="ma-0" type="error"><strong> {{
                GetHttpErrorStatusText }}</strong>
            </v-alert>

        </v-app-bar>
        <v-container fluid fill-height class="justify-center py-0">

            <!--  <div class="col-12 mb-6 position-absolute">
                <v-alert :show="GetHttpErrorStatus == 401" dismissible variant="danger">-->



            <div class="signin__container">
                <v-avatar size='40' class='avatar' color='#202442'>
                    <v-icon dark>mdi-account</v-icon>
                </v-avatar>
                <!-- Login Form Start -->
                <v-card elevation="2" shaped class="card-login">
                    <v-card-title>
                        <v-layout align-center justify-center>
                            <div class="text-h4 ">Авторизация</div>
                        </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="px-3 py-1">
                        <v-form ref="formLogin" v-model="valid">
                            <div>
                                <v-text-field class="mt-0 login-field"
                                    :hint="login_error ? 'Неверный логин/или пароль' : ''" persistent-hint
                                    prepend-icon="mdi-account" persistent-placeholder :rules="loginRules"
                                    @focus="Clear_errors" id="username" name="login" v-model="login" 
                                    placeholder="Введите логин...." type="text"></v-text-field>
                      
                            </div>
                            <div>
                                <v-text-field :type="passwodType" persistent-placeholder :rules="passwordRules"
                                    prepend-icon="mdi-lock" id="password" 
                                    class="form-control form-control-round mt-0" placeholder="Введите пароль...."
                                    v-model="password">
                                    <template v-slot:append>
                                        <v-btn icon @click="ChangeTypePassword"><v-icon>{{
                                            passwodType == 'text' ? 'mdi mdi-eye-off' : 'mdi mdi-eye' }}</v-icon></v-btn>
                                    </template>
                                </v-text-field>
                            </div>
                            <!-- <label class="form-label fw-600 mb-0 mt-2 text-end"><a href="#">Forget password ?</a></label>-->
                            <v-btn color="primary" class='my-1' rounded block @click.enter="Account()"
                                :disabled="loading">
                                <span v-if="!loading">Войти</span>
                                <span v-else><v-progress-circular indeterminate color="black"
                                        :size="20"></v-progress-circular>
                                    <span class="spinner-border spinner-border-sm mr-1" role="status"
                                        aria-hidden="true"></span>
                                    <span>&nbsp; Загрузка...</span>
                                </span>
                            </v-btn>
                        </v-form>
                    </v-card-text>
                    <!--
                <div class="bottom">
                    <h4 class="title">Other Option</h4>
                    <div class="row justify-content-center">
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-google-plus"><i class="fa fa-google"></i></a></div>
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-facebook"><i class="fa fa-facebook"></i></a></div>
                        <div class="col-auto"><a href="#" class="btn btn-icon btn-round btn-twitter"><i class="fa fa-twitter"></i></a></div>
                    </div>
                </div>
-->
                </v-card><!-- Login Form End -->
            </div>
            <!--
        <div class="col-lg-6 col-12 d-none d-lg-block">
            <div class="authentication-bg-image" style="background-image: url(assets/images/authentication/login.jpg);"></div>
        </div>
        -->

        </v-container>
    </v-main>
</template>
<script>
import { mapGetters } from 'vuex'
import * as exp from '@/helpers/actions.js';
Object.entries(exp).forEach(([name, exported]) => { window[name] = exported; });
export default {

    computed: {
        ...mapGetters([
            'GetHttpErrorStatus',
            'GetHttpErrorStatusText',
        ])
    },
    data() {
        return {
            passwodType: "password",
            valid: false,
            dismissSecs: 100,
            dismissCountDown: 0,
            login: null,
            password: null,
            login_error: false,
            loading: false,
            loginRules: [
                v => {
                    if (v == null) return "Логин должен быть заполнен";
                    else return true
                }
            ],
            passwordRules: [
                v => {
                    if (v == null) return "Пароль должен быть заполнен";
                    else return true
                }
            ],
        }
    },
    beforeDestroy() {
        this.loading = false;
        window.removeEventListener('keyup', this._keyListener)
    },
    mounted() {
        var vm = this;
        window.addEventListener('keyup', this._keyListener)
    },
    methods:
    {
        ChangeTypePassword() {
            if (this.passwodType == 'password')
                this.passwodType = 'text'
            else this.passwodType = 'password'
        },
        _keyListener(event) {
            if (event.keyCode === 13) {
                this.Account();
            }
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        Clear_errors() {
            if (this.login_error)
                this.$refs.formLogin.reset()
            this.login_error = false;
        },
        async Account() {
            if (this.$refs.formLogin.validate()) {
                this.loading = true;
                await this.$store.dispatch(AUTH_LOGIN_REQUEST, { Login: this.login, Password: this.password } /*formData/*{username: this.login, password: this.password}*/)
                    .then(response => {
                        addToUserTokens(response.data.jwt, response.data.userID, response.data.tokenID, response.data.role, response.data.userFullName).then(() => {
                            if (response.data.role === "user")
                                this.$router.push("/user/")
                            else this.$router.push("/admin")
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.status == 400 || err.response.status == 404)
                            this.login_error = true;
                        this.loading = false;
                    })
            }
        }
    }
}
</script>
<style >
.login-field .v-messages__message {
    color: red;
}


.card-login {
    width: 100%;
}

.signin__container {
    width: min(450px, 100%);
    position: relative;
    text-align: center;
}

.signin__container .avatar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
}
</style>